import { yupResolver } from "@hookform/resolvers/yup";
import { AuthenticationError, Button, CompanyLogo, FormFieldsInput, Info, InputFields, useI18n, UserContext } from "@vaultinum/app-sdk";
import { useContext, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { SHOPPING_CART_KEY } from "../../contexts";
import { Translation } from "../../i18n";
import { APP_ASSO, APP_CGU, BECOME_MEMBER, CONTACT_SUPPORT, LEGAL_NOTICE, RESET_PASSWORD_PAGE, trackingService } from "../../services";

export default function LoginPage(): JSX.Element {
    const { translation } = useI18n<Translation>();

    const LoginSchema: ObjectSchema<FieldValues> = yup.object({
        login: yup.string().required(translation.requiredField),
        password: yup.string().required(translation.requiredField)
    });

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<FieldValues>({
        resolver: yupResolver(LoginSchema)
    });

    const { authenticateWithInterdeposit, user } = useContext(UserContext);
    const [error, setError] = useState<AuthenticationError | undefined>();

    useEffect(() => {
        if (user) {
            trackingService.startUserTracking(user.sub, user);
        }
    }, [user]);

    const LOGIN_FORM: InputFields[] = [
        {
            name: "login",
            label: translation.login,
            labelPosition: "top",
            autofocus: true
        },
        {
            name: "password",
            label: translation.password,
            labelPosition: "top",
            type: "password"
        }
    ];

    const login = async (data: FieldValues): Promise<void> => {
        try {
            await authenticateWithInterdeposit(data.login, data.password);
        } catch (reponseError) {
            const value: AuthenticationError = JSON.parse(reponseError.body);

            setError(value);
        }
        localStorage.removeItem(SHOPPING_CART_KEY);
    };

    const handleErrorMessage = (errorLogin: AuthenticationError) => {
        switch (errorLogin.loginErrorType) {
            case "TOO_MANY_LOGIN_ATTEMPTS":
                return (
                    <Info labelType={translation.loginFail.temporaryLocked} type="danger" className="mb-5">
                        {errorLogin.accountLockedUntil && translation.loginFail.lockedUntil(errorLogin.accountLockedUntil)}
                    </Info>
                );
            case "LOCKED_ACCOUNT":
                return (
                    <Info labelType={translation.loginFail.impossibleToConnect} type="danger" className="mb-5">
                        {translation.loginFail.accountLocked(errorLogin)}
                    </Info>
                );
            case "INVALID_CREDENTIALS":
            default:
                return (
                    <Info labelType={translation.loginFail.impossibleToConnect} type="danger" className="mb-5">
                        {translation.loginFail.triesWarning(errorLogin)}
                    </Info>
                );
        }
    };

    return (
        <main className="flex flex-col items-center h-screen bg-medium-light-grey">
            <div className="h-16 w-full flex items-center border bg-white border-b-2 border-b-primary">
                <div className="w-40 my-auto ml-5">
                    <a href={APP_ASSO} target="_blank" rel="noreferrer">
                        <CompanyLogo />
                    </a>
                </div>
            </div>
            <div className="flex flex-col items-center my-auto w-96">
                <div className="w-full">
                    <div className="flex flex-col items-center mb-5">
                        <h1 className="text-primary font-bold uppercase m-0">{translation.projectName}</h1>
                        <p className="uppercase text-primary-red font-bold -mt-1.5">{translation.spaceName}</p>
                    </div>
                    {error && handleErrorMessage(error)}
                    <div className="relative flex flex-col p-5 border border-form rounded-lg bg-white">
                        <form className="w-full" onSubmit={handleSubmit(login)}>
                            <FormFieldsInput className="flex flex-col" fields={LOGIN_FORM} control={control} errors={errors} />
                            <div className="flex">
                                <Link className="text-xs mt-1 text-primary underline hover:font-semibold" to={RESET_PASSWORD_PAGE}>
                                    {translation.forgottenPassword}
                                </Link>
                            </div>
                            <div className="flex flex-col items-center gap-4 pt-5">
                                <Button type="submit" label={translation.connect} withLoader />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="flex justify-around w-full mt-2">
                    <Link className="text-sm underline text-primary hover:font-semibold" to={BECOME_MEMBER} target="_blank">
                        {translation.becomeMember}
                    </Link>
                    <Link className="text-sm underline text-primary hover:font-semibold" to={CONTACT_SUPPORT} target="_blank">
                        {translation.contactApp}
                    </Link>
                </div>
            </div>
            <div className="absolute bottom-2 flex justify-between left-5 right-5">
                <div className="space-x-5 w-44">
                    <Link className="text-sm underline text-primary hover:font-semibold" to={LEGAL_NOTICE} target="_blank">
                        {translation.legalNotices}
                    </Link>
                    <Link className="text-sm underline text-primary hover:font-semibold" to={APP_CGU} target="_blank">
                        {translation.cgu}
                    </Link>
                </div>
                <div>
                    <p className="text-sm text-light-black text-center">{process.env.REACT_APP_VERSION}</p>
                </div>
            </div>
        </main>
    );
}
