import { Link, Pagination, UserWallet } from ".";

export namespace Member {
    export enum Nature {
        INDIVIDUAL_COMPANY = "INDIVIDUAL_COMPANY",
        LEGAL_PERSON = "LEGAL_PERSON",
        NATURAL_PERSON = "NATURAL_PERSON"
    }

    export enum Type {
        OWNER = "OWNER",
        REPRESENTATIVE = "REPRESENTATIVE"
    }

    export enum Status {
        VALID = "VALID",
        EXPIRED = "EXPIRED",
        CANCELED = "CANCELED"
    }

    export enum VatCode {
        FRANCE = "FRANCE",
        INTERNATIONAL = "INTERNATIONAL",
        DOM_TOM = "DOM_TOM",
        POLYNESIA = "POLYNESIA"
    }

    export interface PaginatedList {
        members: Member[];
        pagination: Pagination;
    }

    export interface Stats {
        count: number;
    }

    export enum CreditStatus {
        ACTIVE = "ACTIVE",
        EXPIRED = "EXPIRED"
    }

    export interface Administrator {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phone?: string;
        mobile?: string;
        function?: string;
    }
}
export interface Member {
    id: string;
    fullNumber: string;
    uuid: string;
    name: string;
    number: string;
    country: string;
    expiresAt: string;
    dueDate: string;
    subscribedAt: string;
    subscriptionRenewalDate: string;
    nature: Member.Nature;
    status: Member.Status;
    legalStatus: string;
    siren: string;
    vatCode: Member.VatCode;
    isGapp: boolean;
    address: {
        street: string;
        zipCode: string;
        city: string;
        countryCode: string;
    };
    administrators: Member.Administrator[];
    _links: {
        self: Link;
        depositsStats: Link;
        depositStores: Link;
        holders: Link;
        contributors: Link;
        activeContributors: Link;
        representatives: Link;
        mandates: Link;
        requestMandate: Link;
        requestRepresentative: Link;
        listRequestsSentToMandates: Link;
        listRequestsSentToRepresentatives: Link;
        requests: Link;
    };
}

export interface MemberCredit {
    purchased: number;
    consumed: number;
    allocated: number;
    allocatedAndAvailable: number;
    notAllocated: number;
    available: number;
    availableAndNotAllocated: number;
    lost: number;
    expiresAt?: string;
    status?: Member.CreditStatus;
    userWallets: UserWallet[];
    _links: {
        update: Link;
    };
}
