import classNames from "classnames";
import { ReactNode } from "react";

export type CardBoxItem = {
    value: string;
    children: ReactNode;
    title?: string;
    header?: ReactNode;
    subtitle?: string;
    helperText?: string;
    bottomLeftContent?: ReactNode;
    bottomRightContent?: ReactNode;
    isBordered?: boolean;
    checked?: boolean;
    disabled?: boolean;
    lightMode?: boolean;
    position?: "left" | "right" | "center";
    topRightContent?: ReactNode;
};

export default function CardBoxContent({
    children,
    title,
    header,
    subtitle,
    helperText,
    bottomLeftContent,
    bottomRightContent,
    checked,
    isDisabled,
    lightMode,
    position = "left"
}: {
    children: ReactNode;
    title?: string;
    header?: ReactNode;
    subtitle?: string;
    helperText?: string;
    bottomLeftContent?: ReactNode;
    bottomRightContent?: ReactNode;
    checked?: boolean;
    isDisabled?: boolean;
    lightMode?: boolean;
    position?: "left" | "right" | "center";
}): JSX.Element {
    return (
        <div
            className={classNames("relative flex flex-col h-full", {
                "items-center": position === "center",
                "items-start": position === "left",
                "items-end": position === "right"
            })}
        >
            <div
                className={classNames("flex flex-col mb-4", {
                    "items-center": position === "center",
                    "items-start": position === "left",
                    "items-end": position === "right"
                })}
            >
                {header}
                <h2
                    className={classNames("font-bold text-base", {
                        "text-primary": checked || (!checked && !isDisabled),
                        "text-light-black": isDisabled,
                        "font-light text-light-black": lightMode,
                        "text-center": position === "center",
                        "text-left": position === "left",
                        "text-right": position === "right"
                    })}
                >
                    {title}
                </h2>
                {subtitle && (
                    <p
                        className={classNames("text-sm font-light -mt-1", {
                            "text-primary": checked || (!checked && !isDisabled),
                            "text-light-black": isDisabled || lightMode
                        })}
                    >
                        {subtitle}
                    </p>
                )}
            </div>
            <div
                className={classNames("text-sm flex flex-col", {
                    "text-label": checked,
                    "text-light-black": isDisabled || lightMode,
                    "text-center": position === "center",
                    "text-left": position === "left",
                    "text-right": position === "right"
                })}
            >
                {helperText && <p className="text-sm">{helperText}</p>}
                {children}
            </div>
            <footer
                className={classNames("flex w-full items-end mt-auto pt-4", {
                    "justify-between": bottomLeftContent && bottomRightContent,
                    "justify-end": !bottomLeftContent && bottomRightContent,
                    "justify-start": bottomLeftContent && !bottomRightContent
                })}
            >
                {bottomLeftContent}
                {bottomRightContent}
            </footer>
        </div>
    );
}
