import {
    BranChIcon,
    BreadCrumbContext,
    Button,
    CorporationIcon,
    CreditCardIcon,
    CustomLink,
    Environment,
    getEnvironment,
    HomeIcon,
    MainLayout,
    MoneyIcon,
    PersonIcon,
    PersonsIcon,
    ThickDownloadIcon,
    useI18n,
    User,
    UserContext
} from "@vaultinum/app-sdk";
import { ChatboxColors, ChatboxPosition, Crisp } from "crisp-sdk-web";
import { ReactNode, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { MemberContext } from "../../contexts";
import { Translation } from "../../i18n";
import {
    CREDIT_PAGE,
    DEPOSIT_PAGE,
    HOME_PAGE,
    MEMBER_INFO_PAGE,
    PRIVATE_HELP_PAGE,
    toDepositCreatorsPage,
    toPaymentAndInvoicesPage,
    trackingService,
    USER_INFO_PAGE,
    WALLET_PAGE
} from "../../services";
import { Cartridge } from "../Application";

interface LinkTetst {
    label: string;
    icon: ReactNode;
    to?: string;
}

interface MemberDropdownMenuProps {
    links: LinkTetst[];
    sectionTitle: string;
    sectionTitleContent: ReactNode;
}

const DropDownMenuLink = ({ label, icon, to }: LinkTetst) => {
    return (
        <>
            <span className="w-7 h-7 flex items-center justify-center rounded-md bg-primary-light mr-1.5">{icon}</span>
            <CustomLink label={label} className="no-underline" path={to} />
        </>
    );
};

const DropDownMenu = ({ links, sectionTitle, sectionTitleContent }: MemberDropdownMenuProps) => {
    return (
        <div className="text-label space-y-2">
            <p>
                {sectionTitle} <span className="font-semibold">{sectionTitleContent}</span>
            </p>
            <ul className="space-y-2">
                {links.map(link => (
                    <li key={link.label} className="flex">
                        <DropDownMenuLink {...link} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

const Separator = () => {
    return <div className="my-4 h-[1px] bg-gray-200 w-full"></div>;
};

export default function PrivatePageLayout({ children }: { children: ReactNode }) {
    const { logout, user } = useContext(UserContext);
    const { member } = useContext(MemberContext);
    const location = useLocation();
    const { locale, translation } = useI18n<Translation>();
    const { content, showContent } = useContext(BreadCrumbContext);

    const MENU_ITEMS = [
        {
            title: translation.dashboard,
            icon: <HomeIcon className="w-3.5" />,
            path: HOME_PAGE
        },
        {
            title: translation.depositAction,
            icon: <ThickDownloadIcon className="w-3.5" />,
            path: DEPOSIT_PAGE
        },
        {
            title: translation.portfolio,
            icon: <BranChIcon className="w-3.5" />,
            path: WALLET_PAGE
        }
    ];

    const disconnect = async () => {
        await logout(() => {
            trackingService.stopUserTracking();
        });
    };

    const userDetails = user && {
        ...user,
        roles: user?.roles.map(role => translation.user.roles[role]) ?? []
    };

    const isAdmin = (): boolean => user?.roles.includes(User.Role.ADMIN) ?? false;

    const layoutEnv: { [env in Environment]: "development" | "staging" | "production" } = {
        DEVELOPMENT: "development",
        STAGING: "staging",
        PRODUCTION: "production"
    };

    const memberMenuLinks = [
        { label: translation.accountSettings, to: MEMBER_INFO_PAGE, icon: <CorporationIcon className="w-4 h-4 fill-primary" /> },
        { label: translation.manageCredit, to: CREDIT_PAGE, icon: <MoneyIcon className="w-4 h-4 fill-primary" /> },
        { label: translation.paymentAndInvoices, to: toPaymentAndInvoicesPage(), icon: <CreditCardIcon className="w-4 h-4 fill-primary" /> },
        { label: translation.depositCreatorsAndRepresentatives, to: toDepositCreatorsPage(), icon: <PersonsIcon className="w-4 h-4 fill-primary" /> }
    ];

    const userMenuLinks = [{ label: translation.personalSettings, to: USER_INFO_PAGE, icon: <PersonIcon className="w-4 h-4 fill-primary" /> }];

    useEffect(() => {
        if (user && process.env.REACT_APP_CRISP_WEBSITE_ID) {
            Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID, {
                locale,
                cookieExpire: 3600
            });
            Crisp.session.reset();

            Crisp.setPosition(ChatboxPosition.Left);
            Crisp.setColorTheme(ChatboxColors.Red);
            Crisp.setTokenId(user.sub);
            Crisp.user.setEmail(user.email);
            Crisp.user.setNickname(`${user.firstName} ${user.lastName} (${user.memberId})`);
        }
    }, [user]);

    return (
        <MainLayout
            env={layoutEnv[getEnvironment()]}
            logoNavigationPath={HOME_PAGE}
            menuItems={MENU_ITEMS}
            user={userDetails}
            member={member}
            currentLocation={MENU_ITEMS.find(menuitem => location.pathname.startsWith(menuitem.path))}
            bgColor="medium-light-grey"
            headerPopUpContent={
                <>
                    {isAdmin() && (
                        <>
                            <DropDownMenu
                                links={memberMenuLinks}
                                sectionTitle={translation.label(translation.memberAccount)}
                                sectionTitleContent={
                                    <>
                                        <br />
                                        {member?.name}
                                    </>
                                }
                            />
                            <Separator />
                        </>
                    )}
                    <DropDownMenu
                        links={userMenuLinks}
                        sectionTitle={translation.label(translation.connectedAs)}
                        sectionTitleContent={
                            <>
                                <br />
                                {user?.firstName} {user?.lastName.toUpperCase()}
                            </>
                        }
                    />
                    <Separator />
                    <div className="flex flex-col space-y-2">
                        <CustomLink label={translation.needHelpQuestion} className="text-sm no-underline" path={PRIVATE_HELP_PAGE} />
                        <Button onClick={disconnect} variant="link" label={translation.disconnect} size="sm" color="danger" />
                    </div>
                </>
            }
            headerBottomRightContent={<Cartridge />}
            headerBottomLeftContent={content}
            showHeaderBottomLeftContent={showContent}
        >
            {children}
        </MainLayout>
    );
}
