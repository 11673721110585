import { useScroll, useWindowDimensions } from "@vaultinum/shared-web-app-sdk";
import classNames from "classnames";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronSmall, Header, Menu, MenuItem } from "../../../design-system/components";
import { useToggle } from "../../hooks";

type User = {
    firstName: string;
    lastName: string;
    roles: string[];
    avatar?: string;
};

type Member = {
    name: string;
};

type Color = "medium-light-grey" | "white";

export default function MainLayout({
    children,
    menuItems,
    env,
    logoNavigationPath,
    bgColor = "medium-light-grey",
    user,
    currentLocation,
    headerCenterContent,
    headerPopUpContent,
    headerBottomRightContent,
    headerBottomLeftContent,
    showHeaderBottomLeftContent,
    member
}: {
    children: React.ReactNode;
    menuItems: MenuItem[];
    env: "development" | "production" | "staging";
    logoNavigationPath: string;
    bgColor?: Color;
    user?: User;
    currentLocation?: MenuItem;
    headerCenterContent?: ReactNode;
    headerPopUpContent?: ReactNode;
    headerBottomRightContent?: ReactNode;
    headerBottomLeftContent?: ReactNode;
    showHeaderBottomLeftContent?: boolean;

    member?: Member;
}) {
    const scrollY = useScroll();
    const [isExpanded, toggleExpanded, setIsExpanded] = useToggle(localStorage.getItem("isExpanded") ? localStorage.getItem("isExpanded") === "true" : true);
    const { width } = useWindowDimensions();
    const navigate = useNavigate();

    useEffect(() => {
        if (isExpanded) {
            setIsExpanded(width >= 1280);
        }
    }, [width]);

    useEffect(() => {
        localStorage.setItem("isExpanded", String(isExpanded));
    }, [isExpanded]);

    return (
        <div className="flex flex-col">
            <Header
                env={env}
                user={user}
                member={member}
                headerPopUpContent={headerPopUpContent}
                centerContent={headerCenterContent}
                bottomRightContent={headerBottomRightContent}
                openMenu={isExpanded}
                onClick={_ => navigate(logoNavigationPath)}
            />
            <div className="flex mt-16">
                <Menu items={menuItems} onToggle={toggleExpanded} openMenu={isExpanded} selectedMenuItem={currentLocation} className="fixed h-full" />
                <main
                    className={classNames(`relative min-h-screen grow py-7 px-3 text-default ease-in-out duration-200 block w-9/12 bg-${bgColor}`, {
                        "ml-52": isExpanded,
                        "ml-16": !isExpanded
                    })}
                >
                    <div
                        className={classNames("transition-all duration-500 fixed z-40", {
                            "top-0": !showHeaderBottomLeftContent,
                            "top-16": showHeaderBottomLeftContent
                        })}
                    >
                        {headerBottomLeftContent}
                    </div>
                    <div className="space-y-7">{children}</div>
                    <span
                        className={classNames("fixed bottom-10 right-10 flex justify-center w-9 h-9 bg-primary p-5 cursor-pointer ease-in-out duration-200", {
                            "opacity-0": !scrollY,
                            "opacity-80 hover:opacity-90": !!scrollY
                        })}
                        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                    >
                        <div className="flex items-center duration-200 ease-in-out rotate-0">
                            <ChevronSmall className="w-4 h-2 fill-white" />
                        </div>
                    </span>
                </main>
            </div>
        </div>
    );
}
