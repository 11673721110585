import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CompanyLogo, FormFieldsInput, InputFields, ServiceContext, useApi, useI18n } from "@vaultinum/app-sdk";
import { useContext } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { Translation } from "../../i18n";
import { CONFIRMATION_RESET_PASSWORD_PAGE } from "../../services";

export default function ResetPasswordPage(): JSX.Element {
    const { translation } = useI18n<Translation>();
    const { fetchApi, isLoading } = useApi();
    const { authentificationService } = useContext(ServiceContext);
    const params = useParams();
    const navigate = useNavigate();

    const ResetPasswordSchema: ObjectSchema<FieldValues> = yup.object({
        password: yup.string().isPasswordFormatValid(translation.passwordRequirements).required(translation.requiredField),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password")], translation.confirmPasswordRequirements)
            .required(translation.requiredField)
    });

    const {
        control,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm<FieldValues>({
        mode: "onChange",
        resolver: yupResolver(ResetPasswordSchema)
    });

    const submit = (data: FieldValues) => {
        const token = params.token ?? "";
        fetchApi(
            () => authentificationService.resetInterdepositPassword(token, data.password),
            () => {
                navigate(CONFIRMATION_RESET_PASSWORD_PAGE);
            }
        );
    };

    const RESET_PASSWORD_FORM: InputFields[] = [
        {
            name: "password",
            label: translation.password,
            helperText: translation.passwordRequirements,
            labelPosition: "top",
            type: "password"
        },
        {
            name: "confirmPassword",
            label: translation.confirmPassword,
            helperText: translation.confirmPassword,
            labelPosition: "top",
            type: "password"
        }
    ];

    return (
        <main className="flex flex-col items-center py-16 space-y-12">
            <CompanyLogo className="h-12 w-60" />
            <div className="max-w-md">
                <div className="relative flex flex-col items-center p-12 border border-form">
                    <h2 className="text-2xl font-bold text-primary mb-9">{translation.resetPassword.label}</h2>
                    <form onSubmit={handleSubmit(submit)} className="w-full space-y-5">
                        <FormFieldsInput className="flex flex-col" fields={RESET_PASSWORD_FORM} control={control} errors={errors} />
                        <div className="flex flex-col items-center gap-4 pt-4">
                            <Button type="submit" label={translation.confirm} disabled={!isValid || isLoading} />
                        </div>
                    </form>
                </div>
            </div>
        </main>
    );
}
