import {
    BankTransferIcon,
    Checkout,
    createOrder,
    CreditCardIcon,
    getCredits,
    getMemberInformation,
    MemberInformation,
    Order,
    OrderCreation,
    OrderCredit,
    orderCreditsWithCreditCard,
    PaymentMethod,
    Product,
    useApi,
    useI18n,
    UserContext
} from "@vaultinum/app-sdk";
import { keyBy, omit, omitBy } from "lodash";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";

import { Translation } from "../i18n";

export const SHOPPING_CART_KEY = "shoppingCart";

const reduceSum = (total: number, price: number): number => total + price;

export interface ShoppingCart {
    items: Record<string, number>;
    externalReference?: string;
    paymentMethod?: PaymentMethod;
}

const EMPTY_SHOPPING_CART: ShoppingCart = {
    items: {},
    paymentMethod: PaymentMethod.CREDIT_CARD
};

type PaymentMethodDetails = {
    paymentMethod: PaymentMethod;
    icon: ReactNode;
    label: string;
    description?: ReactNode;
};

export type Catalog = Record<string, Product>;

export type ShoppingCardInformation = {
    totalPriceTaxExcluded: number;
    globalRebatePercent: number;
    globalRebatePrice: number;
    vatPercents: number[];
    vatPrice: number;
    totalPriceTaxIncluded: number;
    isEmptyShoppingCart: boolean;
};

const getShoppingCartInformation = (
    memberInformation: MemberInformation | undefined,
    creditsCatalog: Catalog | undefined
): ((shoppingCart: ShoppingCart) => ShoppingCardInformation) => {
    return (shoppingCart: ShoppingCart) => {
        const selectedProducts = Object.entries(shoppingCart.items)
            .map(([productReference]) => creditsCatalog?.[productReference])
            .filter(product => product !== undefined);

        const totalPriceTaxExcluded = selectedProducts
            .map(product => product.unitPriceVatExcluded * shoppingCart.items[product.reference])
            .reduce(reduceSum, 0);

        const globalRebatePercent = memberInformation?.globalRebatePercent ?? 0;
        const globalRebatePrice = selectedProducts.map(product => product.rebatePriceVatExcluded * shoppingCart.items[product.reference]).reduce(reduceSum, 0);

        const vatPercents = selectedProducts.map(product => product.vatRate).filter((vatRate, index, self) => self.indexOf(vatRate) === index);
        const vatPrice = selectedProducts.map(product => product.vatPriceWithRebate * shoppingCart.items[product.reference]).reduce(reduceSum, 0);

        const totalPriceTaxIncluded = selectedProducts
            .map(product => product.unitPriceWithRebateVatIncluded * shoppingCart.items[product.reference])
            .reduce(reduceSum, 0);

        const isEmptyShoppingCart = !Object.keys(shoppingCart.items).length;

        return {
            totalPriceTaxExcluded,
            globalRebatePercent,
            globalRebatePrice,
            vatPercents,
            vatPrice,
            totalPriceTaxIncluded,
            isEmptyShoppingCart
        };
    };
};

export interface OrderContextInterface {
    shoppingCart: ShoppingCart;
    getShoppingCartInformation: (shoppingCart: ShoppingCart) => ShoppingCardInformation;
    canFillExternalReference: boolean;
    getProductQuantityFromShoppingCart: (product: Product) => number;
    setProductQuantityIntoShoppingCart: (product: Product, quantity: number) => void;
    clearShoppingCart: () => void;
    paymentMethods: PaymentMethodDetails[];
    setPaymentMethod: (paymentMethod: PaymentMethod) => void;
    setExternalReference: (externalReference: string) => void;
    isOrderValidated: boolean;
    setIsOrderValidated: (isValidated: boolean) => void;
    creditsCatalog?: Catalog;
    createOrder?: (orderCreation: OrderCreation) => Promise<Order>;
    orderCreditsWithCreditCard?: (orderCredit: OrderCredit) => Promise<Checkout>;
}

export const OrderContext = createContext<OrderContextInterface>({} as OrderContextInterface);

const removeZeroQuantitiesFromShoppingCart = (shoppingCart: ShoppingCart): ShoppingCart => ({
    ...shoppingCart,
    items: omitBy(shoppingCart.items, quantity => quantity === 0)
});

const getShoppingCartFromStorage = (): ShoppingCart | undefined => {
    const storedSerializedShoppingCart = localStorage.getItem(SHOPPING_CART_KEY);

    if (storedSerializedShoppingCart) {
        return JSON.parse(storedSerializedShoppingCart);
    }

    return undefined;
};

const setShoppingCartIntoStorage = (shoppingCart: ShoppingCart): void => {
    const filteredShoppingCart: ShoppingCart = removeZeroQuantitiesFromShoppingCart(shoppingCart);

    localStorage.setItem(SHOPPING_CART_KEY, JSON.stringify(filteredShoppingCart));
};

export function OrderContextProvider({ children }: { children: ReactNode }) {
    const [memberInformation, setMemberInformation] = useState<MemberInformation>();
    const [credits, setCredits] = useState<Product[]>();
    const [shoppingCart, setShoppingCart] = useState<ShoppingCart>(getShoppingCartFromStorage() ?? { ...EMPTY_SHOPPING_CART });
    const [isOrderValidated, setIsOrderValidated] = useState<boolean>(false);

    const { user } = useContext(UserContext);
    const { fetchApi } = useApi();
    const { translation } = useI18n<Translation>();

    useEffect(() => {
        if (!user?.memberId) {
            return;
        }

        const memberId = user.memberId;

        void (async () => {
            await fetchApi<MemberInformation>(() => getMemberInformation(memberId), setMemberInformation);
        })();
    }, [user]);

    useEffect(() => {
        if (memberInformation) {
            const getCreditsFn = getCredits(memberInformation);

            if (getCreditsFn) {
                void (async () => setCredits(await getCreditsFn()))();
            }
        }
    }, [memberInformation]);

    const setShoppingCartAndStore = (handler: (prevShoppingCart: ShoppingCart) => ShoppingCart) => {
        setShoppingCart((prevShoppingCart: ShoppingCart) => {
            const newShoppingCart = handler(prevShoppingCart);
            const filteredShoppingCart = removeZeroQuantitiesFromShoppingCart(newShoppingCart);

            setShoppingCartIntoStorage(filteredShoppingCart);

            return filteredShoppingCart;
        });
    };

    const setProductQuantityIntoShoppingCart = (product: Product, quantity: number): void => {
        setShoppingCartAndStore(prevShoppingCart => {
            const itemsWithoutProduct = omit(prevShoppingCart.items, product.reference);

            return {
                ...prevShoppingCart,
                items: { ...itemsWithoutProduct, [product.reference]: quantity }
            };
        });
    };

    const clearShoppingCart = (): void => {
        setShoppingCartAndStore(_ => ({ ...EMPTY_SHOPPING_CART }));
    };

    const paymentMethods: PaymentMethodDetails[] = [
        {
            ...translation.paymentMethods[PaymentMethod.CREDIT_CARD],
            paymentMethod: PaymentMethod.CREDIT_CARD,
            icon: <CreditCardIcon className="w-6 h-6 fill-primary" />
        },
        {
            ...translation.paymentMethods[PaymentMethod.BANK_TRANSFER],
            paymentMethod: PaymentMethod.BANK_TRANSFER,
            icon: <BankTransferIcon className="w-8 h-8 fill-primary" />
        }
    ];

    const setPaymentMethod = (paymentMethod: PaymentMethod): void => {
        setShoppingCartAndStore(prevShoppingCart => ({ ...prevShoppingCart, paymentMethod }));
    };

    const setExternalReference = (externalReference: string): void => {
        setShoppingCartAndStore(prevShoppingCart => ({ ...prevShoppingCart, externalReference }));
    };

    const orderContext: OrderContextInterface = useMemo(() => {
        const creditsCatalog: Catalog | undefined = credits && keyBy(credits, credit => credit.reference);

        return {
            shoppingCart,
            getShoppingCartInformation: getShoppingCartInformation(memberInformation, creditsCatalog),
            canFillExternalReference: !!memberInformation?.canFillExternalReference,
            getProductQuantityFromShoppingCart: (product: Product) => shoppingCart.items[product.reference] ?? 0,
            setProductQuantityIntoShoppingCart,
            clearShoppingCart,
            paymentMethods,
            setPaymentMethod,
            setExternalReference,
            isOrderValidated,
            setIsOrderValidated,
            creditsCatalog,
            createOrder: memberInformation && createOrder(memberInformation),
            orderCreditsWithCreditCard: memberInformation && orderCreditsWithCreditCard(memberInformation)
        };
    }, [credits, memberInformation, shoppingCart, isOrderValidated]);

    return <OrderContext.Provider value={orderContext}>{children}</OrderContext.Provider>;
}
