import { ApisContext, BoxContainer, Deposit, FormFieldsInput, FormFieldsRadio, InfoTooltip, TickIcon, getStandardFields, useI18n } from "@vaultinum/app-sdk";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { SCHEMA_DEPOSIT_INFORMATIONS } from "../../hooks/useStepController";
import { Translation } from "../../i18n";

export default function StepInformation(): JSX.Element {
    const { translation } = useI18n<Translation>();
    const apis = useContext(ApisContext);

    const {
        control,
        watch,
        formState: { errors }
    } = useFormContext();

    const depositInformations = watch(SCHEMA_DEPOSIT_INFORMATIONS).every(value => value);

    const stepContractType = [
        {
            value: "standard",
            title: translation.pack.free.title
        },
        {
            value: "bipartite",
            title: translation.pack.small.title,
            disabled: true
        },
        {
            value: "accessclause",
            title: translation.pack.mid.title,
            disabled: true
        },
        {
            value: "tripartite",
            title: translation.pack.high.title,
            disabled: true
        }
    ];

    const stepDepositType = [
        {
            value: Deposit.SubscriptionTypeCode.S,
            header: translation.depositTypeStep.standard.title,
            title: translation.depositTypeStep.standard.subtitle
        },
        {
            value: Deposit.SubscriptionTypeCode.D,
            header: translation.depositTypeStep.verified.title,
            title: translation.depositTypeStep.verified.subtitle,
            disabled: true
        },
        {
            value: Deposit.SubscriptionTypeCode.C,
            header: translation.depositTypeStep.controlled.title,
            title: translation.depositTypeStep.controlled.subtitle,
            disabled: true
        }
    ];

    return (
        <div className="grid grid-cols-2 gap-5 w-full">
            <BoxContainer
                title={translation.deposit.subscriptionType}
                topRightContent={<InfoTooltip children={translation.subscriptionTypeTooltip} className="top-1 right-1" />}
                icon={watch("subscriptionTypeCode") && <TickIcon className="fill-green-light" />}
            >
                <FormFieldsRadio radioGroupName="subscriptionTypeCode" control={control} options={stepDepositType} />
            </BoxContainer>
            <BoxContainer
                title={translation.contractType}
                topRightContent={<InfoTooltip children={translation.infoNoContractType} className="top-1 right-1" />}
                icon={watch("contractType") && <TickIcon className="fill-green-light" />}
            >
                <FormFieldsRadio radioGroupName="contractType" control={control} options={stepContractType} />
            </BoxContainer>
            <div className="grid-cols-subgrid col-span-2">
                <BoxContainer
                    title={translation.yourProduct}
                    icon={depositInformations && <TickIcon className="fill-green-light" />}
                    rightTitle={
                        <p className="text-xs">
                            <span className="text-danger-high">*</span> {translation.mandatory}
                        </p>
                    }
                >
                    <FormFieldsInput className="flex flex-col" fields={getStandardFields(apis, translation, "top")} control={control} errors={errors} />
                </BoxContainer>
            </div>
        </div>
    );
}
