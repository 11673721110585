import { doPost } from "./api.service";
import { AuthentificationService } from "./new-authentification.service";

export class RestApiAuthentificationService implements AuthentificationService {
    private readonly apiUrl: string;

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    private absolute(url: string): string {
        return `${this.apiUrl}${url}`;
    }

    requestPasswordReset(login: string): Promise<void> {
        const url = this.absolute("/v1/interdeposit/request-password-reset");
        return doPost(url, { login });
    }

    resetInterdepositPassword(token: string, password: string): Promise<void> {
        const url = this.absolute(`/v1/interdeposit/reset-password/${token}`);
        return doPost(url, { password });
    }
}
