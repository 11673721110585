import { Chip, Size } from "../../../design-system";
import { Translation, useI18n } from "../../i18n";
import { DepositMember } from "../../model";

const COLORS: { [key in DepositMember.Status]: "success" | "neutral" | "warning" | "primary" } = {
    [DepositMember.Status.SENT]: "primary",
    [DepositMember.Status.ACCEPTED]: "success",
    [DepositMember.Status.REFUSED]: "warning",
    [DepositMember.Status.INVALID]: "warning"
};

export default function DepositMemberStatus({
    status,
    type,
    size = "md"
}: {
    status: DepositMember.Status;
    type: DepositMember.Type;
    size?: Size;
}): JSX.Element {
    const { translation } = useI18n<Translation>();
    return <Chip label={translation.invitationType[type]} type={COLORS[status]} size={size} tooltipContent={status} />;
}
