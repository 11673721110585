import { createContext, ReactNode, useMemo } from "react";
import { MemberService, OrderService, RestApiAuthentificationService, RestApiMemberService, RestApiOrderService } from "../services";
import { AuthentificationService } from "../services/new-authentification.service";

interface ServiceContextInterface {
    memberService: MemberService;
    orderService: OrderService;
    authentificationService: AuthentificationService;
}

export const ServiceContext = createContext<ServiceContextInterface>({} as ServiceContextInterface);

export function RestApiServiceContextProvider({ children }: { children: ReactNode }): JSX.Element {
    const exposed: ServiceContextInterface = useMemo(() => {
        if (!process.env.REACT_APP_MEMBER_API_URL) {
            throw new Error("REACT_APP_MEMBER_API_URL is not defined");
        }

        if (!process.env.REACT_APP_ORDER_API_URL) {
            throw new Error("REACT_APP_ORDER_API_URL is not defined");
        }

        if (!process.env.REACT_APP_AUTHENTICATION_API_URL) {
            throw new Error("REACT_APP_AUTHENTICATION_API_URL is not defined");
        }

        return {
            memberService: new RestApiMemberService(process.env.REACT_APP_MEMBER_API_URL),
            orderService: new RestApiOrderService(process.env.REACT_APP_ORDER_API_URL),
            authentificationService: new RestApiAuthentificationService(process.env.REACT_APP_AUTHENTICATION_API_URL)
        };
    }, []);

    return <ServiceContext.Provider value={exposed}>{children}</ServiceContext.Provider>;
}
