import { Country, Member, Order, Product } from "../model";
import { doGet, doPost } from "./api.service";
import { OrderService } from "./new-order.service";

export class RestApiOrderService implements OrderService {
    private readonly apiUrl: string;

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    private absolute(url: string): string {
        return `${this.apiUrl}${url}`;
    }

    getSubscriptionsByMemberId(memberId: string): Promise<Product[]> {
        const url = this.absolute(`/v1/members/${memberId}/products`);
        return doGet<Product[]>(url, {
            type: "SUBSCRIPTION"
        });
    }

    getSubscription(memberNature: Member.Nature, countryCode: Country, isOffer?: boolean): Promise<Product> {
        const url = this.absolute(`/v1/products/subscription`);
        return doGet<Product>(url, {
            nature: memberNature,
            country: countryCode,
            offer: isOffer ? "STARTUP" : ""
        });
    }

    createOrder(registrationId: string): () => Promise<Order> {
        const url = this.absolute(`/v1/registrations/${registrationId}/create-order`);
        return () => doPost<Order>(url);
    }
}
