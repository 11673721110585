import { ReactNode } from "react";
import bgimage from "../../styles/images/appBg.png";
import CompanyWhiteLogo from "../CompanyWhiteLogo";

export default function SucessLayout({ title, subtitle, children }: { title: string; subtitle: string; children: ReactNode }): JSX.Element {
    return (
        <div className="w-screen h-screen flex flex-col justify-center items-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${bgimage})` }}>
            <div className="flex flex-col items-center gap-y-14 max-w-lg">
                <div className="w-96">
                    <CompanyWhiteLogo />
                </div>
                <div className="p-7 bg-white rounded">
                    <h2 className="text-primary font-bold">{title}</h2>
                    <div className="flex gap-x-2">
                        <hr className="w-7 h-1 bg-primary" />
                        <p className="uppercase text-danger-high font-bold text-xs -mt-1">{subtitle}</p>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
}
