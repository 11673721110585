import { Link } from "..";

export enum RegisterSubmitStatus {
    ACTIVE = "ACTIVE",
    WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL"
}

export interface ResponseSubmitStatus {
    id: string;
    status: RegisterSubmitStatus;
    memberId: string;
    _links: {
        self: Link;
    };
}

export interface RegistrationDetail {
    _links: {
        finalizeRegistration: Link;
    };
}

export interface RegisterType {
    _links: {
        registrationOwner: Link;
        registrationRepresentative: Link;
        registrationJointHolder: Link;
    };
}

export interface NatureRegister {
    _links: {
        legalPerson: Link;
        naturalPerson: Link;
    };
}

export interface RegistrationMemberRequest {
    name: string;
    siret: string;
    countryCode: string;
}

export interface RegistrationAdministratorRequest {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

export interface RepresentativeLegalPerson {
    member: RegistrationMemberRequest;
    administrator: RegistrationAdministratorRequest;
    withRebate: boolean;
    validateData: boolean;
}

export interface RegistrationIndividualCompanyRequest {
    name: string;
    identificationNumber: string;
    countryCode: string;
}

export interface OwnerLegalPerson {
    member: RegistrationMemberRequest;
    administrator: RegistrationAdministratorRequest;
    mainAddress?: {
        line1: string;
        line2?: string;
        postalCode: string;
        city: string;
        countryCode: string;
        phone: string;
    };
    invoiceAddress?: {
        name: string;
        line1: string;
        line2?: string;
        postalCode: string;
        city: string;
        countryCode: string;
        vatNumber: string;
        invoiceEmail: string;
    };
    specialOffers: {
        isStartup: boolean;
        isNumeum: boolean;
        numeumNumber?: string;
    };
    validateData: boolean;
}

export interface OwnerNaturalPerson {
    individualCompany: RegistrationIndividualCompanyRequest;
    administrator: RegistrationAdministratorRequest;
    mainAddress?: {
        line1: string;
        line2?: string;
        postalCode: string;
        city: string;
        countryCode: string;
        phone: string;
    };
    invoiceAddress?: {
        name: string;
        line1: string;
        line2?: string;
        postalCode: string;
        city: string;
        countryCode: string;
        invoiceEmail: string;
    };
    validateData: boolean;
}
