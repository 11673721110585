import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../design-system";
import { InterdepositContext } from "../contexts";
import { Translation, useI18n } from "../i18n";
import { DepositMember, DepositStore, ManagedBy, Member, User, UserInfo } from "../model";

import {
    addMemberToDepositStore,
    addUserManagerToDepositStore,
    getActiveContibutorsByMember,
    getManagedByDepositStore,
    getRepresentativesByMember,
    removeDepositStoreContributor,
    removeDepositStoreMember
} from "../services";
import useApi from "./useApi";

export interface Managers {
    onDepositMemberManagerAdd: (depositMember: DepositMember) => void;
    onDepositMemberManagerRemove: (representativeId: string) => void;
    onUserManagerRemove: (userId: string) => void;
    onUserManagerAdd: (user: UserInfo) => void;
    contributors?: UserInfo[];
    userManagers: UserInfo[];
    depositMembersManagers: DepositMember[];
    depositMembers?: DepositMember[];
}

type ContributorsList = {
    submit: (redirect?: string) => Promise<void>;
    reset: () => void;
    readonly: boolean;
    infoMessage: ReactNode;
    warningMessage: ReactNode;
    updating: boolean;
    noModification: boolean;
    managers: Managers;
};

const adminstratorToUserInfo = (administrator: Member.Administrator): UserInfo => {
    return {
        id: administrator.id,
        firstName: administrator.firstName,
        lastName: administrator.lastName,
        role: User.UserWalletRole.ADMINISTRATOR,
        email: administrator.email,
        _links: {
            removeUserManager: {
                available: false
            }
        }
    } as UserInfo;
};

export function useContributorsList(depositStore: DepositStore, member: Member): ContributorsList {
    const toast = useToast();
    const { refreshDepositStore } = useContext(InterdepositContext);
    const { fetchApi } = useApi();
    const { translation } = useI18n<Translation>();

    const [updating, setUpdating] = useState<boolean>(false);
    const [contributors, setContributors] = useState<UserInfo[]>();
    const [depositMembers, setDepositMembers] = useState<DepositMember[]>();
    const [defaultManagers, setDefaultManagers] = useState<ManagedBy>();

    const [userManagers, setUserManagers] = useState<UserInfo[]>(depositStore.managedBy.users);
    const [userManagersToAdd, setUserManagersToAdd] = useState<UserInfo[]>([]);
    const [userManagersToRemove, setUserManagersToRemove] = useState<UserInfo[]>([]);

    const [depositMembersManagers, setDepositMembersManagers] = useState<DepositMember[]>(depositStore.managedBy.members);
    const [depositMembersToAdd, setDepositMembersToAdd] = useState<DepositMember[]>([]);
    const [depositMembersToRemove, setDepositMembersToRemove] = useState<DepositMember[]>([]);

    const [infoMessage, setInfoMessage] = useState<ReactNode>();
    const [warningMessage, setWarningMessage] = useState<ReactNode>();

    const noModification = useMemo(
        () => userManagersToAdd.length === 0 && userManagersToRemove.length === 0 && depositMembersToAdd.length === 0 && depositMembersToRemove.length === 0,
        [userManagersToAdd, userManagersToRemove, depositMembersToAdd, depositMembersToRemove]
    );

    const navigate = useNavigate();

    const addUserManagerFn = addUserManagerToDepositStore(depositStore);
    const addRepresentativeManagerFn = addMemberToDepositStore(depositStore);

    const submit = async (redirect?: string): Promise<void> => {
        const addUserPromises = userManagersToAdd.map(manager => {
            if (addUserManagerFn) {
                return fetchApi(() => addUserManagerFn(manager));
            }

            return Promise.resolve();
        });

        const addRepresentativePromises = depositMembersToAdd.map(manager => {
            if (addRepresentativeManagerFn) {
                return fetchApi(() => addRepresentativeManagerFn(manager));
            }

            return Promise.resolve();
        });

        const removeUserPromises = userManagersToRemove.map(manager => {
            const removeFn = removeDepositStoreContributor(manager);

            if (removeFn) {
                return fetchApi(removeFn);
            }

            return Promise.resolve();
        });

        const removeRepresentativePromises = depositMembersToRemove.map(manager => {
            const removeFn = removeDepositStoreMember(manager);

            if (removeFn) {
                return fetchApi(removeFn);
            }

            return Promise.resolve();
        });

        setUpdating(true);

        await Promise.all([...removeUserPromises, ...removeRepresentativePromises, ...addRepresentativePromises, ...addUserPromises]).then(responses => {
            if (responses.every(response => response !== undefined)) {
                toast.success(translation.contributorsUpdated);
            } else {
                toast.error(translation.error);
            }
        });

        setUserManagersToAdd([]);
        setUserManagersToRemove([]);
        setDepositMembersToAdd([]);
        setDepositMembersToRemove([]);

        refreshDepositStore();

        setUpdating(false);
        if (redirect) {
            navigate(redirect);
        }
    };

    useEffect(() => {
        if (noModification && depositStore.managedBy.members.length) {
            setInfoMessage(translation.representativeSelectedInfo);
        } else {
            setInfoMessage(undefined);
        }
    }, [userManagersToAdd, userManagersToRemove, depositMembersToAdd, depositMembersToRemove, noModification]);

    useEffect(() => {
        if (depositMembersToAdd.length) {
            setWarningMessage(translation.representativeAddedInfo(depositMembersToAdd[0].name));
        } else if (depositMembersToRemove.length) {
            setWarningMessage(translation.representativeRemovedInfo(depositStore.managedBy.members[0].name));
        } else {
            setWarningMessage(undefined);
        }
    }, [depositMembersToAdd, depositMembersToRemove]);

    const readonly = !addUserManagerFn && !addRepresentativeManagerFn;

    const initialDepositStoreUserManagers = defaultManagers?.users ?? member.administrators.map(adminstratorToUserInfo);

    const loadContributorsAndRepresentatives = async (): Promise<void> => {
        const fetchContributors = getActiveContibutorsByMember(member);
        const fetchRepresentatives = getRepresentativesByMember(member);
        const fetchDefaultManagers = getManagedByDepositStore(depositStore);

        if (fetchContributors) {
            await fetchApi(fetchContributors, setContributors);
        }

        if (fetchRepresentatives) {
            await fetchApi(fetchRepresentatives, setDepositMembers);
        }

        if (fetchDefaultManagers) {
            await fetchApi(fetchDefaultManagers, setDefaultManagers);
        }
    };

    useEffect(() => {
        void loadContributorsAndRepresentatives();
    }, []);

    const onUserManagerAdd = (user: UserInfo): void => {
        setUserManagers(prev => {
            if (prev.length === 0) {
                return [...initialDepositStoreUserManagers, user];
            } else {
                return [...prev, user];
            }
        });
        setUserManagersToRemove(prev => prev.filter(manager => manager.id !== user.id));
        if (depositStore.managedBy.users.every(manager => manager.id !== user.id)) {
            setUserManagersToAdd(prev => [...prev, user]);
        }

        setDepositMembersManagers([]);
        setDepositMembersToAdd([]);

        setDepositMembersToRemove(depositStore.managedBy.members.filter(removeDepositStoreMember));
    };

    const onUserManagerRemove = (userId: string): void => {
        setUserManagers(prev => prev.filter(manager => manager.id !== userId));
        setUserManagersToAdd(prev => prev.filter(manager => manager.id !== userId));

        const user = depositStore.managedBy.users.find(manager => manager.id === userId);

        if (user) {
            setUserManagersToRemove(prev => [...prev, user]);
        }
    };

    const onDepositMemberManagerAdd = (depositMember: DepositMember): void => {
        setUserManagers([]);
        setUserManagersToAdd([]);
        setUserManagersToRemove(depositStore.managedBy.users.filter(removeDepositStoreContributor));

        setDepositMembersManagers([depositMember]);
        setDepositMembersToAdd([depositMember].filter(manager => depositStore.managedBy.members.every(rep => rep.id !== manager.id)));
        setDepositMembersToRemove(depositStore.managedBy.members.filter(manager => manager.id !== depositMember.id));
    };

    const onDepositMemberManagerRemove = (depositMemberId: string): void => {
        setUserManagers(initialDepositStoreUserManagers);
        setUserManagersToAdd([]);
        setUserManagersToRemove(depositStore.managedBy.users.filter(removeDepositStoreContributor));

        setDepositMembersManagers([]);
        setDepositMembersToAdd([]);
        setDepositMembersToRemove(prev => [...prev, ...depositStore.managedBy.members.filter(manager => manager.id === depositMemberId)]);
    };

    const reset = (): void => {
        setUserManagers(depositStore.managedBy.users);
        setUserManagersToAdd([]);
        setUserManagersToRemove([]);

        setDepositMembersManagers(depositStore.managedBy.members);
        setDepositMembersToAdd([]);
        setDepositMembersToRemove([]);
    };

    const managers: Managers = useMemo(
        () => ({
            contributors: contributors?.map(contributor => defaultManagers?.users.find(user => user.id === contributor.id) ?? contributor),
            depositMembers,
            userManagers,
            depositMembersManagers,
            onDepositMemberManagerAdd,
            onDepositMemberManagerRemove,
            onUserManagerRemove,
            onUserManagerAdd
        }),
        [
            defaultManagers,
            contributors,
            depositMembers,
            userManagers,
            depositMembersManagers,
            onDepositMemberManagerAdd,
            onDepositMemberManagerRemove,
            onUserManagerRemove,
            onUserManagerAdd
        ]
    );

    return {
        managers,
        submit,
        noModification,
        reset,
        readonly,
        infoMessage,
        warningMessage,
        updating
    };
}
