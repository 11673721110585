import { LayoutSide } from "../../../design-system";
import { canSendRequest } from "../../helpers";
import { useApi } from "../../hooks";
import { Translation, useI18n } from "../../i18n";
import { Member } from "../../model";

export default function RequestDepositMembersSide({ toRequestForm, member }: { toRequestForm: () => void; member: Member }): JSX.Element {
    const { translation } = useI18n<Translation>();
    const { isLoading } = useApi();

    return (
        <LayoutSide
            title={translation.actions}
            sideElements={[
                {
                    label: translation.createInvitation,
                    elementType: "button",
                    action: toRequestForm,
                    disabled: !canSendRequest(member) || isLoading
                }
            ]}
        />
    );
}
