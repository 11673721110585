import {
    DepositCreators,
    DepositCreatorsSide,
    DepositMember,
    getContributorsByMember,
    getRepresentativesByMember,
    Layout,
    Loader,
    Member,
    PersonsIcon,
    RequestDepositMembers,
    RequestDepositMembersSide,
    RequestSender,
    ServiceContext,
    Tabs,
    useApi,
    useI18n,
    UserInfo
} from "@vaultinum/app-sdk";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MemberContext } from "../../contexts";
import { Translation } from "../../i18n";

export enum DepositCreatorsTabs {
    CONTRIBUTORS = "contributors",
    TIERS = "tiers"
}

export default function DepositCreatorsPage(): JSX.Element {
    const [contributors, setContributors] = useState<UserInfo[]>();
    const [openPopUp, setOpenPopUp] = useState<boolean>(false);
    const [invitations, setInvitations] = useState<RequestSender[]>([]);
    const [depositMembers, setDepositMembers] = useState<DepositMember[]>();
    const params = useParams();
    const { translation } = useI18n<Translation>();
    const { member } = useContext(MemberContext);
    const { fetchApi } = useApi();
    const { memberService } = useContext(ServiceContext);

    const loadInfos = async (memberDetails?: Member) => {
        if (memberDetails) {
            const fetchRepresentatives = getRepresentativesByMember(memberDetails);
            const fetchContributors = getContributorsByMember(memberDetails);
            const fetchRequests = memberService.getRequests(memberDetails);

            if (fetchContributors) {
                await fetchApi(fetchContributors, setContributors);
            }
            if (fetchRepresentatives) {
                await fetchApi(fetchRepresentatives, setDepositMembers);
            }
            if (fetchRequests) {
                await fetchApi(fetchRequests, setInvitations);
            }
        }
    };

    const refreshInfos = async () => {
        setOpenPopUp(false);
        await loadInfos(member);
    };

    useEffect(() => {
        const fetchData = async () => {
            await loadInfos(member);
        };
        fetchData();
    }, [member]);

    if (!member || !contributors || !depositMembers) {
        return <Loader />;
    }

    const tabs = [
        {
            key: DepositCreatorsTabs.CONTRIBUTORS,
            title: translation.contributors,
            component: <DepositCreators member={member} contributors={contributors} refresh={() => loadInfos(member)} />
        },
        {
            key: DepositCreatorsTabs.TIERS,
            title: translation.thirdParties,
            component: (
                <RequestDepositMembers
                    member={member}
                    depositMembers={depositMembers}
                    isFormOpen={openPopUp}
                    closePopupForm={refreshInfos}
                    depositMembersInvitations={invitations}
                />
            )
        }
    ];

    const handleSide = () => {
        switch (params.tab) {
            case DepositCreatorsTabs.TIERS:
                return <RequestDepositMembersSide toRequestForm={() => setOpenPopUp(true)} member={member} />;
            case DepositCreatorsTabs.CONTRIBUTORS:
            default:
                return <DepositCreatorsSide contributors={contributors} />;
        }
    };

    return (
        <Layout title={translation.depositCreatorsAndRepresentatives} icon={<PersonsIcon className="w-6 h-8 fill-primary" />} rightContent={handleSide()}>
            <Tabs tabs={tabs} />
        </Layout>
    );
}
