import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CompanyLogo, CustomLink, FormFieldsInput, InputFields, ServiceContext, useApi, useI18n } from "@vaultinum/app-sdk";
import { createRef, useContext } from "react";
import { default as ReCAPTCHA } from "react-google-recaptcha";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { Translation } from "../../i18n";
import { CONFIRMATION_REQUEST_PASSWORD_PAGE, LOGIN_PAGE } from "../../services";

export default function PasswordPage() {
    const recaptchaRef = createRef<ReCAPTCHA>();
    const { translation } = useI18n<Translation>();
    const { authentificationService } = useContext(ServiceContext);
    const { fetchApi, isLoading } = useApi();
    const navigate = useNavigate();

    const PasswordSchema: ObjectSchema<FieldValues> = yup.object({
        login: yup.string().required(translation.requiredField),
        captcha: yup.string().required(translation.requiredField)
    });

    const {
        control,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm<FieldValues>({
        resolver: yupResolver(PasswordSchema)
    });

    const resetPassword = (data: FieldValues) => {
        fetchApi(
            () => authentificationService.requestPasswordReset(data.login),
            () => {
                navigate(CONFIRMATION_REQUEST_PASSWORD_PAGE);
            }
        );
    };

    const RESET_PASSWORD_FORM: InputFields[] = [
        {
            name: "login",
            label: translation.login,
            helperText: translation.loginHelper,
            labelPosition: "top"
        }
    ];

    return (
        <main className="flex flex-col items-center py-16 space-y-12">
            <CompanyLogo className="h-12 w-60" />
            <div className="max-w-md">
                <div className="relative flex flex-col items-center p-12 border border-form">
                    <h2 className="text-2xl font-bold text-primary mb-9">{translation.resetPassword.label}</h2>
                    <form onSubmit={handleSubmit(resetPassword)} className="w-full space-y-5">
                        <FormFieldsInput className="flex flex-col" fields={RESET_PASSWORD_FORM} control={control} errors={errors} />
                        <Controller
                            name="captcha"
                            control={control}
                            render={({ field: { ref = recaptchaRef, ...field }, fieldState: { error } }) => (
                                <>
                                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ""} ref={ref} {...field} />
                                    <span className="text-xs mt-0.5 text-danger-high">{error?.message}</span>
                                </>
                            )}
                        />
                        <div className="flex flex-col items-center gap-4 pt-4">
                            <Button type="submit" label="Valider" disabled={!isValid || isLoading} />
                            <CustomLink label={translation.resetPassword.cancelAndBackToLoginPage} path={LOGIN_PAGE} disabled={isLoading} />
                        </div>
                    </form>
                </div>
            </div>
        </main>
    );
}
