import classNames from "classnames";
import { ForwardedRef, forwardRef, ReactNode, useState } from "react";

import { GroupListOptions, ListOption } from "../../model";
import { CrossEyeIcon, EyeIcon, SearchIcon } from "../Icons";

const isListOption = (option: ListOption | GroupListOptions): option is ListOption => {
    return (option as ListOption).value !== undefined;
};

const RenderOption = ({ value, label, disabled }: { value: string; label: string; disabled?: boolean }): JSX.Element => (
    <option
        key={value}
        value={value}
        disabled={disabled}
        className={classNames({
            "bg-white text-black": !disabled,
            "bg-disabled cursor-not-allowed": disabled
        })}
    >
        {label}
    </option>
);

const Input = forwardRef(
    (
        {
            onChange,
            fieldName,
            label,
            helperText,
            disabled,
            error,
            limit,
            className,
            rows,
            checked,
            value = "",
            labelPosition = "top",
            type = "text",
            size = "medium",
            cols = 20,
            options,
            placeholder,
            autofocus,
            onClick,
            uppercase
        }: {
            onChange: <T extends HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>(e: React.ChangeEvent<T>) => void;
            fieldName?: string;
            label?: ReactNode;
            helperText?: string;
            disabled?: boolean;
            error?: boolean;
            limit?: number;
            className?: string;
            rows?: number;
            checked?: boolean;
            value?: string;
            labelPosition?: "top" | "left";
            type?: "text" | "password" | "textarea" | "number" | "email" | "date" | "file" | "search" | "checkbox" | "radio" | "select";
            size?: "small" | "medium";
            cols?: number;
            options?: (ListOption | GroupListOptions)[];
            placeholder?: string;
            autofocus?: boolean;
            onClick?: () => void;
            uppercase?: boolean;
        },
        ref?: ForwardedRef<HTMLInputElement>
    ) => {
        const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

        const handleSelect = () => {
            if (options) {
                return options.map(listOption => {
                    if (isListOption(listOption)) {
                        return <RenderOption key={listOption.value} value={listOption.value} disabled={listOption.disabled} label={listOption.label} />;
                    } else {
                        return (
                            <optgroup key={listOption.label} label={listOption.label}>
                                {listOption.values.map(groupList => (
                                    <RenderOption key={groupList.value} value={groupList.value} disabled={listOption.disabled} label={groupList.label} />
                                ))}
                            </optgroup>
                        );
                    }
                });
            }
            return <option value="" />;
        };

        const handleInputsType = () => {
            switch (type) {
                case "textarea":
                    return (
                        <textarea
                            onChange={onChange}
                            disabled={disabled}
                            className={classNames("border rounded-none disabled:cursor-not-allowed focus:border-primary outline-none py-2 px-4", {
                                "border-input-border-color": !error,
                                "border-danger-high": error && !disabled,
                                "bg-disabled": disabled
                            })}
                            maxLength={limit}
                            value={value}
                            cols={cols}
                            rows={rows}
                            autoFocus={autofocus}
                        />
                    );
                case "checkbox":
                    return (
                        <div className="flex items-center">
                            <input
                                id={fieldName}
                                disabled={disabled}
                                type={"checkbox"}
                                onChange={onChange}
                                checked={checked}
                                className="w-4 h-4 checked:accent-primary focus:ring-primary cursor-pointer"
                                autoFocus={autofocus}
                                name={fieldName}
                            />
                            <label
                                htmlFor={fieldName}
                                className={classNames("w-full ml-2.5 text-sm cursor-pointer", {
                                    "text-disabled-dark": disabled,
                                    "text-label": !disabled
                                })}
                            >
                                {label}
                            </label>
                        </div>
                    );
                case "radio":
                    return (
                        <div className="flex items-center">
                            <input
                                checked={checked}
                                id={fieldName}
                                type="radio"
                                onChange={onChange}
                                value={value}
                                name={fieldName}
                                className={classNames("w-4 h-4 checked:accent-primary focus:ring-primary", {
                                    "cursor-pointer": !disabled,
                                    "cursor-not-allowed": disabled
                                })}
                                autoFocus={autofocus}
                                disabled={disabled}
                            />
                            <label
                                htmlFor={fieldName}
                                className={classNames("ml-2.5 max-w-96 text-sm", {
                                    "text-disabled-dark cursor-not-allowed": disabled,
                                    "text-label cursor-pointer": !disabled
                                })}
                            >
                                {label}
                            </label>
                        </div>
                    );
                case "select":
                    return (
                        <select
                            onChange={onChange}
                            className={classNames("border block rounded-none scroll-mt-24 focus:border-primary outline-none px-4", className, {
                                "border-input-border-color": !error,
                                "bg-white": !disabled,
                                "bg-disabled cursor-not-allowed": disabled,
                                "py-2.5": size === "medium",
                                "py-1.5": size === "small",
                                "border-danger-high": error && !disabled,
                                "text-gray-400": value === "",
                                "text-black": value !== ""
                            })}
                            value={value}
                            disabled={disabled}
                            autoFocus={autofocus}
                        >
                            {placeholder && (
                                <option value="" disabled hidden>
                                    {placeholder}
                                </option>
                            )}
                            {handleSelect()}
                        </select>
                    );
                case "password":
                    return (
                        <div
                            className={classNames("relative border rounded-none", {
                                "border-input-border-color": !error,
                                "border-danger-high": error && !disabled,
                                "bg-disabled cursor-not-allowed": disabled
                            })}
                        >
                            <input
                                ref={ref}
                                type={isPasswordVisible ? "text" : "password"}
                                className={classNames("scroll-mt-24 outline-none w-full px-4", {
                                    "py-2": size === "medium",
                                    "py-1": size === "small"
                                })}
                                onChange={onChange}
                                onClick={onClick}
                                disabled={disabled}
                                value={value}
                                autoFocus={autofocus}
                                placeholder={placeholder}
                            />
                            <span
                                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                                onMouseDown={() => setIsPasswordVisible(true)}
                                onMouseUp={() => setIsPasswordVisible(false)}
                            >
                                {isPasswordVisible ? <EyeIcon className="cursor-pointer w-5 h-5" /> : <CrossEyeIcon className="cursor-pointer w-5 h-5" />}
                            </span>
                        </div>
                    );
                default:
                    return (
                        <div className={classNames("relative flex flex-col", className)}>
                            {type === "search" && (
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <SearchIcon className="w-3.5 h-3.5" />
                                </div>
                            )}
                            <input
                                ref={ref}
                                type={type}
                                className={classNames(
                                    "border block rounded-none disabled:cursor-not-allowed scroll-mt-24 focus:border-primary outline-none px-4",
                                    {
                                        "border-input-border-color": !error,
                                        "border-danger-high": error && !disabled,
                                        "bg-disabled": disabled,
                                        "pl-10": type === "search",
                                        "py-2": size === "medium",
                                        "py-1": size === "small",
                                        uppercase
                                    }
                                )}
                                onChange={onChange}
                                onClick={onClick}
                                disabled={disabled}
                                value={value}
                                autoFocus={autofocus}
                                placeholder={placeholder}
                            />
                        </div>
                    );
            }
        };
        const isGroup = type === "checkbox" || type === "radio";
        return (
            <div className={classNames("flex w-full", { "flex-col": labelPosition === "top", "flex-row": labelPosition === "left" })}>
                {!isGroup && (
                    <div
                        className={classNames("flex text-sm font-semibold", {
                            "justify-between flex-row mb-1.5": labelPosition === "top",
                            "flex-col mr-8 text-end w-56": labelPosition === "left" && label,
                            "flex-col m-0": labelPosition === "left" && !label
                        })}
                    >
                        {label && <label className="block whitespace-nowrap">{label}</label>}
                        {type === "textarea" && limit !== undefined && <span className="font-light text-label">{`${value.length}/${limit}`}</span>}
                    </div>
                )}

                <div className="flex flex-col w-full">
                    {handleInputsType()}
                    {helperText && (
                        <span className={classNames("text-xs mt-0.5", { "text-danger-high": error, "text-color-disabled": !error })}>{helperText}</span>
                    )}
                </div>
            </div>
        );
    }
);

export default Input;
