import { CreateDepositResponse, Deposit, DepositStore, encodeUrl } from "@vaultinum/app-sdk";

import { DepositCreatorsTabs } from "../views/private/DepositCreatorsPage";
import { InvoicesAndPaymentTabs } from "../views/private/InvoicesAndPaymentsPage";
import { DepositStoreTabs } from "../views/private/WalletPage/DepositStore/DepositStorePage";

// PUBLIC PAGES
export const PUBLIC_ROOT_PAGE = process.env.PUBLIC_URL;
export const LOGIN_PAGE = `${PUBLIC_ROOT_PAGE}/login`;
export const RESET_PASSWORD_PAGE = `${PUBLIC_ROOT_PAGE}/reset_password`;
export const MEMBERS_ADVANTAGES = "https://www.app.asso.fr/lagence/avantages-membres";
export const BUY_CREDITS_V1 = process.env.REACT_APP_BUY_CREDITS_V1;
export const ADD_HOLDERS_V1 = process.env.REACT_APP_ADD_HOLDERS_V1;
export const CONTACT_SUPPORT = "https://www.app.asso.fr/contact";
export const HELP_PAGE = "https://www.app.asso.fr/aide-app-interdeposit";
export const INFO_CENTER = "https://www.app.asso.fr/centre-information)";
export const BECOME_MEMBER = "https://id.app.asso.fr/fr/adhesion";
export const LEGAL_NOTICE = "https://www.app.asso.fr/mentions-legales";
export const APP_NEWS = "https://www.app.asso.fr/centre-information/actualites";
export const ESCROW_AGREEMENT = "https://www.app.asso.fr/nos-solutions/escrow-agreement";
export const APP_ASSO = "https://www.app.asso.fr/";
export const APP_CGU = "https://www.app.asso.fr/wp-content/uploads/APP-CGU-FR-InterDepositWeb.pdf";
export const CONFIRMATION_REQUEST_PASSWORD_PAGE = `${PUBLIC_ROOT_PAGE}/confirmation_request_password`;
export const CONFIRMATION_RESET_PASSWORD_PAGE = `${PUBLIC_ROOT_PAGE}/confirmation_reset_password`;
export const CHANGE_PASSWORD_PAGE = `${PUBLIC_ROOT_PAGE}/change_password/:token`;

// PRIVATE PAGES
export const PRIVATE_ROOT_PAGE = `${PUBLIC_ROOT_PAGE}/app`;
export const CREDIT_PAGE = `${PRIVATE_ROOT_PAGE}/credits`;
export const CREDIT_BUY_PAGE = `${PRIVATE_ROOT_PAGE}/credits/buy`;
export const CREDIT_BUY_PAYMENT_PAGE = `${PRIVATE_ROOT_PAGE}/credits/buy/payment`;
export const CREDIT_BUY_SUMMARY_PAGE = `${PRIVATE_ROOT_PAGE}/credits/buy/summary`;
export const CREDIT_BUY_SUCCESS_PAGE = `${PRIVATE_ROOT_PAGE}/credits/buy/success`;
export const PAYMENT_AND_INVOICES_PAGE = `${PRIVATE_ROOT_PAGE}/payment_and_invoices_page/tab/:tab`;
export const USER_INFO_PAGE = `${PRIVATE_ROOT_PAGE}/user_info`;
export const PRIVATE_HELP_PAGE = `${PRIVATE_ROOT_PAGE}/help`;
export const MEMBER_INFO_PAGE = `${PRIVATE_ROOT_PAGE}/member_info`;
export const HOME_PAGE = `${PRIVATE_ROOT_PAGE}/dashboard`;
export const WALLET_PAGE = `${PRIVATE_ROOT_PAGE}/wallet_page`;
export const DEPOSIT_PAGE = `${PRIVATE_ROOT_PAGE}/deposit_page`;
export const DEPOSIT_PAGE_WAITING_CREDIT = `${PRIVATE_ROOT_PAGE}/deposit_page_waiting_credit`;
export const DEPOSIT_STORE_PAGE = `${PRIVATE_ROOT_PAGE}/wallet_page/deposit_store_page/:depositStoreUrl/tab/:tab`;
export const DEPOSIT_CREATORS_PAGE = `${PRIVATE_ROOT_PAGE}/deposit_creators_page/tab/:tab`;
export const REQUEST_INVITATION_PAGE = `${PRIVATE_ROOT_PAGE}/request/:requestId`;

export const CREATE_DEPOSIT_PAGE = `${PRIVATE_ROOT_PAGE}/deposit_page/create`;
export const UPDATE_DEPOSIT_PAGE = `${PRIVATE_ROOT_PAGE}/deposit_page/:depositUrl/update`;

export const DEPOSIT_STORE_CREATE_DEPOSIT_PAGE = `${PRIVATE_ROOT_PAGE}/wallet_page/deposit_store_page/:depositStoreUrl/create`;
export const DEPOSIT_STORE_UPDATE_DEPOSIT_PAGE = `${PRIVATE_ROOT_PAGE}/wallet_page/deposit_store_page/:depositStoreUrl/deposit/:depositUrl/update`;

export const DEPOSIT_STORE_RESUME_DEPOSIT_PAGE = `${PRIVATE_ROOT_PAGE}/wallet_page/deposit_store_page/:depositStoreUrl/deposit/:depositUrl/resume`;

export function toDepositStorePage(deposiStoretUrl: DepositStore, tab: DepositStoreTabs = DepositStoreTabs.DEPOSITSTORE): string {
    return `${PRIVATE_ROOT_PAGE}/wallet_page/deposit_store_page/${encodeUrl(deposiStoretUrl._links.self.href)}/tab/${tab}`;
}

export function toDepositCreatorsPage(tab: DepositCreatorsTabs = DepositCreatorsTabs.CONTRIBUTORS): string {
    return `${PRIVATE_ROOT_PAGE}/deposit_creators_page/tab/${tab}`;
}

export function toPaymentAndInvoicesPage(tab: InvoicesAndPaymentTabs = InvoicesAndPaymentTabs.INVOICES): string {
    return `${PRIVATE_ROOT_PAGE}/payment_and_invoices_page/tab/${tab}`;
}

export function toDepositStorePageUpdate(depositStore: DepositStore, deposit: CreateDepositResponse): string {
    return `${PRIVATE_ROOT_PAGE}/wallet_page/deposit_store_page/${encodeUrl(depositStore._links.self.href)}/deposit/${encodeUrl(deposit._links.self.href)}/update`;
}

export function toDepositPageUpdate(deposit: CreateDepositResponse): string {
    return `${PRIVATE_ROOT_PAGE}/deposit_page/${encodeUrl(deposit._links.self.href)}/update`;
}

export function toDepositSorePageCreate(depositStore: DepositStore): string {
    return `${PRIVATE_ROOT_PAGE}/wallet_page/deposit_store_page/${encodeUrl(depositStore._links.self.href)}/create`;
}

export function toDepositStoreResumeDepositPage(depositStore: DepositStore, deposit: Deposit): string {
    return `${PRIVATE_ROOT_PAGE}/wallet_page/deposit_store_page/${encodeUrl(depositStore._links.self.href)}/deposit/${encodeUrl(deposit._links.self.href)}/resume`;
}
