import { BoxContainer, Button, Input, Product, ShoppingCartIcon, useI18n } from "@vaultinum/app-sdk";
import { ReactNode, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { OrderItem, OrderSummary } from "../../../components/Order";
import { OrderContext } from "../../../contexts";
import { Translation } from "../../../i18n";
import { CREDIT_BUY_PAYMENT_PAGE } from "../../../services";
import CreditsPageTemplate from "./CreditsPageTemplate";

export default function CreditsBuySelectionPage(): ReactNode {
    const {
        canFillExternalReference,
        createOrder,
        creditsCatalog,
        getProductQuantityFromShoppingCart,
        getShoppingCartInformation,
        setExternalReference,
        setProductQuantityIntoShoppingCart,
        shoppingCart
    } = useContext(OrderContext);

    const { translation } = useI18n<Translation>();
    const navigate = useNavigate();

    const isEmptyShoppingCart = useMemo(() => getShoppingCartInformation(shoppingCart).isEmptyShoppingCart, [shoppingCart]);

    const toggleCreditSelection = (credit: Product): void => {
        const currentCreditQuantity = getProductQuantityFromShoppingCart(credit);

        return setProductQuantityIntoShoppingCart(credit, currentCreditQuantity === 0 ? 1 : 0);
    };

    return (
        <CreditsPageTemplate currentStep={1} description={translation.chooseCreditsAndValidate}>
            <div className="flex flex-col md:flex-row gap-2 mt-4">
                <div className="flex flex-col grow space-y-2">
                    {!!creditsCatalog &&
                        Object.entries(creditsCatalog).map(([reference, credit]) => (
                            <OrderItem
                                key={reference}
                                product={credit}
                                quantity={getProductQuantityFromShoppingCart(credit)}
                                onQuantityChange={value => setProductQuantityIntoShoppingCart(credit, value)}
                            />
                        ))}
                    {!creditsCatalog && Array.from({ length: 5 }, (_, i) => i).map(n => <OrderItem.Skeleton key={`order-item-skeleton-${n}`} />)}
                </div>
                <div className="md:w-96">
                    <BoxContainer icon={<ShoppingCartIcon className="fill-primary" />} title={translation.order}>
                        <div className="space-y-5">
                            <OrderSummary shoppingCart={shoppingCart} toggleProductSelection={toggleCreditSelection} />
                            {canFillExternalReference && (
                                <Input
                                    type="text"
                                    label={translation.externalReference}
                                    onChange={e => setExternalReference(e.target.value)}
                                    value={shoppingCart.externalReference}
                                />
                            )}
                            <div className="flex justify-center">
                                <Button
                                    label={translation.goToPaymentPage}
                                    onClick={() => navigate(CREDIT_BUY_PAYMENT_PAGE)}
                                    disabled={!createOrder || isEmptyShoppingCart}
                                />
                            </div>
                        </div>
                    </BoxContainer>
                </div>
            </div>
        </CreditsPageTemplate>
    );
}
