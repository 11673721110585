import { Loader, Product, TrashIcon, useI18n } from "@vaultinum/app-sdk";
import { ReactNode, useContext } from "react";

import { OrderContext, ShoppingCart } from "../../contexts";
import { Translation } from "../../i18n";
import { OrderTotal } from "./OrderTotal";

export function OrderSummary({
    shoppingCart,
    toggleProductSelection
}: {
    shoppingCart: ShoppingCart;
    toggleProductSelection?: (product: Product) => void;
}): ReactNode {
    const { creditsCatalog, getShoppingCartInformation } = useContext(OrderContext);

    const { translation } = useI18n<Translation>();

    const shoppingCartInformation = getShoppingCartInformation(shoppingCart);

    if (!creditsCatalog) {
        return <Loader position="relative" />;
    }

    return (
        <div className="space-y-2">
            {shoppingCartInformation.isEmptyShoppingCart && <p>{translation.emptyShoppingCart}</p>}
            {!shoppingCartInformation.isEmptyShoppingCart && (
                <>
                    <ul className="text-label space-y-2.5 mb-2.5">
                        {Object.values(creditsCatalog)
                            .filter(credit => !!shoppingCart.items[credit.reference])
                            .map(credit => {
                                return (
                                    <li key={credit.reference} className="flex space-x-2">
                                        {toggleProductSelection && (
                                            <button onClick={() => toggleProductSelection(credit)}>
                                                <TrashIcon className="h-5 w-5 fill-primary cursor-pointer" />
                                            </button>
                                        )}
                                        <span className="grow">{credit.name}</span>
                                        <span className="whitespace-nowrap font-light">
                                            {translation.formatPrice(credit.unitPriceVatExcluded)} {translation.vatExcludedSymbol} x{" "}
                                            {shoppingCart.items[credit.reference]}
                                        </span>
                                    </li>
                                );
                            })}
                    </ul>
                    <OrderTotal shoppingCart={shoppingCart} />
                </>
            )}
        </div>
    );
}
