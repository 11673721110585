import classNames from "classnames";
import { ReactNode, useEffect } from "react";

import { PERCENTAGE_SIZE_MAP, Size } from "../../referentials";
import { CloseIcon } from "../Icons";

export default function Popup({
    title,
    isOpen,
    icon,
    children,
    onClose,
    leftContent,
    rightContent,
    size = "md"
}: {
    title: string;
    isOpen: boolean;
    icon?: ReactNode;
    children?: ReactNode;
    onClose?: () => void;
    leftContent?: ReactNode;
    rightContent?: ReactNode;
    size?: Size;
}) {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpen]);

    useEffect(() => {
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    const hasContent = leftContent || rightContent;

    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="fixed inset-0 bg-black opacity-60"></div>
                    <div className={classNames("relative z-10 p-5 bg-white rounded-md shadow-lg", PERCENTAGE_SIZE_MAP[size])}>
                        <div className="flex items-start justify-between">
                            <div className={classNames("w-full", { "flex items-center": icon })}>
                                {icon}
                                <p
                                    className={classNames("uppercase text-primary font-bold", {
                                        "ml-1.5": icon
                                    })}
                                >
                                    {title}
                                </p>
                            </div>
                            {onClose && (
                                <button onClick={onClose}>
                                    <CloseIcon className="w-5 h-5" />
                                </button>
                            )}
                        </div>
                        {children && (
                            <div
                                className={classNames("overflow-y-auto pt-5 text-label", {
                                    "mb-8": leftContent || rightContent
                                })}
                            >
                                {children}
                            </div>
                        )}
                        {hasContent && (
                            <div
                                className={classNames("flex items-end h-full", {
                                    "justify-between": leftContent && rightContent,
                                    "justify-end": !leftContent && rightContent,
                                    "justify-start": leftContent && !rightContent
                                })}
                            >
                                {leftContent}
                                {rightContent}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
