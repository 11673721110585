import {
    Member,
    NatureRegister,
    OwnerLegalPerson,
    OwnerNaturalPerson,
    RegisterType,
    Registration,
    RegistrationDetail,
    RepresentativeLegalPerson,
    Request,
    RequestSender,
    ResponseSubmitStatus
} from "../model";
import { doGet, doPost } from "./api.service";
import { MemberService } from "./new-member.service";

export class RestApiMemberService implements MemberService {
    private readonly apiUrl: string;

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    private absoluteUrl(url: string): string {
        return `${this.apiUrl}${url}`;
    }

    getRequestById(id: string): Promise<Request> {
        const url = this.absoluteUrl(`/v1/requests/${id}`);
        return doGet<Request>(url);
    }

    acceptRequest(request: Request): (() => Promise<void>) | undefined {
        const link = request._links.accept;

        if (!link) {
            return undefined;
        }

        return () => doPost(link.href);
    }

    rejectRequest(request: Request): (() => Promise<void>) | undefined {
        const link = request._links.reject;

        if (!link) {
            return undefined;
        }

        return () => doPost(link.href);
    }

    getRegisters(): Promise<RegisterType> {
        const url = this.absoluteUrl("/v1/registrations/links");
        return doGet<RegisterType>(url);
    }

    getNaturalOptions(url: string): Promise<NatureRegister> {
        return doGet<NatureRegister>(url);
    }

    submitRepresentativeLegalPerson(): (form: RepresentativeLegalPerson) => Promise<ResponseSubmitStatus> {
        const url = this.absoluteUrl("/v1/registrations/representatives/legal-person");
        return form => doPost<ResponseSubmitStatus>(url, { ...form });
    }

    submitOwnerLegalPerson(): (form: OwnerLegalPerson) => Promise<ResponseSubmitStatus> {
        const url = this.absoluteUrl("/v1/registrations/owners/legal-person");
        return form => doPost<ResponseSubmitStatus>(url, { ...form });
    }

    submitOwnerNaturalPerson(): (form: OwnerNaturalPerson) => Promise<ResponseSubmitStatus> {
        const url = this.absoluteUrl("/v1/registrations/owners/natural-person");
        return form => doPost<ResponseSubmitStatus>(url, { ...form });
    }

    getRegistrationDetail(status: ResponseSubmitStatus): (() => Promise<RegistrationDetail>) | undefined {
        const link = status._links.self.href;

        if (!link) {
            return undefined;
        }

        return () => doGet<RegistrationDetail>(link);
    }

    getAllRegistrations(): Promise<Registration[]> {
        const url = this.absoluteUrl("/v1/registrations");
        return doGet<Registration[]>(url);
    }

    getRegistrationsByStatus(queryParam: Registration.Status): Promise<Registration[]> {
        const url = this.absoluteUrl("/v1/registrations");
        return doGet<Registration[]>(url, {
            status: queryParam
        });
    }

    acceptRegistration(registration: Registration): (() => Promise<void>) | undefined {
        const link = registration._links.accept;
        if (!link) {
            return undefined;
        }
        return () => doPost(link.href);
    }
    getRequests(member: Member): (() => Promise<RequestSender[]>) | undefined {
        const link = member._links.requests;

        if (!link) {
            return undefined;
        }

        return () => doGet<RequestSender[]>(link.href);
    }

    finalizeRegistration(detail: RegistrationDetail): (() => Promise<ResponseSubmitStatus>) | undefined {
        const link = detail._links.finalizeRegistration;

        if (!link) {
            return undefined;
        }

        return () => doPost<ResponseSubmitStatus>(link.href);
    }
    rejectRegistration(registration: Registration): (() => Promise<void>) | undefined {
        const link = registration._links.reject;

        if (!link) {
            return undefined;
        }

        return () => doPost(link.href);
    }
    submitRepresentativeSubscription(memberId: string): (form: OwnerLegalPerson) => Promise<ResponseSubmitStatus> {
        const url = this.absoluteUrl(`/v1/members/${memberId}/register/owner`);
        return form => doPost<ResponseSubmitStatus>(url, { ...form });
    }
}
