import { addHolder, BoxContainer, DepositMember, FormFieldsRadio, InfoTooltip, InterdepositContext, Member, TickIcon, useI18n } from "@vaultinum/app-sdk";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { RepresentativeList, StepDepositRightsUpdate, StepSharedTitularity } from ".";
import { Translation } from "../../i18n";
import { DepositRights } from "../../schemas/DepositFormNumericSchema";

function Skeleton(): JSX.Element {
    return (
        <div className="space-y-5 w-full">
            <div className="border-2 p-5 relative rounded-lg w-full">
                <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-6 py-1">
                        <div className="space-y-3">
                            <div className="grid grid-cols-6 gap-4">
                                <div className="h-2 bg-gray-200 rounded col-span-3"></div>
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-2 p-5 relative rounded-lg w-full">
                <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-6 py-1">
                        <div className="space-y-3">
                            <div className="grid grid-cols-6 gap-4">
                                <div className="h-2 bg-gray-200 rounded col-span-3"></div>
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const OneTitularity = ({ member }: { member: Member }): JSX.Element => {
    const { translation } = useI18n<Translation>();

    return (
        <div className="w-full space-y-2.5 text-label">
            <h3 className="text-sm font-bold">{translation.titularityDeposit}</h3>
            <div className="flex gap-2">
                {member && <p className="text-sm">{`${member.id} - ${member.name}`}</p>}
                <p className="font-light italic">({translation.you})</p>
            </div>
        </div>
    );
};

export default function StepDepositRights({
    member,
    memberHolders,
    representatives
}: {
    member: Member;
    memberHolders?: DepositMember[];
    representatives?: DepositMember[];
}): JSX.Element {
    const { deposit } = useContext(InterdepositContext);
    const { translation } = useI18n<Translation>();

    const { control, getValues, watch } = useFormContext();

    const choices = [
        {
            value: DepositRights.ONE_TITULARITY,
            title: translation.selfRadio(member.name),
            disabled: !deposit?._links.setSingleHolder?.available
        },
        {
            value: DepositRights.SHARED_TITULARITY,
            title: translation.oneOfContributors(member.name),
            disabled: !deposit?._links.setSharedHolders?.available
        },
        {
            value: DepositRights.REPRESENTATIVE,
            title: translation.representativeRadio(member.name),
            disabled: !deposit?._links.setRepresentative?.available
        }
    ];

    const handleSwitch = () => {
        switch (getValues("depositRights")) {
            case DepositRights.ONE_TITULARITY:
                return <OneTitularity member={member} />;
            case DepositRights.SHARED_TITULARITY:
                return <StepSharedTitularity member={member} holders={memberHolders} />;
            case DepositRights.REPRESENTATIVE:
                return <RepresentativeList representatives={representatives} />;
            default:
                return null;
        }
    };

    if (!deposit) {
        return <Skeleton />;
    }

    if (!addHolder(deposit)) {
        return <StepDepositRightsUpdate />;
    }

    return (
        <div className="w-full space-y-5">
            <BoxContainer
                title={translation.titularity}
                topRightContent={<InfoTooltip children={translation.titularityTooltip(member.name)} className="top-1 right-1" />}
                icon={watch("depositRights") && <TickIcon className="fill-green-light" />}
            >
                <FormFieldsRadio radioGroupName="depositRights" control={control} options={choices} />
            </BoxContainer>
            {handleSwitch() && <BoxContainer>{handleSwitch()}</BoxContainer>}
        </div>
    );
}
