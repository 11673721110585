import {
    BoxContainer,
    Button,
    CustomLink,
    Input,
    Order,
    PaymentMethod,
    payOrderByBankTransfer,
    ShoppingCartIcon,
    TickIcon,
    useI18n,
    useToast
} from "@vaultinum/app-sdk";
import { ReactNode, useContext, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { CheckoutForm } from "../../../components";
import { OrderSummary } from "../../../components/Order";
import { OrderContext } from "../../../contexts";
import { Translation } from "../../../i18n";
import { CREDIT_BUY_PAYMENT_PAGE, CREDIT_BUY_SUCCESS_PAGE, toPaymentAndInvoicesPage } from "../../../services";
import { InvoicesAndPaymentTabs } from "../InvoicesAndPaymentsPage";
import CreditsPageTemplate from "./CreditsPageTemplate";

const payOrder = async (
    order: Order,
    clearShoppingCart: () => void,
    setIsOrderValidated: (isOrderValidated: boolean) => void,
    navigate: NavigateFunction
): Promise<void> => {
    const payOrderByBankTransferFn = payOrderByBankTransfer(order);

    if (payOrderByBankTransferFn) {
        await payOrderByBankTransferFn();

        clearShoppingCart();

        setIsOrderValidated(true);

        navigate(toPaymentAndInvoicesPage(InvoicesAndPaymentTabs.INVOICES));
    }
};

export default function CreditsBuySummaryPage(): ReactNode {
    const { translation } = useI18n<Translation>();

    const {
        canFillExternalReference,
        clearShoppingCart,
        createOrder,
        orderCreditsWithCreditCard,
        paymentMethods,
        setExternalReference,
        setIsOrderValidated,
        shoppingCart
    } = useContext(OrderContext);

    const toast = useToast();
    const navigate = useNavigate();

    const [isValidatingOrder, setIsValidatingOrder] = useState<boolean>(false);

    const orderAndPayWithBankTransfer = async () => {
        setIsValidatingOrder(true);

        try {
            if (createOrder) {
                const order = await createOrder({
                    items: Object.entries(shoppingCart.items).map(([reference, quantity]) => ({
                        reference,
                        quantity
                    })),
                    externalReference: shoppingCart.externalReference
                });

                await payOrder(order, clearShoppingCart, setIsOrderValidated, navigate);
            }
        } catch {
            toast.error(translation.genericError(translation.paymentError));
        } finally {
            setIsValidatingOrder(false);
        }
    };

    return (
        <CreditsPageTemplate currentStep={3} description={translation.checkOrderAndValidate}>
            <div className="flex justify-center">
                <div className="grid grid-cols-1 lg:grid-cols-2 items-start gap-2 w-full xl:w-1/2 xl:max-w-4xl">
                    <div>
                        <BoxContainer icon={<ShoppingCartIcon className="fill-primary" />} title={translation.order}>
                            <div className="flex flex-col gap-4">
                                <OrderSummary shoppingCart={shoppingCart} />
                                {canFillExternalReference && (
                                    <Input
                                        type="text"
                                        label={translation.externalReference}
                                        onChange={e => setExternalReference(e.target.value)}
                                        value={shoppingCart.externalReference}
                                        disabled={true}
                                    />
                                )}
                            </div>
                        </BoxContainer>
                    </div>
                    <div>
                        <BoxContainer icon={<TickIcon className="fill-green-600" />} title={translation.paymentMethod}>
                            <div className="flex flex-col gap-6">
                                {paymentMethods
                                    .filter(({ paymentMethod }) => paymentMethod === shoppingCart.paymentMethod)
                                    .map(({ paymentMethod, icon, label, description }) => (
                                        <div className="flex flex-col gap-2" key={paymentMethod}>
                                            <p className="flex items-center gap-2">
                                                {icon} {label}
                                            </p>
                                            {!!description && <p>{description}</p>}
                                        </div>
                                    ))}
                                {shoppingCart.paymentMethod === PaymentMethod.CREDIT_CARD && orderCreditsWithCreditCard && (
                                    <div className="grid justify-stretch">
                                        <CheckoutForm
                                            shoppingCart={shoppingCart}
                                            orderCreditsWithCreditCard={orderCreditsWithCreditCard}
                                            buttonLabel={translation.validateOrderAndPay}
                                            onSuccess={() => navigate(CREDIT_BUY_SUCCESS_PAGE)}
                                        />
                                    </div>
                                )}
                            </div>
                        </BoxContainer>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center gap-3 mt-4">
                {shoppingCart.paymentMethod === PaymentMethod.BANK_TRANSFER && (
                    <Button
                        label={translation.validateOrderAndPay}
                        disabled={!shoppingCart.paymentMethod}
                        loading={isValidatingOrder}
                        onClick={orderAndPayWithBankTransfer}
                    />
                )}
                <CustomLink label={translation.goBack} path={CREDIT_BUY_PAYMENT_PAGE} className="text-sm" />
            </div>
        </CreditsPageTemplate>
    );
}
