import { Button, Stepper, useI18n } from "@vaultinum/app-sdk";
import classNames from "classnames";

import { Step } from "../../hooks/useStepController";
import { Translation } from "../../i18n";
import { DepositStep } from "../../model";

export default function DepositStepLayout({
    depositStep,
    stepController,
    backButtonAction,
    forwardButtonAction,
    submit,
    showForwardButton = true,
    isLoading
}: {
    depositStep: DepositStep;
    stepController: Step;
    backButtonAction: () => void;
    forwardButtonAction: () => void;
    submit: () => void;
    showForwardButton?: boolean;
    isLoading?: boolean;
}): JSX.Element {
    const { translation } = useI18n<Translation>();

    const steps = [
        { title: translation.depositInformation },
        { title: translation.titularity },
        { title: translation.transmission },
        { title: translation.summary },
        { title: translation.payment }
    ];

    return (
        <div className={classNames({ "space-y-8": depositStep === DepositStep.INFORMATION, "space-y-12": depositStep > DepositStep.INFORMATION })}>
            <div className="flex justify-center">{<Stepper currentStep={depositStep} stepItems={steps} className="justify-center w-full" />}</div>
            <div className="flex flex-col items-center">
                <div className="flex flex-col items-center justify-center">
                    {stepController.title && <h2 className="font-medium text-primary text-center">{stepController.title}</h2>}
                    {stepController.subtitle && <p className="text-sm text-label">{stepController.subtitle}</p>}
                </div>
            </div>
            <form className="flex flex-col items-center m-auto">{stepController.component}</form>
            {depositStep !== DepositStep.END && (
                <div className="flex flex-col items-center justify-center gap-8 mt-12">
                    {showForwardButton && (
                        <Button
                            label={stepController.forwardButton}
                            onClick={forwardButtonAction}
                            withLoader
                            disabled={stepController.isDisabled || isLoading}
                        />
                    )}
                    <Button variant="link" onClick={backButtonAction} label={stepController.backButton} size="sm" disabled={isLoading} underline />
                    {depositStep === DepositStep.INFORMATION && (
                        <Button variant="link" onClick={submit} label={translation.saveAndQuit} size="sm" disabled={isLoading} />
                    )}
                </div>
            )}
        </div>
    );
}
