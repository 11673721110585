import { Link } from "../Hateoas";
import { Member } from "../Member";

export namespace Registration {
    export enum Status {
        ACTIVE = "ACTIVE",
        WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
        APPROVED = "APPROVED",
        REJECTED = "REJECTED",
        WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT",
        PAID = "PAID"
    }

    export enum Nature {
        INDIVIDUAL_COMPANY = "INDIVIDUAL_COMPANY",
        LEGAL_PERSON = "LEGAL_PERSON",
        NATURAL_PERSON = "NATURAL_PERSON"
    }

    export interface Creator {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
    }

    export interface Member {
        id: string;
        name: string;
        siren: string;
        address: {
            line1: string;
            line2?: string;
            postalCode: string;
            city: string;
            countryCode: string;
        };
    }
}

export interface Registration {
    memberId: string;
    type: Member.Type;
    nature: Registration.Nature;
    status: Registration.Status;
    withRebate: boolean;
    hasDataBeenValidated: boolean;
    createdAt: string;
    updatedAt: string;
    member: Registration.Member;
    creator: Registration.Creator;
    _links: {
        accept: Link;
        reject: Link;
    };
}
