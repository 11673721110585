import { useI18n } from "@vaultinum/app-sdk";
import { SucessLayout } from "../../components";
import { Translation } from "../../i18n";

export default function ConfirmationRequestPasswordPage(): JSX.Element {
    const { translation } = useI18n<Translation>();
    return (
        <SucessLayout title={translation.updatePassword} subtitle={translation.spaceName}>
            <div className="py-9 text-base flex flex-col gap-3">
                <p className="font-bold">{translation.emailRequestSend}</p>
                <br />
                <p>{translation.emailNotReceive}</p>
            </div>
        </SucessLayout>
    );
}
