import dayjs from "dayjs";

import { Translation } from "..";
import { PaymentStatus } from "../../components/PaymentAndInvoices/Invoices";
import { OptionsFilter } from "../../components/Wallet/Wallet";
import { Deposit, DepositMember, Member, RequestSender, SealStatus, User, UserInfo } from "../../model";

const DEFAULT_DEPOSIT_STATUS: { [key in SealStatus]: string } = {
    OPEN: "Dépôt non finalisé",
    IN_PROGRESS: "Scellement en cours",
    SEALED: "Chiffré et scellé",
    ERROR: "Scellement en cours",
    STANDBY: "Validation admin requise",
    ARCHIVED: "Chiffré et scellé"
};

const PAYMENT_STATUS: { [key in PaymentStatus]: string } = {
    PAID: "Payée",
    UNPAID: "Impayée"
};

const DEFAULT_DEPOSITSTORE_STATUS: { [key in SealStatus]: string } = {
    OPEN: "Action requise",
    IN_PROGRESS: "Scellement en cours",
    SEALED: "Scellée",
    ERROR: "Scellement en cours",
    STANDBY: "Action requise",
    ARCHIVED: "Scellée"
};

const USER_ROLE_WITH_ARTICLE: { [key in Deposit.CreatorUserRole]: string } = {
    ADMIN: "l'administrateur",
    CONTRIBUTOR: "le contributeur",
    GIT: "Git"
};

const USER_ROLE: { [key in User.UserWalletRole]: string } = {
    ADMINISTRATOR: "Administrateur",
    CONTRIBUTOR: "Contributeur",
    GIT: "Git"
};

const OPTIONS_FILTER: { [key in OptionsFilter]: string } = {
    IDDN: "Numéro IDDN",
    ICON: "Icône",
    CONTRACT: "Contrats d'entiercement",
    HOLDERS: "Titularité de droits",
    OWNER: "Administrateur de filière"
};

const CONTRIBUTOR_STATUS: { [key in User.Status]: string } = {
    SENT: "Invitation envoyée",
    CANCELED: "Annulé",
    ACCEPTED: "Actif",
    REFUSED: "Refusé",
    REVOKED: "Suspendu"
};

const INVITATION_STATUS: { [key in RequestSender.Status]: string } = {
    SENT: "Invitation en attente...",
    REFUSED: "Invitation refusée"
};

const DEPOSIT_MEMBER_STATUS: { [key in DepositMember.Status]: string } = {
    ACCEPTED: "Acceptée",
    REFUSED: "Invitation refusée",
    INVALID: "Erreur",
    SENT: "Invitation en attente..."
};

const INVITATION_TYPE: { [key in DepositMember.Type]: string } = {
    REPRESENTATIVE: "Mandataire",
    MANDATE: "Mandant",
    COTITULARITY: "Cotitulaire",
    CONTRIBUTOR: "Contributeur",
    HOLDER: "Bénéficiaire"
};

const CREATOR_ROLE: { [key in Deposit.CreatorMemberRole]: string } = {
    MAIN_HOLDER: "Titulaire de droits",
    HOLDER: "Cotitulaire de droits",
    REPRESENTATIVE: "Mandataire"
};

export const fr: Omit<Translation, "errorHandling" | "httpErrorHandling"> = {
    name: "Français",
    label: (label: string) => `${label} :`,
    error: "Erreur",
    country: {
        AD: "Andorre",
        AE: "Émirats arabes unis",
        AF: "افغانستان",
        AG: "Antigua-et-Barbuda",
        AI: "Anguilla",
        AL: "Shqipëria",
        AM: "Arménie",
        AN: "Antilles néerlandaises",
        AO: "República de Angola",
        AR: "Argentine",
        AS: "Samoa américaines",
        AT: "Autriche",
        AU: "Australie",
        AW: "Aruba",
        AZ: "Azerbaïdjan",
        BA: "Bosnie-Herzégovine",
        BB: "Barbade",
        BD: "Bangladesh",
        BE: "Belgique",
        BF: "Burkina Faso",
        BG: "Bulgarie",
        BH: "Bahreïn",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint-Barthélemy COM",
        BM: "Bermuda",
        BN: "Brunei",
        BO: "Bolivia",
        BR: "Brésil",
        BS: "Bahamas",
        BT: "Druk Yul",
        BV: "Île Bouvet",
        BW: "Botswana",
        BY: "Biélorussie",
        BZ: "Belize",
        CA: "Canada",
        CC: "Îles Cocos",
        CD: "République démocratique du Congo",
        CF: "République centrafricaine",
        CG: "République du Congo",
        CH: "Suisse",
        CI: "Côte d'Ivoire",
        CK: "Îles Cook",
        CL: "Chili",
        CM: "Cameroun",
        CN: "Chine",
        CO: "Colombie",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Cap-Vert",
        CX: "Île Christmas",
        CY: "Chypre",
        CZ: "Tchéquie",
        DE: "Deutschland",
        DJ: "Djibouti",
        DK: "Danemark",
        DM: "Dominique",
        DO: "République dominicaine",
        DZ: "الجزائر",
        EC: "Équateur",
        EE: "Estonie",
        EG: "Égypte",
        EH: "République arabe sahraouie démocratique",
        ER: "Érythrée",
        ES: "Espagne",
        ET: "Éthiopie",
        FI: "Finlande",
        FJ: "Fidji",
        FK: "Malouines",
        FM: "États fédérés de Micronésie",
        FO: "Îles Féroé",
        FR: "France métropolitaine",
        GA: "Gabon",
        GB: "Royaume-Uni",
        GD: "Grenade",
        GE: "Géorgie",
        GF: "Guyane DROM",
        GG: "Guernesey",
        GH: "Ghana",
        GI: "Gibraltar",
        GL: "Groenland",
        GM: "Gambie",
        GN: "Guinée",
        GP: "Guadeloupe DROM",
        GQ: "Guinée équatoriale",
        GR: "Grèce",
        GS: "Géorgie du Sud-et-les îles Sandwich du Sud",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinée-Bissau",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Îles Heard-et-MacDonald",
        HN: "Honduras",
        HR: "Croatie",
        HT: "Haïti",
        HU: "Hongrie",
        ID: "Indonésie",
        IE: "Irlande",
        IL: "Israël",
        IM: "Île de Man",
        IN: "Inde",
        IO: "Territoire britannique de l'océan Indien",
        IQ: "Irak",
        IR: "Iran",
        IS: "Islande",
        IT: "Italie",
        JE: "Jersey",
        JM: "Jamaïque",
        JO: "Jordanie",
        JP: "Japon",
        KE: "Kenya",
        KG: "Kirghizistan",
        KH: "Cambodge",
        KI: "Kiribati",
        KM: "Comores",
        KN: "Saint-Christophe-et-Niévès",
        KP: "Corée du Nord",
        KR: "Corée du Sud",
        KW: "Koweït",
        KY: "Îles Caïmans",
        KZ: "Kazakhstan",
        LA: "Laos",
        LB: "Liban",
        LC: "Sainte-Lucie",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Lituanie",
        LU: "Luxembourg",
        LV: "Lettonie",
        LY: "Libye",
        MA: "Maroc",
        MC: "Monaco",
        MD: "Moldavie",
        MF: "Saint-Martin COM",
        MG: "Madagascar",
        MH: "Îles Marshall",
        MK: "Macédoine du Nord",
        ML: "Mali",
        MM: "Birmanie",
        MN: "Mongolie",
        MO: "Macao",
        MP: "Îles Mariannes du Nord",
        MQ: "Martinique DROM",
        MR: "Mauritanie",
        MS: "Montserrat",
        MT: "Malte",
        MU: "Maurice",
        MV: "Maldives",
        MW: "Malawi",
        MX: "Mexique",
        MY: "Malaisie",
        MZ: "Mozambique",
        NA: "Namibie",
        NC: "Nouvelle-Calédonie",
        NE: "Niger",
        NF: "Île Norfolk",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Pays-Bas",
        NO: "Norvège",
        NP: "Népal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "Nouvelle-Zélande",
        OM: "Oman",
        PA: "Panama",
        PE: "Pérou",
        PF: "Polynésie française COM",
        PG: "Papouasie-Nouvelle-Guinée",
        PH: "Philippines",
        PK: "Pakistan",
        PL: "Pologne",
        PM: "Saint-Pierre-et-Miquelon COM",
        PN: "Îles Pitcairn",
        PR: "Porto Rico",
        PS: "Palestine",
        PT: "Portugal",
        PW: "Palaos",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "La Réunion DROM",
        RO: "Roumanie",
        RS: "Serbie",
        RU: "Russie",
        RW: "Rwanda",
        SA: "Arabie saoudite",
        SB: "Îles Salomon",
        SC: "Seychelles",
        SD: "Soudan",
        SE: "Suède",
        SG: "Singapour",
        SH: "Sainte-Hélène, Ascension et Tristan da Cunha",
        SI: "Slovénie",
        SJ: "Svalbard et ile Jan Mayen",
        SK: "Slovaquie",
        SL: "Sierra Leone",
        SM: "Saint-Marin",
        SN: "Sénégal",
        SO: "Somalie",
        SR: "Suriname",
        SS: "Soudan du Sud",
        ST: "Sao Tomé-et-Principe",
        SV: "Salvador",
        SY: "Syrie",
        SZ: "Eswatini",
        TC: "Îles Turques-et-Caïques",
        TD: "Tchad",
        TF: "Terres australes et antarctiques françaises",
        TG: "Togo",
        TH: "Thaïlande",
        TJ: "Tadjikistan",
        TK: "Tokelau",
        TM: "Turkménistan",
        TN: "Tunisie",
        TO: "Tonga",
        TP: "Timor oriental",
        TR: "Turquie",
        TT: "Trinité-et-Tobago",
        TV: "Tuvalu",
        TW: "Taïwan / (République de Chine (Taïwan))",
        TZ: "Tanzanie",
        UA: "Ukraine",
        UG: "Ouganda",
        UM: "Îles mineures éloignées des États-Unis",
        US: "États-Unis",
        UY: "Uruguay",
        UZ: "Ouzbékistan",
        VA: "Saint-Siège (État de la Cité du Vatican)",
        VC: "Saint-Vincent-et-les-Grenadines",
        VE: "Venezuela",
        VG: "Îles Vierges britanniques",
        VI: "Îles Vierges des États-Unis",
        VN: "Viêt Nam",
        VU: "Vanuatu",
        WF: "Wallis-et-Futuna COM",
        WS: "Samoa",
        YE: "Yémen",
        YT: "Mayotte DROM",
        ZA: "South Africa - Suid-Afrika - Afrika-Borwa",
        ZM: "Zambie",
        ZW: "Zimbabwe"
    },
    member: {
        label: "Membre",
        plural: "Membres",
        legalForm: "Forme juridique",
        legalIdentity: "Personnalité juridique",
        memberNumber: "Numéro de membre",
        memberType: "Type de membre",
        nature: {
            INDIVIDUAL_COMPANY: "Entreprise individuelle",
            LEGAL_PERSON: "Personne morale",
            NATURAL_PERSON: "Personne physique"
        },
        status: {
            VALID: "Actif",
            EXPIRED: "Expiré",
            CANCELED: "Résilié"
        },
        creditStatus: {
            ACTIVE: "Actif",
            EXPIRED: "Expiré"
        },
        list: "Liste des membres",
        foundCount: {
            0: "Aucun membre trouvé",
            1: "1 membre trouvé",
            plural: count => `${count} membres trouvés`
        },
        dueDateWithLabel: "Date d'échéance de l'abonnement :",
        creatorRoleAbbreviation: {
            MAIN_HOLDER: {
                ADMIN: "AD",
                CONTRIBUTOR: "CO",
                GIT: "GIT"
            },
            HOLDER: {
                ADMIN: "AD",
                CONTRIBUTOR: "CO",
                GIT: "GIT"
            },
            REPRESENTATIVE: {
                ADMIN: "MA-AD",
                CONTRIBUTOR: "MA-CO",
                GIT: "MA-GIT"
            }
        }
    },
    deposit: {
        depositName: "Dépôt",
        depositWithLabel: "Dépôt :",
        lastDeposit: "Dernier dépôt",
        oldDepoists: "Dépôts passés",
        firstDepositWithDate: "Premier dépôt le :",
        lastUpdateWithDate: "Dernière mise à jour le :",
        numberOfDeposit: {
            0: "Aucun dépôt réalisé sur cette filière",
            1: "1 dépôt sur cette filière",
            plural: count => `${count} dépôts sur cette filière`
        },
        editDeposit: "Editer les informations de la filière",
        transfertDeposit: "Transfert de filière",
        handleDeposit: "Gérer le dépôt",
        downloadCertificate: "Télécharger le certificat",
        postal: "Physique",
        depositType: {
            PHYSICAL: "Physique",
            DIGITAL: "Numérique",
            UNKNOWN: "Autres"
        },
        availableCount: {
            0: "Aucun dépôt",
            1: "1 dépôt",
            plural: count => `${count} dépôts`
        },
        achievedCount: {
            0: "Aucun dépôt réalisé",
            plural: count => `${count}`
        },
        availableStoresCount: {
            0: "Aucune filière disponible",
            plural: count => `${count}`
        },
        export: "Exporter la liste des dépôts",
        transfer: "Transfert de droits",
        depositor: "Déposant",
        depositors: "Déposants",
        beneficiaries: "Bénéficiaires",
        workNature: {
            SOURCE_CODE: "Logiciel (code source)",
            EXECUTABLE_SOFTWARE: "Logiciel (exécutable)",
            DATABASE: "Base de données",
            OTHER: "Autre"
        },
        workType: {
            FIRST_WORK: "Œuvre première",
            COMPOSITE_WORK: "Œuvre composée",
            DERIVATIVE_WORK: "Œuvre dérivée",
            UNDEFINED: "Indéterminé"
        },
        productClass: {
            SYSTEM_SOFTWARE: "Logiciel système",
            SYSTEM_SOFTWARE_OPERATING_SYSTEMS: "Systèmes d'exploitation",
            SYSTEM_SOFTWARE_DATA_TRANSMISSION: "Transmission de données",
            SYSTEM_SOFTWARE_DATABASES: "Bases de données (SGBD)",
            SYSTEM_SOFTWARE_PROGRAMMING_LANGUAGE: "Langage de programmation",
            SYSTEM_SOFTWARE_END_USER_FRIENDLY_LANGUAGE: "Langage adapté à l'utilisateur final",
            SYSTEM_SOFTWARE_SUPPORT_FOR_DEVELOPMENT: "Assistance pour le développement",
            SYSTEM_SOFTWARE_SYSTEM_OPERATION_MANAGEMENT: "Gestion d'exploitation de système",
            SYSTEM_SOFTWARE_UTILITIES: "Utilitaire",
            UNIVERSAL_SOFTWARE: "Logiciel universel",
            UNIVERSAL_SOFTWARE_PLANNING_MANAGEMENT: "Planification / Gestion",
            UNIVERSAL_SOFTWARE_ACCOUNTING: "Comptabilité",
            UNIVERSAL_SOFTWARE_PERSONNEL_PAYROLL: "Personnel / Salaires",
            UNIVERSAL_SOFTWARE_SALES_INVENTORY: "Ventes / Inventaire",
            UNIVERSAL_SOFTWARE_PRODUCTION: "Production",
            UNIVERSAL_SOFTWARE_DESIGN_STUDY_PROJECT: "Conception / étude / projet",
            UNIVERSAL_SOFTWARE_FORECASTING_STATISTICS_ANALYSIS: "Prévision / Statistique / Analyse",
            UNIVERSAL_SOFTWARE_OFFICE_AUTOMATION: "Bureautique",
            UNIVERSAL_SOFTWARE_INFORMATION_ENGINEERING: "Ingénierie d'information",
            UNIVERSAL_SOFTWARE_IMAGE_PROCESSING: "Traitement d'images",
            UNIVERSAL_SOFTWARE_CAE: "EAO",
            SPECIAL_SOFTWARE: "Logiciel specialisé",
            SPECIAL_SOFTWARE_FARMING: "Agriculture",
            SPECIAL_SOFTWARE_WATER_AND_FOREST: "Eaux et Forêts",
            SPECIAL_SOFTWARE_FISHING: "Pêche",
            SPECIAL_SOFTWARE_MINING: "Exploitation minière",
            SPECIAL_SOFTWARE_BUILDING: "Construction",
            SPECIAL_SOFTWARE_PRODUCTION: "Production",
            SPECIAL_SOFTWARE_FEED: "Alimentation",
            SPECIAL_SOFTWARE_TEXTILE_AND_CLOTHING: "Textile et habillement",
            SPECIAL_SOFTWARE_WOOD_PULP_AND_PAPER: "Bois, pâte et papier",
            SPECIAL_SOFTWARE_PUBLICATION_AND_PRINTING: "Publication et impression",
            SPECIAL_SOFTWARE_CHEMICALS_AND_RELATED_INDUSTRIES: "Chimie et industries dérivées",
            SPECIAL_SOFTWARE_STONE_CLAY_AND_GLASS_PRODUCTS: "Produits en pierre, argile et verre",
            SPECIAL_SOFTWARE_METAL_PRODUCTS: "Produits métalliques",
            SPECIAL_SOFTWARE_MACHINERY_AND_EQUIPMENT: "Machines et Matériels",
            SPECIAL_SOFTWARE_ELECTRICAL_MACHINES: "Machines électriques",
            SPECIAL_SOFTWARE_TRANSPORT_EQUIPMENT: "Matériels de transport",
            SPECIAL_SOFTWARE_ELECTRICITY_GAS_HEAT_WATER_SUPPLY: "Fourniture électricité/gaz/chaleur/eau",
            SPECIAL_SOFTWARE_ELECTRICITY: "Electricité",
            SPECIAL_SOFTWARE_GAS: "Gaz",
            SPECIAL_SOFTWARE_WATER_SUPPLY: "Fourniture d'eau",
            SPECIAL_SOFTWARE_TRANSPORT_COMMUNICATION: "Transport / Communication",
            SPECIAL_SOFTWARE_TRANSPORT: "Transport",
            SPECIAL_SOFTWARE_COMMUNICATION: "Communication",
            SPECIAL_SOFTWARE_SALES_RESTAURANTS_AND_DRINKING_PLACES: "Ventes / Restaurants et débits de boissons",
            SPECIAL_SOFTWARE_WHOLESALE: "Vente en gros",
            SPECIAL_SOFTWARE_RETAIL_SALES: "Vente au détail",
            SPECIAL_SOFTWARE_RESTAURANTS_AND_DRINKING_PLACES: "Restaurants et débits de boissons",
            SPECIAL_SOFTWARE_FINANCIAL_AND_INSURANCE_ACTIVITIES: "Activités financières et d'assurances",
            SPECIAL_SOFTWARE_BANKING_AND_TRUST_ACTIVITIES: "Activités bancaires et fiduciaires",
            SPECIAL_SOFTWARE_FINANCIAL_BROKERAGE: "Courtage financier",
            SPECIAL_SOFTWARE_INSURANCE: "Assurances",
            SPECIAL_SOFTWARE_REAL_ESTATE: "Immobilier",
            SPECIAL_SOFTWARE_SERVICES: "Services",
            SPECIAL_SOFTWARE_RENTAL: "Location",
            SPECIAL_SOFTWARE_HOTELS_AND_HOSTELS: "Hôtels et auberges",
            SPECIAL_SOFTWARE_BROADCASTING_AND_ADVERTISING: "Radiodiffusion et publicité",
            SPECIAL_SOFTWARE_INFORMATION_SERVICES: "Services d'information",
            SPECIAL_SOFTWARE_MISCELLANEOUS_SERVICES: "Services divers",
            SPECIAL_SOFTWARE_MEDICAL_HEALTH_AND_SANITARY_SERVICES: "Médecine, santé et sanitaire",
            SPECIAL_SOFTWARE_EDUCATION_AND_SCIENTIFIC_RESEARCH: "Education et recherche scientifique",
            SPECIAL_SOFTWARE_PUBLIC_SERVICES: "Services publics",
            SPECIAL_SOFTWARE_LEISURE_AND_FAMILY_LIFE: "Loisirs et vie familiale",
            SPECIAL_SOFTWARE_OTHER: "Autres",
            MULTIMEDIA_DATABASE: "Multimédia / Bases de données",
            MULTIMEDIA_DATABASE_REPLICATION_2D: "Reproduction numérique d'une œuvre 2 D (peinture, photo, texte, ...)",
            MULTIMEDIA_DATABASE_REPLICATION_3D: "Reproduction numérique d'œuvre 3 D",
            MULTIMEDIA_DATABASE_REPLICATION_ANIMATION: "Reproduction numérique d'image animée",
            MULTIMEDIA_DATABASE_REPLICATION_SOUND: "Reproduction numérique d'un son",
            DIGITAL_CREATION: "CREATION NUMERIQUE",
            DIGITAL_CREATION_2D: "Création numérique 2 D",
            DIGITAL_CREATION_3D: "Création numérique 3D",
            DIGITAL_CREATION_ANIMATION: "Création d'une image animée",
            DIGITAL_CREATION_SOUND: "Création numérique d'un son",
            DIGITAL_CREATION_PICTURE: "Création d'une photo numérique",
            WEBSITE: "Site web",
            UNDEFINED: "Indeterminée"
        },
        subscriptionType: "Type de dépôt",
        subscriptionTypeCode: {
            C: "Contrôlé ou vérifié",
            D: "Diffusion ou CNDA",
            R: "Référencement",
            S: "Standard",
            X: "Inconnu"
        },
        sealStatus: {
            DIGITAL: DEFAULT_DEPOSIT_STATUS,
            PHYSICAL: { ...DEFAULT_DEPOSIT_STATUS, IN_PROGRESS: "APP attend les supports", SEALED: "Scellé", ARCHIVED: "Scellé" },
            UNKNOWN: DEFAULT_DEPOSIT_STATUS
        },
        depositStoreStatus: DEFAULT_DEPOSITSTORE_STATUS,
        dateLabel: "Date de la demande",
        updateSuccess: "Les informations du dépôt ont été mises à jour avec succès."
    },
    physicalDeposits: "Dépôts physiques",
    depositStore: {
        name: "Filière",
        names: "Filières",
        title: "Nom de l'œuvre",
        subtitle: "Sous-titre",
        labelWithName: "Filière :",
        sealedDate: "Date du dépôt",
        version: "Version :",
        dateVersion: "Date de la version",
        workNature: "Nature de l'œuvre :",
        productClass: "Classe de produits :",
        workType: "Type d'œuvre :",
        workNationality: "Nationalité de l'œuvre :",
        numberDeposit: "Nombre de fichiers :",
        depositStoreSize: "Taille du dépôt :",
        logibox1: "Logibox 1",
        logibox1WithLabel: "Logibox 1 :",
        logibox2: "Logibox 2",
        logibox2WithLabel: "Logibox 2 :",
        logiboxAdhWithLabel: "Logibox Membre :",
        logiboxAppWithLabel: "Logibox App :",
        logiboxAdh: "Logibox Membre",
        logiboxApp: "Logibox App",
        logiboxDuplicateError: "Numéro de Logibox déjà attribué",
        quantitySupport: "Quantité du support :",
        depositType: "Type de support :",
        additionnalInformation: "Informations additionnelles :"
    },
    user: {
        roles: {
            ADMINISTRATOR: "Administrateur",
            DEVELOPER: "Développeur",
            SUPER_ADMINISTRATOR: "Super administrateur",
            ADMIN: "Administrateur",
            PUBLISH_USER: "Contributeur"
        }
    },
    portfolio: "Portefeuille",
    card: "Fiche",
    credits: "Crédits",
    escrows: "Entiercements",
    invoices: "Factures",
    annotations: "Notes",
    activity: "Activité",
    vatCode: "Code TVA",
    subscription: "Adhésion",
    registerDate: "Date inscription",
    expiration: "Echéance",
    subscriptionDateExpiration: "Date expiration abonnement",
    siren: "SIREN",
    companyNumber: "Numéro d'entreprise (Ex. SIREN)",
    actions: "Actions",
    information: "Informations",
    address: "Adresse",
    administrator: "Administrateur",
    uuidLabel: "UUID :",
    record: "Enregistrement",
    summary: "Récapitulatif",
    reject: "Refuser",
    dashboard: "Tableau de bord",
    nextPage: "Page suivante",
    goBack: "Revenir en arrière",
    remarks: "Remarques",
    title: "Titre",
    subtitle: "Sous-titre",
    version: "Version",
    noResult: "Aucun résultat",
    home: "Accueil",
    representative: "Mandataire",
    delete: "Supprimer",
    deleteDeposit: "Supprimer le dépôt",
    expendAll: "Tout étendre",
    collapseAll: "Tout réduire",
    input: {
        helperText: "IDDN, Nom d'un dépôt",
        helperTextSearch: "Rechercher un dépôt"
    },
    update: "Mettre à jour",
    managedByRepresentative: "Administré par un mandataire",
    ownershipShared: {
        1: `Titularité partagée avec 1 autre titulaire`,
        plural: (nbTitulaire: number) => `Titularité partagée avec ${nbTitulaire} autres titulaires`
    },
    cotitularity: "Cotitularité",
    archive: "Archivage",
    technicalInformation: "Informations techniques",
    support: "Support",
    askSupport: "Faire une demande de support",
    saveAndBackToDepositStore: "Enregistrer et retourner à la filière",
    cancelAndBackToDepositStore: "Annuler et retourner à la filière",
    mandatory: "Obligatoire",
    sealed: "Scellement",
    description: "Sous-titre/description",
    copied: "copié",
    copy: "Copier",
    disconnect: "Se déconnecter",
    offlineMessage: "Vous semblez être hors ligne. Vérifiez votre connexion Internet puis rechargez la page.",
    agreementStatus: {
        ACCESS_CLAUSE_STANDARD: "Entiercement géré par l'APP",
        ACCESS_CLAUSE_PREMIUM: "Abonnement premium Clause d'accès",
        ESCROW: "Contrat d'entiercement tripartite"
    },
    contracts: "Contrats",
    active: "Actif",
    inactive: "Inactif",
    depositsFoundByDepositStore: "Dépôts trouvés dans les filières existantes",
    deposits: "Dépôts",
    file: {
        units: {
            BYTE: "o",
            KILO_BYTE: "Ko",
            MEGA_BYTE: "Mo",
            GIGA_BYTE: "Go",
            TERA_BYTE: "To"
        },
        decimal: 2
    },
    helperTextSizeLimit:
        "Le dépôt final ne doit pas excéder 10Go, chaque fichier ne peut dépasser 4 Go. Préférez le téléversement d'un .zip pour conserver l'arborescence des fichiers. L'envoi de fichiers volumineux, notamment de certains types, peut parfois entraîner une brève période pendant laquelle votre navigateur peut ne pas répondre. Veuillez patienter durant ce court moment.",
    labelDropHere: "Déposer votre .zip ou vos fichiers ici",
    where: "ou",
    sizeLimitTotaFilelExceeded: (totalFile: string) => `Limite de fichiers dépassée. Vous pouvez télécharger jusqu'à ${totalFile} fichiers.`,
    sizeLimitExceeded: (fileSize: string) => `Un ou plusieurs fichiers dépassent la taille maximale autorisée de ${fileSize}.`,
    sizeLimitTotalExceeded: (total: string) => `Limite de taille totale dépassée. La taille totale maximale autorisée est ${total}.`,
    folderNotAuthorized: "Les dossiers ne sont pas autorisés.",
    files: "Fichiers",
    selectFiles: "Sélectionner des fichiers",
    wallet: {
        noDepositInPortfolio: "Le portefeuille ne contient aucun dépôt pour le moment"
    },
    httpCodes: {
        400: "Requête incorrecte",
        401: "Non autorisé",
        500: "Erreur technique"
    },
    requiredField: "Ce champ est obligatoire",
    contractType: "Type d'entiercement",
    nextStep: "Étape suivante",
    clickNextStep: `Cliquez ensuite sur "Étape suivante" pour continuer.`,
    titularity: "Titularité de droits",
    titularityDeposit: "Titularité de droits du déposant",
    depositMean: "Moyen de dépôt",
    chooseDepositMean: "Choisissez le moyen de dépôt",
    chooseDepositType: "Choisissez le type de dépôt",
    depositInformation: "Informations du dépôt",
    depositInformationNumeric: "Les informations de votre œuvre numérique",
    transmission: "Fichiers à transmettre",
    transmissionName: (name: string) => `Transmission de ${name} à l'APP`,
    titularityStep: {
        option1: {
            title: (name: string) => `${name} est l'unique titulaire de droits`,
            description: (name: string) => [
                `${name} apparaitra comme le seul titulaire de droits du dépôt sur le certificat de dépôt.`,
                `Dans quel cas choisir cette option ? ${name} est par exemple le créateur unique de l'œuvre déposée.`
            ]
        },
        option2: {
            title: (name: string) => `${name} partage la titularité de droits `,
            description: (name: string) => [
                `${name} apparaitra au côté d'autres titulaires de droits sur le certificat de dépôt.`,
                "Dans quel cas choisir cette option ? Par exmple, le dépôt d'une œuvre collective ou de collaboration"
            ]
        }
    },
    addMandantsHelper: (name: string) => (
        <>
            Vous souhaitez que <strong>{name}</strong> puisse avoir mandat pour déposer au nom d'autres membres APP dans le cadre de dépôts standards et
            d'entiercements ? Devenez mandataire en ajoutant un ou plusieurs mandants à votre compte membre.
        </>
    ),
    addMandants: "Ajouter des mandants",
    helper: {
        title: "Généralement, le nom donné au logiciel déposé",
        subtitle: "Peut être un titre alternatif ou une courte description",
        versionDate: "Date à laquelle la version de l'œuvre a été donnée",
        quantityAndSupportType: "Ex : 1 clé USB et 2 feuilles de papier",
        remarks: "Le contenu sera affiché sur le certificat du dépôt"
    },
    versionDate: "Date de version",
    work: {
        nature: "Nature de l'œuvre",
        type: "Type de l'œuvre",
        nationality: "Nationalité de l'œuvre"
    },
    productClass: "Classe produit",
    contactApp: "Transmettre à l'APP",
    learnMore: "En savoir plus",
    initialDeposit: "Dépôt initial",
    automatiqueCreationDepositStore: (
        <>
            <p>Il s'agit du premier dépôt d'une œuvre jamais déposée à l'APP.</p>
            <br />
            <p>Faire un dépôt initial vous permet de créer une nouvelle filière de dépôts.</p>
            <br />
            <p>Rappel : Vous devez déposer une seule œuvre par dépôt : une œuvre = une filière de dépôts.</p>
        </>
    ),
    creditUse: "Un crédit sera déduit de votre solde actuel",
    drop: "Créer une filière",
    updateDepositStore: "Dépôt de mise à jour",
    howToUpdateDepositStore: {
        title: "Pour mettre à jour une filière déjà existante :",
        step1: 'Allez dans "Portefeuille"',
        step2: "Sélectionnez la filière concernée",
        step3: 'Cliquez sur le bouton "Mettre à jour".'
    },
    goToWallet: "Aller sur le portefeuille",
    useGit: "Vous pouvez maintenant faire un “git push” et déposer votre code source sur l'APP en même temps que vous mettez à jour votre repository git !",
    useGit2:
        "Quelle que soit la plateforme de gestion de versions de code source que vous utilisez (GitHub, GitLab, Bitbucket, etc.), protéger vos œuvres numériques devient simple et s'intègre harmonieusement dans vos flux de travail CI/CD.",
    contactUs: "Contactez-nous",
    toKnowMore: "pour en savoir plus.",
    didYouKnow: "Le saviez-vous",
    transmissionSucessTitle: "Téléversement de votre œuvre réalisé avec succès",

    transmissionSucessGoToWallet: "Aller au portefeuille",
    backToDashboard: "Retour au tableau de bord",
    depositInformationTitle: "Informations du dépôt",
    agreementSealed:
        "En cochant cette case, vous consentez expressément à un débit du nombre de crédits nécessaires depuis votre portefeuille de membre et à la transmission de votre création numérique à l'APP. Vous comprenez que celle-ci sera conservée sur des serveurs situés en France. Vous confirmez également l'exactitude des informations que vous avez fournies.",
    submit: "Valider",
    stepRecapSubtitle: `Vérifiez minutieusement les informations fournies puis cliquez sur "Valider et transmettre à l'APP"`,
    onlyOne: "Unique",
    selfRadio: name => `${name} est l'unique titulaire de droits`,
    oneOfContributors: name => `${name} est l'un des titulaires de droits`,
    representativeRadio: name => `${name} n'est pas titulaire de droits mais il est le mandataire du ou des titulaires de droits`,
    share: "Partager",
    shared: "Partagée",
    sharedWidthAdmin: {
        1: "1 titulaire",
        plural: (nbTitulaire: number) => `${nbTitulaire} titulaires, dont vous`
    },
    sharedWidth: {
        1: "1 titulaire",
        plural: (nbTitulaire: number) => `${nbTitulaire} titulaires`
    },
    complete: "Sélectionnés",
    confirmed: "Confirmé",
    informed: "Renseignées",
    infoInitialDepositInProgress: (creatorInfos: string, creatorRole: Deposit.CreatorUserRole | undefined) => (
        <p>
            <strong>Attention : </strong>Un dépôt a été initié par{creatorRole && ` ${USER_ROLE_WITH_ARTICLE[creatorRole]}`} {creatorInfos} pour cette filière
            et doit être complété ou annulé afin de poursuivre la mise à jour du reste de la filière.
        </p>
    ),
    infoInitialDepositUploadCanceled: (creatorInfos: string, creatorRole: Deposit.CreatorUserRole | undefined) => (
        <p>
            <strong>Attention ! Téléversement interrompu : </strong>Un dépôt a été initié par{creatorRole && ` ${USER_ROLE_WITH_ARTICLE[creatorRole]}`}{" "}
            {creatorInfos} pour cette filière mais son téléversement a été interrompu. Annulez le téléversement interrompu pour poursuivre la mise à jour de la
            filière.
        </p>
    ),
    cancelUpload: "Annuler le téléversement interrompu",
    continue: "Continuer",
    warning: "Attention",
    cancel: "Annuler",
    depositInprogress: "Dépôt en cours",
    noContractForThisDepositStore: "Pas d'entiercement géré par l'APP",
    depositSize: "Taille du dépôt",
    addedToClipboard: (value: string) => `"${value}" ajouté au presse papier`,
    appButton: "Valider et transmettre à l'APP",
    cancelDeposit: {
        label: "Annuler la demande",
        question: "Annuler la demande de dépôt ?",
        message: "Êtes-vous certain de vouloir annuler la demande de dépôt ? Cette action est irréversible et la demande sera définitivement supprimée.",
        confirm: "Confirmer l'annulation",
        success: "Le dépôt a été supprimé"
    },
    depositCancelToast: "Dépôt annulé avec succès",
    informationNotAvailableYet: "Information non disponible pour le moment",
    undefined: "N/A",
    userWalletIdentity: (user: UserInfo) => `${user.firstName} ${user.lastName}`,
    userWalletRole: (userRole: User.UserWalletRole) => USER_ROLE[userRole],
    users: "Utilisateurs",
    availablePlural: "Disponibles",
    consummed: "Consommés",
    expireAt: "Fin de validité",
    expiredPlural: "Expirés",
    statut: "Statut",
    purchase: "Acheter",
    purchasedPlural: "Achetés",
    editCredits: "Editer les crédits",
    availableCredits: "Solde de crédits disponibles",
    allocatedCredit: "Pour les contributeurs",
    nonAllocatedCredit: "Pour l'administrateur",
    appName: "APP - Agence pour la Protection des Programmes",
    displaySettings: "Options d'affichage",
    hideCheckedElements: "Masquer les éléments cochés",
    resetDisplaySettings: "Réinitialiser l'affichage",
    availableUserCredits: {
        0: "0 crédit disponible",
        1: "1 crédit disponible",
        plural: count => `${count} crédits disponibles`
    },
    lostUserCredits: {
        1: "1 expiré",
        plural: count => `${count} expirés`
    },
    buyCredits: "Acheter des crédits",
    allocated: "Alloués",
    tableCreditName: "Prénom Nom",
    sharedDepositStore: "Cotitularité déclarée à l'APP",
    nonSharedDepositStore: "Pas de cotitularité déclarée à l'APP",
    depositStoreManagedByRepresentative: "Filière gérée par un mandataire",
    saveModification: "Enregistrer les modifications",
    cancelModification: "Annuler les modifications",
    depositStoreManagedByHolder: "Pas de mandataire, filière gérée par le membre",
    onlyVisibleByAPP: "Uniquement visible par l'APP",
    depositRequest: "Demande de dépôt",
    creatorIdentityLabel: "Identité du déposant :",
    workDescription: "Description de l'œuvre",
    subtitleLabel: "Sous-titre :",
    depositSeal: "Scellement du dépôt",
    depositTypeLabel: "Type de dépôt :",
    depositModeLabel: "Mode de dépôt :",
    depositNameLabel: "Nom :",
    creatorUserRole: {
        ADMIN: "Administrateur",
        CONTRIBUTOR: "Contributeur",
        GIT: "Git"
    },
    role: "Rôle",
    successCreditModification: "Crédits modifiés avec succès",
    findOrSelect: "Recherchez et/ou sélectionnez",
    representativeAddedInfo: (representativeName: string) => (
        <p>
            <strong>Attention :</strong> Un mandataire ne peut pas cœxister avec vous même en tant qu'administrateur, ni avec vos contributeurs (s'il y en
            avait) en ce qui concerne la possibilité de mettre à jour cette filière.
            <br />
            <br />
            Vous perdrez la capacité de mettre à jour la filière au profit du mandataire : {representativeName}. Si des contributeurs étaient associés à cette
            filière, leurs droits de mise à jour seront aussi révoqués. Vous pouvez retirer ces droits au mandataire à tout moment. Vous récupérerez alors la
            capacité pour mettre à jour la filière et d'y associer des contributeurs.
        </p>
    ),
    representativeSelectedInfo: (
        <p>
            <strong>Pour rappel :</strong> Un mandataire possède les droits de mise à jour de cette filière. Vous pouvez retirer ces droits au mandataire à tout
            moment. Vous récupérerez alors vos droits pour mettre à jour la filière et d'associer de nouveaux contributeurs.
        </p>
    ),
    representativeRemovedInfo: (representativeName: string) => (
        <p>
            <strong>Attention :</strong> Le mandataire {representativeName} possédait jusqu'à présent les droits de mise à jour de la filière. Ces droits sont
            sur le point de lui être retirés.
        </p>
    ),
    administrators: "Administrateurs",
    contributors: "Contributeurs",
    representatives: "Mandataires",
    removeFilters: "Supprimer les filtres",
    filterByContributors: "Filtrer par déposant",
    optionsFilters: OPTIONS_FILTER,
    addRequiredMarkerToString: (string: string) => (
        <>
            {string} <span className="text-danger-high">*</span>
        </>
    ),
    contributorsOfDepositStore: "Déposants de la filière",
    findByContributors: "Rechercher parmi les déposants disponibles",
    saveContributors: "Enregistrer les déposants",
    managedContributors: "Administrer déposants",
    contributorsUpdated: "Les déposants ont été mis à jour avec succès",
    responsabilityDeposit: "Responsabilité des mises à jour du dépôt",
    depositCopyValue: (deposit: Deposit) => {
        const subtitle = deposit.subtitle ? ` (${deposit.subtitle})` : "";
        const name = `${deposit.name}${subtitle}`;
        const version = deposit.workVersion ? ` version ${deposit.workVersion}` : "";
        const versionDate = deposit.workVersionDate ? ` en date du ${dayjs(deposit.workVersionDate).format("DD/MM/YYYY")}` : "";

        return `dépôt n° ${deposit.iddn} en date du ${dayjs(deposit.sealedAt).format("DD/MM/YYYY")} de "${name}${version}${versionDate}"`;
    },
    resume: "Résumé",
    uuid: "UUID",
    parentUUID: "UUID Parent",
    date: "Date",
    reference: "Référence",
    article: "Article",
    articles: "Articles",
    priceExcludingTax: "Montant HT",
    priceIncludingTax: "Montant TTC",
    payment: "Paiement",
    status: "Statut",
    paymentStatus: (status: PaymentStatus) => PAYMENT_STATUS[status],
    invoicesHistory: "Historique de facturations",
    depositCreatorName: (member: Member) => `Contributeurs déposants de : ${member.name}`,
    depositCreatorInfo:
        "Un contributeur déposant est généralement un utilisateur de votre organisation, par exemple un ou une collègue, responsable de faire des dépôts.",
    depositMemberStatus: DEPOSIT_MEMBER_STATUS,
    suspend: "Suspendre",
    activate: "Activer",
    inviteContributor: "Inviter un contributeur",
    email: "Email",
    lastName: "Nom",
    firstName: "Prénom",
    addContributorInfo: (
        <>
            <p>Vous vous apprêtez à inviter un nouveau contributeur. Dès que l'utilisateur aura accepté votre invitation, il disposera des droits suivants :</p>
            <ul className="list-disc">
                <li>Procéder à des dépôts initiaux ;</li>
                <li>
                    Il ne pourra pas consulter le portefeuille complet du membre. Vous devrez lui associer une ou plusieurs filières déjà existantes si vous
                    souhaitez qu'il puisse les mettre à jour.
                </li>
            </ul>
        </>
    ),
    sendInvitation: "Envoyer l'invitation",
    noContributor: "Aucun contributeur",
    contributorsActiveCount: {
        0: "Aucun contributeur",
        1: "1 contributeur actif",
        plural: count => `${count} contributeurs actifs`
    },
    contributorsRefusedCount: {
        0: "Aucun contributeur refusé",
        1: "1 contributeur refusé",
        plural: count => `${count} contributeurs refusés`
    },
    contributorsCanceledCount: {
        0: "Aucun contributeur annulé",
        1: "1 contributeur annulé",
        plural: count => `${count} contributeurs annulés`
    },
    contributorsRevokedCount: {
        0: "Aucun contributeur suspendu",
        1: "1 contributeur suspendu",
        plural: count => `${count} contributeurs suspendus`
    },
    contributorsSentCount: {
        0: "Aucun contributeur invité",
        1: "1 contributeur invité",
        plural: count => `${count} contributeurs invités`
    },
    representativesActiveCount: {
        0: "Aucun mandataire",
        1: "1 mandataire actif",
        plural: count => `${count} mandataires actifs`
    },
    representativesRefusedCount: {
        0: "Aucun mandataire refusé",
        1: "1 mandataire refusé",
        plural: count => `${count} mandataires refusés`
    },
    representativesCanceledCount: {
        0: "Aucun mandataire annulé",
        1: "1 mandataire annulé",
        plural: count => `${count} mandataires annulés`
    },
    representativesRevokedCount: {
        0: "Aucun mandataire suspendu",
        1: "1 mandataire suspendu",
        plural: count => `${count} mandataires suspendus`
    },
    representativesSentCount: {
        0: "Aucun mandataire invité",
        1: "1 mandataire invité",
        plural: count => `${count} mandataires invités`
    },
    representativeName: (member: Member) => `Mandataires déposants au nom de : ${member.name}`,
    representativeInfo: (
        <p>
            Un <strong>mandataire déposant</strong>, un avocat par exemple, a mandat (la responsabilité et le droit) de déposer à l'APP en votre nom. Vos droits
            sur les dépôts restent inchangés : vous restez titulaire unique (ou partagé, dans le cadre d'un mandat partagé) de l'œuvre déposée.
        </p>
    ),
    socialRaison: "Raison sociale",
    acceptBy: "Accepté par",
    state: "Etat",
    mandatDate: "Date du mandat",
    revokedDate: "Date de révocation",
    mandate: "Mandat",
    invitationRepresentative: "Inviter un mandataire",
    representativeAddInfo: (
        <p>
            Pour inviter un mandataire à votre compte, assurez-vous qu'il est déjà membre APP. Contactez votre futur mandataire pour obtenir son email de membre
            APP. Une fois l'invitation envoyée, son administrateur la recevra et pourra l'accepter ou la refuser.
        </p>
    ),
    noRepresentative: "Aucun mandataire",
    memberEmail: "Email de membre APP",
    appNumberMemberHelpertext: "ex : 01.01.12345",
    representativesNumber: {
        0: "Aucun mandataire",
        1: "1 mandataire actif",
        plural: count => `${count} mandataires actifs`
    },
    contributeurStatus: CONTRIBUTOR_STATUS,
    toastConfirmationDisableContributor: "Le contributeur a été suspendu",
    toastConfirmationEnableContributor: "Le contributeur a été activé",
    invoiceStatus: {
        PAID: "Payée",
        UNPAID: "Impayée",
        CANCELLED: "Annulée"
    },
    number: {
        currency: (value: number): string => `${new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(value)}`
    },
    download: "Télécharger",
    noInvoice: "Aucune facture",
    workName: "Nom œuvre",
    statusDeposit: "Statut du dépôt",
    walletDepositDate: "Date Dépôt",
    iddnNumber: "Numéro Iddn du dépôt",
    iddn: "IDDN",
    shortIddn: "IDDN court",
    informations: "Infos",
    depositStoreDescription: "Descriptif complet de l'oeuvre",
    depositAccess: "Accéder à la fiche complète du dépôt",
    pay: "Payer",
    depositHoldersTitle: count => `Titulaires de droits (${count})`,
    noPhoneNumber: "Téléphone non renseigné",
    creators: "Les déposants",
    invitations: "Les invitations",
    emailFormatError: "Format de l'email invalide",
    noInvitations: "Aucune invitation reçue",
    invitationStatus: INVITATION_STATUS,
    invitationType: INVITATION_TYPE,
    invitationUserTitle: "Invitation à devenir",
    invitationTitleAPP: "Du membre APP",
    receivedAt: "Reçue le",
    invitationFrom: "De la part de",
    invitationAcceptedOrRefused: "Acceptée ou refusée le",
    receivedInvitation: "Invitations reçues",
    accepted: "Accepter",
    titleConfirmInvitation: "Êtes-vous sûr de vouloir accepter cette invitation ?",
    titleCancelInvitation: "Êtes-vous sûr de vouloir annuler cette invitation ?",
    confirm: "Confirmer",
    createInvitation: "Créer une invitation",
    invitedRole: "Rôle de l'invité",
    asMandate: "Mandant",
    thirdParties: "Tiers",
    artwork: "Œuvre",
    depositNumber: "Numéro du dépôt",
    creatorMemberRole: CREATOR_ROLE,
    depositMember: "Membre déposant",
    depositRoleMember: "Rôle du déposant",
    inviteMember: "Inviter un membre",
    subscriptionRenewalDate: "Date d'échéance de l'abonnement",
    invitationSent: "Invitation envoyée",
    memberIdentity: "Identité du membre",
    companyRegistrationNumber: "Numéro d'immatriculation",
    selectTargetUserRole: "Sélectionnez le rôle que vous souhaitez attribuer au destinataire",
    acceptRequestInvitation: "Vous êtes sur le point d'accepter l'invitation.",
    rejectRequestInvitation: "Vous êtes sur le point de refuser l'invitation.",
    shippingNote: "Bordereau d'expédition",
    yes: "Oui",
    no: "Non",
};
