import { DepositMemberStatus, DepositSection, DepositSectionData, RequestForm } from "..";
import { Card, Info, PlusIcon, Popup, SadBox, SIZE_MAP } from "../../../design-system";
import { standardFormatDate } from "../../helpers";
import { Translation, useI18n } from "../../i18n";
import { DepositMember, Member, RequestSender } from "../../model";

const indentityInfos = (depositMember: DepositMember, translation: Translation): DepositSectionData[] => {
    return [
        { title: translation.label(translation.member.memberNumber), value: depositMember.fullNumber },
        {
            title: translation.label(translation.socialRaison),
            value: depositMember.name
        },
        {
            title: translation.label(translation.companyRegistrationNumber),
            value: depositMember.siren
        }
    ];
};

const mandateInfos = (depositMember: DepositMember, translation: Translation): DepositSectionData[] => {
    return [
        {
            title: translation.label(translation.state),
            value: translation.depositMemberStatus[depositMember.request.status]
        },
        {
            title: translation.label(translation.mandatDate),
            value: standardFormatDate(depositMember.request.requestedAt)
        },
        {
            title: translation.label(translation.acceptBy),
            value: `${depositMember.owner.firstName} ${depositMember.owner.lastName} - ${depositMember.owner.email}`
        }
    ];
};

const DepositMemberCard = ({ depositMember, translation }: { depositMember: DepositMember; translation: Translation }): JSX.Element => (
    <Card
        title={<span className="font-bold" children={depositMember.name} />}
        rightContent={
            <div className="flex items-center gap-x-2.5">
                {depositMember.request.status !== DepositMember.Status.ACCEPTED && (
                    <span className="text-xs text-primary">{translation.depositMemberStatus[depositMember.request.status]}</span>
                )}
                <span className="text-light-grey">|</span>
                <DepositMemberStatus type={depositMember.request.type} status={depositMember.request.status} size="sm" />
            </div>
        }
    >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4">
            <DepositSection title={translation.memberIdentity} data={indentityInfos(depositMember, translation)} />
            <DepositSection title={translation.mandate} data={mandateInfos(depositMember, translation)} />
        </div>
    </Card>
);

export default function RequestDepositMembers({
    member,
    isFormOpen,
    closePopupForm,
    depositMembers,
    depositMembersInvitations
}: {
    member: Member;
    isFormOpen: boolean;
    closePopupForm: () => void;
    depositMembers?: DepositMember[];
    depositMembersInvitations?: RequestSender[];
}): JSX.Element {
    const { translation } = useI18n<Translation>();

    if (!depositMembers?.length && !depositMembersInvitations?.length) {
        return (
            <div className="pt-8 gap-y-2.5 flex flex-col justify-center items-center">
                <SadBox className={SIZE_MAP.xxl} />
                <span className="text-light-grey">{translation.noResult}</span>
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col gap-2.5">
                <Info type="neutral">{translation.representativeInfo}</Info>
                <ul className="flex flex-col gap-y-2.5">
                    {depositMembers?.map(depositMember => (
                        <li key={depositMember.id}>
                            <DepositMemberCard depositMember={depositMember} translation={translation} />
                        </li>
                    ))}

                    {depositMembersInvitations?.map(invitation => (
                        <li key={invitation.id}>
                            <Card
                                title={<span className="font-bold" children={invitation.recipientEmail} />}
                                rightContent={
                                    <div className="flex gap-x-2.5">
                                        <span className="text-xs text-primary">{translation.invitationStatus[invitation.status]}</span>
                                        <span className="text-light-grey">|</span>
                                        <span className="px-1.5">
                                            <DepositMemberStatus status={invitation.status} type={invitation.type} size="sm" />
                                        </span>
                                    </div>
                                }
                            />
                        </li>
                    ))}
                </ul>
            </div>
            <Popup title={translation.inviteMember} icon={<PlusIcon className="text-green-light w-5 h-5" />} isOpen={isFormOpen} size="md">
                <RequestForm member={member} cancelSubmit={closePopupForm} />
            </Popup>
        </>
    );
}
