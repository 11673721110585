import classNames from "classnames";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { InfoTooltip } from "..";

type ButtonProps = {
    label?: string;
    to?: string;
    disabled?: boolean;
    shouldOpenInNewTab?: boolean;
};

const ButtonLink = ({ label, to, disabled = false, shouldOpenInNewTab = false }: ButtonProps) => {
    if (!label) {
        return null;
    }

    const target = shouldOpenInNewTab ? "_blank" : "_self";
    const href = to ?? "#";

    return (
        <Link to={href} target={target} rel="noopener noreferrer" aria-disabled={disabled}>
            <span className="border flex items-center justify-center rounded-none font-normal gap-2 px-6 py-2 bg-white text-primary border-primary hover:bg-primary hover:text-white">
                {label}
            </span>
        </Link>
    );
};

export default function GridCell({
    children,
    title,
    customClass,
    subtitle,
    icon,
    tooltipMessage,
    primaryButtonProps,
    secondaryButtonProps,
    alignTitle = "left",
    alignButtons = "right"
}: {
    children: ReactNode;
    title: string;
    customClass?: string;
    subtitle?: string;
    icon?: ReactNode;
    tooltipMessage?: string | ReactNode;
    primaryButtonProps?: ButtonProps;
    secondaryButtonProps?: ButtonProps;
    alignTitle?: "left" | "center";
    alignButtons?: "center" | "right";
}): JSX.Element {
    return (
        <div className={classNames("rounded-md bg-white p-7 relative", customClass)}>
            {tooltipMessage && (
                <InfoTooltip className="top-1 right-1" direction="bottom" width="w-60">
                    {tooltipMessage}
                </InfoTooltip>
            )}
            <div className="flex flex-col justify-between space-y-4">
                <div
                    className={classNames("flex items-center mb-2", {
                        "flex-col": alignTitle === "center"
                    })}
                >
                    {icon && <div className="inline-block p-2 mr-2 rounded-full bg-primary-light">{icon}</div>}
                    <div
                        className={classNames({
                            "text-left": alignTitle === "left",
                            "text-center": alignTitle === "center"
                        })}
                    >
                        {title && <h3 className="text-base font-bold text-primary">{title}</h3>}
                        {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
                    </div>
                </div>
                {children}
                <div
                    className={classNames("gap-2", {
                        "flex flex-col justify-center items-center": alignButtons === "center",
                        "flex justify-end": alignButtons === "right"
                    })}
                >
                    {primaryButtonProps && (
                        <ButtonLink
                            to={primaryButtonProps.to}
                            shouldOpenInNewTab={primaryButtonProps.shouldOpenInNewTab}
                            label={primaryButtonProps.label}
                            disabled={primaryButtonProps.disabled}
                        />
                    )}
                    {secondaryButtonProps && (
                        <ButtonLink
                            to={secondaryButtonProps.to}
                            shouldOpenInNewTab={secondaryButtonProps.shouldOpenInNewTab}
                            label={secondaryButtonProps.label}
                            disabled={secondaryButtonProps.disabled}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
