import { Button, DepositMember, Loader, Popup, Request, ServiceContext, useApi, useI18n, useToast } from "@vaultinum/app-sdk";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyWhiteLogo } from "../../components";
import { Translation } from "../../i18n";
import { toDepositCreatorsPage } from "../../services";
import bgimage from "../../styles/images/appBg.png";

const RequestDetails = ({ request }: { request: Request }): ReactNode => {
    const { translation } = useI18n<Translation>();

    if (request.type === DepositMember.Type.MANDATE) {
        return (
            <div className="py-9 text-base flex flex-col gap-3">
                <p>{translation.requestType.MANDATE(request)}</p>
            </div>
        );
    }

    if (request.type === DepositMember.Type.REPRESENTATIVE) {
        return (
            <div className="py-9 text-base flex flex-col gap-3">
                <p>{translation.requestType.REPRESENTATIVE(request)}</p>
            </div>
        );
    }

    return null;
};

const RequestAction = ({
    request,
    requestHandler,
    buttonLabel,
    buttonType,
    confirmationTitle,
    confirmationDescription,
    successMessage
}: {
    request: Request;
    requestHandler: (request: Request) => (() => Promise<void>) | undefined;
    buttonLabel: string;
    buttonType: "default" | "danger";
    confirmationTitle: string;
    confirmationDescription: string;
    successMessage: string;
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { translation } = useI18n<Translation>();
    const navigate = useNavigate();
    const toast = useToast();

    const requestHandlerFn = requestHandler(request);

    const handleSubmit = async () => {
        if (requestHandlerFn) {
            await requestHandlerFn();

            toast.success(successMessage);

            navigate(toDepositCreatorsPage());
        }
    };

    return (
        <>
            <Button label={buttonLabel} onClick={() => setIsOpen(true)} variant={buttonType} className="w-32" disabled={!requestHandlerFn} />
            <Popup title={confirmationTitle} isOpen={isOpen} size="md">
                <div className="flex flex-col gap-y-7">
                    <p>{confirmationDescription}</p>
                    <div className="flex justify-end gap-x-3">
                        <Button label={translation.cancel} onClick={() => setIsOpen(false)} variant="danger" />
                        <Button label={translation.confirm} onClick={handleSubmit} color="primary" />
                    </div>
                </div>
            </Popup>
        </>
    );
};

export default function RequestInvitationPage(): JSX.Element {
    const { translation } = useI18n<Translation>();
    const params = useParams();
    const [request, setRequest] = useState<Request>();
    const { fetchApi, isLoading } = useApi();
    const { memberService } = useContext(ServiceContext);
    const [isInvitationTreated, setIsInvitationTreated] = useState<boolean>(false);

    const loadRequest = async (id: string) => {
        await fetchApi(() => memberService.getRequestById(id), setRequest);
    };

    useEffect(() => {
        if (request) {
            const isLinksAvailables = memberService.acceptRequest(request) ?? memberService.rejectRequest(request);
            if (!isLinksAvailables) {
                setIsInvitationTreated(true);
            }
        }
    }, [request]);

    useEffect(() => {
        if (params.requestId) {
            void loadRequest(params.requestId);
        }
    }, [params.requestId]);

    if (!request || isLoading) {
        return <Loader />;
    }

    return (
        <div className="w-screen h-screen flex flex-col justify-center items-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${bgimage})` }}>
            <div className="flex flex-col items-center gap-y-14 max-w-lg">
                <div className="w-96">
                    <CompanyWhiteLogo />
                </div>
                <div className="p-7 bg-white rounded">
                    <h2 className="text-primary font-bold">{isInvitationTreated ? translation.invitationAlreadyProcessed : translation.pendingInvitation}</h2>
                    <div className="flex gap-x-2">
                        <hr className="w-7 h-1 bg-primary" />
                        <p className="uppercase text-danger-high font-bold text-xs -mt-1">{translation.spaceName}</p>
                    </div>
                    <RequestDetails request={request} />
                    <div className="flex flex-col items-center gap-y-2.5">
                        <RequestAction
                            request={request}
                            requestHandler={req => memberService.acceptRequest(req)}
                            buttonLabel={translation.accepted}
                            buttonType="default"
                            confirmationTitle={translation.accepted}
                            confirmationDescription={translation.acceptRequestInvitation}
                            successMessage={translation.alertInvitationAccepted}
                        />
                        <RequestAction
                            request={request}
                            requestHandler={req => memberService.rejectRequest(req)}
                            buttonLabel={translation.reject}
                            buttonType="danger"
                            confirmationTitle={translation.reject}
                            confirmationDescription={translation.rejectRequestInvitation}
                            successMessage={translation.alertInvitationRejected}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
