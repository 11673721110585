import {
    ApisContext,
    BoxContainer,
    Credits,
    Deposit,
    finalizePhysicalDeposit,
    FormFieldsRadio,
    getDepositById,
    getOwnCredits,
    Info,
    initializeUpload,
    InterdepositContext,
    Loader,
    PaymentMethod,
    RadioFields,
    ShoppingCartIcon,
    TickIcon,
    useApi,
    useI18n
} from "@vaultinum/app-sdk";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useInterval } from "usehooks-ts";

import { OrderContext, ShoppingCart } from "../../contexts";
import { Translation } from "../../i18n";
import { CheckoutForm } from "../Checkout";
import { OrderSummary } from "../Order";

export default function StepPayment({
    setIsLoading,
    uploadFilesAndRequestSeal
}: {
    setIsLoading?: (isLoading: boolean) => void;
    uploadFilesAndRequestSeal?: () => Promise<void>;
}): JSX.Element {
    const { translate, translation } = useI18n<Translation>();
    const { fetchApi } = useApi();
    const { control, register, setValue } = useFormContext();
    const { creditsCatalog, orderCreditsWithCreditCard } = useContext(OrderContext);
    const { deposit } = useContext(InterdepositContext);

    const apis = useContext(ApisContext);

    const [credits, setCredits] = useState<Credits>();
    const [choices, setChoices] = useState<RadioFields[]>([]);
    const [shoppingCart, setShoppingCart] = useState<ShoppingCart>();
    const [isPaying, setIsPaying] = useState<boolean>(false);
    const [canUpload, setCanUpload] = useState<boolean>(false);
    const [canFinalizePhysicalDeposit, setCanFinalizePhysicalDeposit] = useState<boolean>(false);
    const [paymentMethod, acceptPaymentMethod]: [string, boolean] = useWatch({ name: ["paymentMethod", "acceptPaymentMethod"] });

    const isLoading = useMemo(() => isPaying && (!canUpload || !canFinalizePhysicalDeposit), [isPaying, canUpload, canFinalizePhysicalDeposit]);

    const DEFAULT_CHOICES: RadioFields[] = [
        {
            title: translation.creditCard,
            value: "creditCard",
            disabled: false
        }
    ];

    const fetchCredits = async () => {
        const getCreditFn = getOwnCredits(apis.deposit);

        if (!getCreditFn) {
            return;
        }

        await fetchApi(getCreditFn, setCredits);
    };

    useEffect(() => {
        setValue("paymentMethod", undefined);

        void fetchCredits();
    }, []);

    useEffect(() => {
        if (credits) {
            if (credits.available) {
                setChoices([
                    {
                        title: translate(translation.useMyOwnCredits, credits.available),
                        value: "credits",
                        disabled: false
                    },
                    ...DEFAULT_CHOICES
                ]);
            } else {
                setChoices(DEFAULT_CHOICES);
            }
        }
    }, [credits]);

    useEffect(() => {
        setValue("paymentMethod", choices[0]?.value);
    }, [choices]);

    useEffect(() => {
        if (creditsCatalog) {
            setShoppingCart({
                paymentMethod: PaymentMethod.CREDIT_CARD,
                items: {
                    [Object.values(creditsCatalog)[0].reference]: 1
                }
            });
        }
    }, [creditsCatalog]);

    useEffect(() => {
        if (canUpload) {
            void uploadFilesAndRequestSeal?.();

            setIsPaying(false);
            setIsLoading?.(false);
        }
    }, [canUpload]);

    useInterval(
        async () => {
            if (deposit) {
                const refreshedDeposit: Deposit = await getDepositById(deposit._links.self.href);

                setCanUpload(!!initializeUpload(refreshedDeposit));
                setCanFinalizePhysicalDeposit(!!finalizePhysicalDeposit(refreshedDeposit));
            }
        },
        isPaying ? 1000 : null
    );

    if (!credits) {
        return <Loader />;
    }

    return (
        <div className="flex flex-col w-full gap-5">
            <BoxContainer title={translation.paymentMethod} icon={<TickIcon className="fill-green-light" />}>
                <FormFieldsRadio radioGroupName="paymentMethod" control={control} options={choices} />
            </BoxContainer>
            <Info type="warning">
                <div className="flex flex-col gap-4">
                    <p>
                        {paymentMethod === "creditCard" && translation.paymentWithCreditCardWarning}
                        {paymentMethod === "credits" && translation.paymentWithCreditsWarning}
                    </p>
                    <p>{translation.confirmationPageRediction}</p>
                </div>
            </Info>
            <div className="flex items-start">
                <input type="checkbox" {...register("acceptPaymentMethod")} id="acceptPaymentMethod" name="acceptPaymentMethod" className="mt-1" />
                <label className="pl-2.5 text-sm text-label" htmlFor="acceptPaymentMethod">
                    {paymentMethod === "creditCard" && translation.acceptPaymentWithCreditCard}
                    {paymentMethod === "credits" && translation.acceptPaymentWithCredits}
                </label>
            </div>
            {!!shoppingCart && !!orderCreditsWithCreditCard && paymentMethod === "creditCard" && (
                <BoxContainer title={translation.order} icon={<ShoppingCartIcon className="fill-primary" />}>
                    <div className="flex gap-4">
                        <div className="w-1/2">
                            <OrderSummary shoppingCart={shoppingCart} />
                        </div>
                        <div className="w-1/2 relative">
                            <CheckoutForm
                                buttonLabel={translation.validatePaymentAndUpload}
                                shoppingCart={shoppingCart}
                                orderCreditsWithCreditCard={orderCreditsWithCreditCard}
                                isEnabled={acceptPaymentMethod && !isPaying}
                                isLoading={isLoading}
                                onSuccess={() => {
                                    setIsPaying(true);
                                    setIsLoading?.(true);
                                }}
                            />
                        </div>
                    </div>
                </BoxContainer>
            )}
        </div>
    );
}
