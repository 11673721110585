import { Button, useI18n } from "@vaultinum/app-sdk";
import { useNavigate } from "react-router-dom";
import { SucessLayout } from "../../components";
import { Translation } from "../../i18n";
import { LOGIN_PAGE } from "../../services";

export default function ConfirmationResetPasswordPage(): JSX.Element {
    const { translation } = useI18n<Translation>();
    const navigate = useNavigate();
    return (
        <SucessLayout title={translation.updatePassword} subtitle={translation.spaceName}>
            <div className="py-9 text-base flex flex-col gap-3">
                <p className="font-bold">{translation.passwordSucciessfullyUpdated}</p>
                <br />
                <p>{translation.youCanLogin}</p>
            </div>
            <div className="flex justify-center pt-3">
                <Button onClick={() => navigate(LOGIN_PAGE)} label={translation.loginMemberSite} />
            </div>
        </SucessLayout>
    );
}
