import { ReactNode } from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";
import { Stepper } from "..";

export type MultiStep = {
    title: string;
    subtitle?: string;
};

export function FormMultiSteps({
    wizardForm,
    steps,
    currentStep,
    methods,
    submit
}: {
    wizardForm: { [key: number]: ReactNode };
    steps: MultiStep[];
    currentStep: number;
    methods: UseFormReturn;
    submit: (data: FieldValues) => Promise<void>;
}): JSX.Element {
    const { handleSubmit } = methods;

    return (
        <>
            <Stepper currentStep={currentStep} stepItems={steps} className="justify-center w-full" />
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submit)} className="w-full">
                    {wizardForm[currentStep]}
                </form>
            </FormProvider>
        </>
    );
}
