import {
    addHolder,
    Deposit,
    DepositMember,
    DepositStore,
    getDepositStoreByUrl,
    getMemberHolders,
    getMemberMandates,
    InterdepositContext,
    Loader,
    Member,
    useApi,
    useI18n
} from "@vaultinum/app-sdk";
import { ReactNode, useContext, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { StepDepositRights, StepEnd, StepInformation, StepPayment, StepRecap, StepTransmission } from "../components/DepositSteps";
import { INPUT_FILES_NAME } from "../components/DropZone/DropZone";
import { Translation } from "../i18n";
import { DepositStep } from "../model";
import { DepositRights } from "../schemas/DepositFormNumericSchema";

const SCHEMA_DEPOSIT_RIGHTS = ["depositRights"];
export const SCHEMA_DEPOSIT_INFORMATIONS = ["contractType", "subscriptionTypeCode", "name", "productClass", "workType", "countryCode"];
const SCHEMA_DEPOSIT_INFORMATIONS_RECAP = ["agreements"];

export interface Step {
    component: JSX.Element;
    forwardButton?: string;
    isDisabled?: boolean;
    title?: string;
    subtitle?: ReactNode;
    backButton?: string;
    validateFields?: string[];
}

export default function useStepController(
    methods: UseFormReturn,
    activeStep: DepositStep,
    memberInfos?: Member,
    setIsLoading?: (isLoading: boolean) => void,
    uploadFilesAndRequestSeal?: () => Promise<void>
): Step {
    const { translation } = useI18n<Translation>();
    const { fetchApi } = useApi();
    const { deposit } = useContext(InterdepositContext);
    const [memberHolders, setMemberHolders] = useState<DepositMember[]>();
    const [depositStore, setDepositStore] = useState<DepositStore>();
    const [representatives, setRepresentatives] = useState<DepositMember[]>();

    const { watch, getValues } = methods;

    const loadHolders = async () => {
        if (memberInfos) {
            const responseFn = getMemberHolders(memberInfos);
            if (responseFn) {
                const holdersResponse = await fetchApi(responseFn);
                setMemberHolders(holdersResponse);
            }
        }
    };

    const loadMandates = async () => {
        if (memberInfos) {
            const fetchMandatesFn = getMemberMandates(memberInfos);
            if (fetchMandatesFn) {
                await fetchApi(fetchMandatesFn, setRepresentatives);
            }
        }
    };

    const loadDepositStore = async (depositInfo: Deposit) => {
        if (depositInfo) {
            const depositStoreResponse = getDepositStoreByUrl(depositInfo._links.depositStore?.href);

            await fetchApi(
                () => depositStoreResponse,
                response => setDepositStore(response)
            );
        }
    };

    useEffect(() => {
        if (activeStep === DepositStep.END && deposit) {
            void loadDepositStore(deposit);
        }
    }, [activeStep, deposit]);

    useEffect(() => {
        void loadHolders();
        void loadMandates();
    }, [memberInfos]);

    const isDisabledRightsStep = (): boolean => {
        if (deposit) {
            if (!addHolder(deposit)) {
                return false;
            } else {
                if (watch("depositRights") === DepositRights.ONE_TITULARITY) {
                    return false;
                }
                if (watch("depositRights") === DepositRights.SHARED_TITULARITY && watch("holders").length) {
                    return false;
                }

                if (watch("depositRights") === DepositRights.REPRESENTATIVE && watch("holders").length) {
                    return false;
                }

                return true;
            }
        }
        return true;
    };

    const stepDepositInformation = {
        component: <StepInformation />,
        forwardButton: translation.nextStep,
        backButton: translation.goBackAndCancel,
        title: translation.depositInformationNumeric,
        subtitle: translation.clickNextStep,
        validateFields: SCHEMA_DEPOSIT_INFORMATIONS,
        isDisabled: false
    };

    const stepDepositRights = {
        component: !memberInfos ? <Loader /> : <StepDepositRights member={memberInfos} memberHolders={memberHolders} representatives={representatives} />,
        forwardButton: translation.nextStep,
        backButton: translation.goBack,
        title: translation.titularityDeposit,
        subtitle: deposit && addHolder(deposit) && memberInfos && (
            <div className="text-center">
                {translation.subTitleTitularity(memberInfos.name)}
                <br />
                {translation.clickNextStep}
            </div>
        ),
        validateFields: SCHEMA_DEPOSIT_RIGHTS,
        isDisabled: isDisabledRightsStep()
    };

    const stepTransmission = {
        component: <StepTransmission />,
        forwardButton: translation.nextStep,
        backButton: translation.goBack,
        title: translation.transmissionName(getValues("name")),
        subtitle: translation.clickNextStep,
        isDisabled: watch("type") === Deposit.Type.DIGITAL && watch(INPUT_FILES_NAME).length === 0
    };

    const stepRecap = {
        component: <StepRecap />,
        forwardButton: translation.validateAndGoToPayment,
        backButton: translation.goBack,
        title: translation.summary,
        subtitle: translation.stepRecapSubtitle
    };

    const stepPayment = {
        component: <StepPayment setIsLoading={setIsLoading} uploadFilesAndRequestSeal={uploadFilesAndRequestSeal} />,
        forwardButton: translation.appButton,
        backButton: translation.goBack,
        title: translation.paymentMethod,
        subtitle: translation.stepRecapSubtitle,
        validateFields: SCHEMA_DEPOSIT_INFORMATIONS_RECAP,
        isDisabled: !watch("paymentMethod") || !watch("acceptPaymentMethod")
    };

    const stepEnd = {
        component:
            depositStore && memberInfos ? (
                <StepEnd
                    title={translation.transmissionSucessTitle}
                    subtitle={translation.transmissionSucessDescription(deposit?.name ?? "")}
                    memberInfos={memberInfos}
                    depositStore={depositStore}
                />
            ) : (
                <Loader />
            )
    };

    const NumericStep: { [key: number]: Step } = {
        [DepositStep.INFORMATION]: stepDepositInformation,
        [DepositStep.RIGHTS]: stepDepositRights,
        [DepositStep.TRANSMISSION]: stepTransmission,
        [DepositStep.INFORMATION_RECAP]: stepRecap,
        [DepositStep.PAYMENT]: stepPayment,
        [DepositStep.END]: stepEnd
    };

    return NumericStep[activeStep];
}
