import * as yup from "yup";

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const SIREN_REGEX = {
    FRANCE: /^\d{9}$/
};

const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^_&*-]).{8,}$/;

declare module "yup" {
    interface StringSchema {
        isEmailFormatValid(message: string): this;
        isFrenchSirenFormatValid(message: string): this;
        isPasswordFormatValid(message: string): this;
    }
}

yup.addMethod<yup.StringSchema>(yup.string, "isEmailFormatValid", function (message) {
    return this.test("isEmailFormatValid", message, function (value) {
        const { path, createError } = this;
        return value && EMAIL_REGEX.test(value) ? true : createError({ path, message });
    });
});

yup.addMethod<yup.StringSchema>(yup.string, "isFrenchSirenFormatValid", function (message) {
    return this.test("isSirenFormatValid", message, function (value) {
        const { path, createError } = this;
        return value && SIREN_REGEX.FRANCE.test(value) ? true : createError({ path, message });
    });
});

yup.addMethod<yup.StringSchema>(yup.string, "isPasswordFormatValid", function (message) {
    return this.test("isPasswordFormatValid", message, function (value) {
        const { path, createError } = this;
        return value && PASSWORD_REGEX.test(value) ? true : createError({ path, message });
    });
});

export default yup;
