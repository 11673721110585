import classNames from "classnames";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";

import { InputFields } from "../../../../design-system";
import { Input } from "../../../../design-system/components";

export default function FormFieldsInput({
    fields,
    control,
    errors,
    readOnly,
    className
}: {
    fields: InputFields[];
    control: Control<FieldValues>;
    errors: FieldErrors;
    readOnly?: boolean;
    className?: string;
}) {
    return (
        <ul className={classNames("gap-5", className)}>
            {fields.map(
                ({ name, label, className: clsName, helperText, type, rows, labelPosition, limit, disabled, placeholder, options, autofocus, uppercase }) => {
                    return (
                        <li key={name}>
                            <Controller
                                name={name}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        label={label}
                                        className={clsName}
                                        labelPosition={labelPosition}
                                        helperText={errors[name]?.message ? String(errors[name]?.message) : helperText}
                                        limit={limit}
                                        fieldName={name}
                                        type={type}
                                        placeholder={placeholder}
                                        error={!!errors[name]}
                                        rows={rows}
                                        options={options}
                                        onChange={onChange}
                                        value={value}
                                        disabled={disabled || readOnly}
                                        autofocus={autofocus}
                                        uppercase={uppercase}
                                        checked={value}
                                    />
                                )}
                            />
                        </li>
                    );
                }
            )}
        </ul>
    );
}
