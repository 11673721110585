import {
    ApisContextProvider,
    BreadCrumbContextProvider,
    getEnvironment,
    I18n,
    InterdepositContextProvider,
    Loader,
    RestApiServiceContextProvider,
    ScrollToTop,
    Toast,
    useI18n,
    UserContext,
    UserContextProvider
} from "@vaultinum/app-sdk";
import { useContext, useEffect, useMemo } from "react";
import { BrowserRouter, matchPath, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { PrivatePageLayout } from "./components";
import { MemberContextProvider, OrderContextProvider } from "./contexts";
import { fr, Translation } from "./i18n";
import {
    CHANGE_PASSWORD_PAGE,
    CONFIRMATION_REQUEST_PASSWORD_PAGE,
    CONFIRMATION_RESET_PASSWORD_PAGE,
    CREATE_DEPOSIT_PAGE,
    CREDIT_BUY_PAGE,
    CREDIT_BUY_PAYMENT_PAGE,
    CREDIT_BUY_SUCCESS_PAGE,
    CREDIT_BUY_SUMMARY_PAGE,
    CREDIT_PAGE,
    DEPOSIT_CREATORS_PAGE,
    DEPOSIT_PAGE,
    DEPOSIT_STORE_CREATE_DEPOSIT_PAGE,
    DEPOSIT_STORE_PAGE,
    DEPOSIT_STORE_RESUME_DEPOSIT_PAGE,
    DEPOSIT_STORE_UPDATE_DEPOSIT_PAGE,
    HOME_PAGE,
    LOGIN_PAGE,
    MEMBER_INFO_PAGE,
    PAYMENT_AND_INVOICES_PAGE,
    PRIVATE_HELP_PAGE,
    REQUEST_INVITATION_PAGE,
    RESET_PASSWORD_PAGE,
    TrackingEvent,
    trackingService,
    UPDATE_DEPOSIT_PAGE,
    USER_INFO_PAGE,
    WALLET_PAGE
} from "./services";
import {
    CreateDepositPage,
    CreditPage,
    DepositCreatorsPage,
    DepositPage,
    DepositStoreCreateDepositPage,
    DepositStorePage,
    DepositStoreResumeDepositPage,
    DepositStoreUpdateDepositPage,
    HelpPage,
    HomePage,
    InvoicesAndPaymentsPage,
    MemberInfoPage,
    RequestInvitationPage,
    UpdateDepositPage,
    UserInfoPage,
    WalletPage
} from "./views/private";
import { CreditsBuyPaymentPage, CreditsBuySelectionPage, CreditsBuySuccessPage, CreditsBuySummaryPage } from "./views/private/CreditsPage";
import { ConfirmationRequestPasswordPage, ConfirmationResetPasswordPage, LoginPage, PasswordPage, ResetPasswordPage } from "./views/public";

function PrivateRoutes(): JSX.Element {
    const { translation } = useI18n<Translation>();
    const location = useLocation();

    useEffect(() => {
        trackingService.track(TrackingEvent.NAVIGATION, { path: `${location.pathname}${location.search}` });
    }, [location]);

    document.title = translation.applicationTitles[getEnvironment()];

    return (
        <Routes>
            <Route
                element={
                    <RestApiServiceContextProvider>
                        <Outlet />
                    </RestApiServiceContextProvider>
                }
            >
                <Route
                    element={
                        <ApisContextProvider>
                            <MemberContextProvider>
                                <BreadCrumbContextProvider>
                                    <ScrollToTop />
                                    <InterdepositContextProvider>
                                        <PrivatePageLayout>
                                            <Outlet />
                                        </PrivatePageLayout>
                                    </InterdepositContextProvider>
                                </BreadCrumbContextProvider>
                            </MemberContextProvider>
                        </ApisContextProvider>
                    }
                >
                    <Route path={HOME_PAGE} element={<HomePage />} />
                    <Route
                        element={
                            <OrderContextProvider>
                                <Outlet />
                            </OrderContextProvider>
                        }
                    >
                        <Route path={CREDIT_PAGE} element={<CreditPage />} />
                        <Route path={CREDIT_BUY_PAGE} element={<CreditsBuySelectionPage />} />
                        <Route path={CREDIT_BUY_PAYMENT_PAGE} element={<CreditsBuyPaymentPage />} />
                        <Route path={CREDIT_BUY_SUMMARY_PAGE} element={<CreditsBuySummaryPage />} />
                        <Route path={CREDIT_BUY_SUCCESS_PAGE} element={<CreditsBuySuccessPage />} />
                        <Route path={PAYMENT_AND_INVOICES_PAGE} element={<InvoicesAndPaymentsPage />} />
                        <Route path={DEPOSIT_PAGE} element={<DepositPage />} />
                        <Route path={UPDATE_DEPOSIT_PAGE} element={<UpdateDepositPage />} />
                        <Route path={CREATE_DEPOSIT_PAGE} element={<CreateDepositPage />} />
                        <Route path={DEPOSIT_STORE_CREATE_DEPOSIT_PAGE} element={<DepositStoreCreateDepositPage />} />
                        <Route path={DEPOSIT_STORE_UPDATE_DEPOSIT_PAGE} element={<DepositStoreUpdateDepositPage />} />
                        <Route path={DEPOSIT_STORE_RESUME_DEPOSIT_PAGE} element={<DepositStoreResumeDepositPage />} />
                    </Route>
                    <Route path={PRIVATE_HELP_PAGE} element={<HelpPage />} />
                    <Route path={USER_INFO_PAGE} element={<UserInfoPage />} />
                    <Route path={MEMBER_INFO_PAGE} element={<MemberInfoPage />} />
                    <Route path={WALLET_PAGE} element={<WalletPage />} />
                    <Route path={DEPOSIT_STORE_PAGE} element={<DepositStorePage />} />
                    <Route path={DEPOSIT_CREATORS_PAGE} element={<DepositCreatorsPage />} />
                    <Route path="*" element={<Navigate to={HOME_PAGE} />} />
                </Route>
                <Route path={REQUEST_INVITATION_PAGE} element={<RequestInvitationPage />} />
            </Route>
        </Routes>
    );
}

function PublicRoutes(): JSX.Element {
    const { translation } = useI18n<Translation>();
    document.title = translation.applicationTitles[getEnvironment()];

    return (
        <Routes>
            <Route path={LOGIN_PAGE} element={<LoginPage />} />
            <Route path={RESET_PASSWORD_PAGE} element={<PasswordPage />} />
            <Route path={CONFIRMATION_RESET_PASSWORD_PAGE} element={<ConfirmationResetPasswordPage />} />
            <Route path={CONFIRMATION_REQUEST_PASSWORD_PAGE} element={<ConfirmationRequestPasswordPage />} />
            <Route path={CHANGE_PASSWORD_PAGE} element={<ResetPasswordPage />} />
            <Route path="*" element={<Navigate to={LOGIN_PAGE} />} />
        </Routes>
    );
}

const UNPROTECTED_PAGES = [LOGIN_PAGE, RESET_PASSWORD_PAGE, CONFIRMATION_RESET_PASSWORD_PAGE, CONFIRMATION_REQUEST_PASSWORD_PAGE, CHANGE_PASSWORD_PAGE + "/*"];

function isUnprotectedPage(pathname: string): boolean {
    return UNPROTECTED_PAGES.some(pattern => matchPath(pattern, pathname));
}

function HandleRoutes(): JSX.Element {
    const { user, isLoading } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();

    const redirectUrl = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return params.get("redirectUrl");
    }, [location.search]);

    useEffect(() => {
        if (!isLoading && !user && !isUnprotectedPage(location.pathname)) {
            const currentUrl = `${location.pathname}${location.search}`;
            window.location.replace(`${LOGIN_PAGE}?redirectUrl=${currentUrl}`);
        }
    }, [user, isLoading, location.pathname, location.search]);

    if (isLoading) {
        return <Loader />;
    }

    if (user) {
        if (redirectUrl) {
            navigate(redirectUrl);
        }
        return <PrivateRoutes />;
    }

    return <PublicRoutes />;
}

function AppRouter(): JSX.Element {
    return (
        <BrowserRouter>
            <UserContextProvider>
                <RestApiServiceContextProvider>
                    <I18n locale="fr" locales={{ fr }}>
                        <Toast />
                        <HandleRoutes />
                    </I18n>
                </RestApiServiceContextProvider>
            </UserContextProvider>
        </BrowserRouter>
    );
}
export default AppRouter;
