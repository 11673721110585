import { AddDepositLinkDescription, ApisContext, Box, Button, CardBoxContent, CustomLink, Info, Layout, LetterOpen, useI18n } from "@vaultinum/app-sdk";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Translation } from "../../../i18n";
import { CREATE_DEPOSIT_PAGE, HELP_PAGE, WALLET_PAGE } from "../../../services";

export default function DepositPage() {
    const { translation } = useI18n<Translation>();
    const navigate = useNavigate();
    const apis = useContext(ApisContext);
    const canCreate = apis?.deposit._links.create?.available;

    const handleNavigate = (to: string) => {
        navigate(to);
    };

    return (
        <Layout
            title={translation.depositAction}
            icon={<LetterOpen className="w-6 h-8 fill-primary" />}
            children={
                <div className="flex flex-col items-center pt-8 gap-y-10">
                    <h2 className="font-medium text-primary">{translation.whichDeposit}</h2>
                    <div className="flex gap-2">
                        <div className="h-auto w-full">
                            <Box isBordered>
                                <CardBoxContent
                                    title={translation.initialDeposit}
                                    bottomRightContent={
                                        <Button label={translation.drop} onClick={() => handleNavigate(CREATE_DEPOSIT_PAGE)} disabled={!canCreate} />
                                    }
                                >
                                    <div>
                                        {translation.automatiqueCreationDepositStore}
                                        {apis.deposit._links.create?.description === AddDepositLinkDescription.NO_CREDIT && !canCreate && (
                                            <Info type="warning" className="mt-4">
                                                {translation.noCreditAvailable}
                                            </Info>
                                        )}
                                    </div>
                                </CardBoxContent>
                            </Box>
                        </div>
                        <div className="h-auto w-full">
                            <Box isBordered>
                                <CardBoxContent
                                    title={translation.updateDepositStore}
                                    bottomRightContent={<Button label={translation.goToWallet} onClick={() => handleNavigate(WALLET_PAGE)} />}
                                >
                                    <>
                                        <p>{translation.updateDepositStoreHowTo}</p>
                                        <br />
                                        <p>{translation.howToUpdateDepositStore.title}</p>
                                        <ol className="list-decimal list-inside">
                                            <li>{translation.howToUpdateDepositStore.step1}</li>
                                            <li>{translation.howToUpdateDepositStore.step2}</li>
                                            <li>{translation.howToUpdateDepositStore.step3}</li>
                                        </ol>
                                    </>
                                </CardBoxContent>
                            </Box>
                        </div>
                    </div>
                    <CustomLink label={translation.learnMore} target="_blank" path={HELP_PAGE} />
                </div>
            }
        />
    );
}
