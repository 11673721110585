import { DepositMember, SimpleListOption } from "@vaultinum/app-sdk";
import { FieldValues } from "react-hook-form";

export function formatUserList(users: DepositMember[]): SimpleListOption[] {
    return users.map(user => {
        return {
            id: user.id,
            label: `${user.fullNumber} - ${user.name}`
        };
    });
}

export const dataForm = (data: FieldValues) => {
    return {
        name: data.name,
        subtitle: data.subtitle,
        type: data.type,
        subscriptionTypeCode: data.subscriptionTypeCode,
        workType: data.workType,
        workNature: data.workNature !== "" ? data.workNature : null,
        workVersion: data.workVersion,
        workVersionDate: data.workVersionDate,
        productClass: data.productClass,
        countryCode: data.countryCode
    };
};
