import { Country, Member as MemberModel, SealStatus } from ".";
import { Link } from "./Hateoas";

export namespace Deposit {
    export enum Type {
        DIGITAL = "DIGITAL",
        PHYSICAL = "PHYSICAL",
        UNKNOWN = "UNKNOWN"
    }

    export enum WorkType {
        FIRST_WORK = "FIRST_WORK",
        COMPOSITE_WORK = "COMPOSITE_WORK",
        DERIVATIVE_WORK = "DERIVATIVE_WORK",
        UNDEFINED = "UNDEFINED"
    }

    export enum WorkNature {
        SOURCE_CODE = "SOURCE_CODE",
        EXECUTABLE_SOFTWARE = "EXECUTABLE_SOFTWARE",
        DATABASE = "DATABASE",
        OTHER = "OTHER"
    }

    export interface Owner {
        name: string;
    }

    export enum MemberRole {
        OWNER = "OWNER",
        HOLDER = "HOLDER",
        REPRESENTATIVE = "REPRESENTATIVE"
    }

    export enum CreatorMemberRole {
        MAIN_HOLDER = "MAIN_HOLDER",
        HOLDER = "HOLDER",
        REPRESENTATIVE = "REPRESENTATIVE"
    }

    export enum CreatorUserRole {
        ADMIN = "ADMIN",
        CONTRIBUTOR = "CONTRIBUTOR",
        GIT = "GIT"
    }

    export interface Member {
        number: string;
        name: string;
        administrator: {
            firstName: string;
            lastName: string;
        };
        phone?: string;
        email?: string;
        address: {
            street?: string;
            postalCode?: string;
            city?: string;
        };
        role: Deposit.MemberRole;
        _links: {
            self: {
                href: string;
            };
        };
    }

    export interface Holder {
        longNumber: string;
        memberId: string;
        name: string;
        nature: MemberModel.Nature;
        lastDepositCreator: boolean;
        siret?: string;
        _links: {
            self: {
                href: string;
            };
        };
    }

    export interface HoldersManagement {
        _links: {
            addHolders: Link;
        };
    }

    export interface UpdatedDepositType {
        _links: {
            self: Link;
            finalizePhysicalDeposit?: Link;
        };
    }

    export enum ProductClass {
        SYSTEM_SOFTWARE = "SYSTEM_SOFTWARE",
        SYSTEM_SOFTWARE_OPERATING_SYSTEMS = "SYSTEM_SOFTWARE_OPERATING_SYSTEMS",
        SYSTEM_SOFTWARE_DATA_TRANSMISSION = "SYSTEM_SOFTWARE_DATA_TRANSMISSION",
        SYSTEM_SOFTWARE_DATABASES = "SYSTEM_SOFTWARE_DATABASES",
        SYSTEM_SOFTWARE_PROGRAMMING_LANGUAGE = "SYSTEM_SOFTWARE_PROGRAMMING_LANGUAGE",
        SYSTEM_SOFTWARE_END_USER_FRIENDLY_LANGUAGE = "SYSTEM_SOFTWARE_END_USER_FRIENDLY_LANGUAGE",
        SYSTEM_SOFTWARE_SUPPORT_FOR_DEVELOPMENT = "SYSTEM_SOFTWARE_SUPPORT_FOR_DEVELOPMENT",
        SYSTEM_SOFTWARE_SYSTEM_OPERATION_MANAGEMENT = "SYSTEM_SOFTWARE_SYSTEM_OPERATION_MANAGEMENT",
        SYSTEM_SOFTWARE_UTILITIES = "SYSTEM_SOFTWARE_UTILITIES",
        UNIVERSAL_SOFTWARE = "UNIVERSAL_SOFTWARE",
        UNIVERSAL_SOFTWARE_PLANNING_MANAGEMENT = "UNIVERSAL_SOFTWARE_PLANNING_MANAGEMENT",
        UNIVERSAL_SOFTWARE_ACCOUNTING = "UNIVERSAL_SOFTWARE_ACCOUNTING",
        UNIVERSAL_SOFTWARE_PERSONNEL_PAYROLL = "UNIVERSAL_SOFTWARE_PERSONNEL_PAYROLL",
        UNIVERSAL_SOFTWARE_SALES_INVENTORY = "UNIVERSAL_SOFTWARE_SALES_INVENTORY",
        UNIVERSAL_SOFTWARE_PRODUCTION = "UNIVERSAL_SOFTWARE_PRODUCTION",
        UNIVERSAL_SOFTWARE_DESIGN_STUDY_PROJECT = "UNIVERSAL_SOFTWARE_DESIGN_STUDY_PROJECT",
        UNIVERSAL_SOFTWARE_FORECASTING_STATISTICS_ANALYSIS = "UNIVERSAL_SOFTWARE_FORECASTING_STATISTICS_ANALYSIS",
        UNIVERSAL_SOFTWARE_OFFICE_AUTOMATION = "UNIVERSAL_SOFTWARE_OFFICE_AUTOMATION",
        UNIVERSAL_SOFTWARE_INFORMATION_ENGINEERING = "UNIVERSAL_SOFTWARE_INFORMATION_ENGINEERING",
        UNIVERSAL_SOFTWARE_IMAGE_PROCESSING = "UNIVERSAL_SOFTWARE_IMAGE_PROCESSING",
        UNIVERSAL_SOFTWARE_CAE = "UNIVERSAL_SOFTWARE_CAE",
        SPECIAL_SOFTWARE = "SPECIAL_SOFTWARE",
        SPECIAL_SOFTWARE_FARMING = "SPECIAL_SOFTWARE_FARMING",
        SPECIAL_SOFTWARE_WATER_AND_FOREST = "SPECIAL_SOFTWARE_WATER_AND_FOREST",
        SPECIAL_SOFTWARE_FISHING = "SPECIAL_SOFTWARE_FISHING",
        SPECIAL_SOFTWARE_MINING = "SPECIAL_SOFTWARE_MINING",
        SPECIAL_SOFTWARE_BUILDING = "SPECIAL_SOFTWARE_BUILDING",
        SPECIAL_SOFTWARE_PRODUCTION = "SPECIAL_SOFTWARE_PRODUCTION",
        SPECIAL_SOFTWARE_FEED = "SPECIAL_SOFTWARE_FEED",
        SPECIAL_SOFTWARE_TEXTILE_AND_CLOTHING = "SPECIAL_SOFTWARE_TEXTILE_AND_CLOTHING",
        SPECIAL_SOFTWARE_WOOD_PULP_AND_PAPER = "SPECIAL_SOFTWARE_WOOD_PULP_AND_PAPER",
        SPECIAL_SOFTWARE_PUBLICATION_AND_PRINTING = "SPECIAL_SOFTWARE_PUBLICATION_AND_PRINTING",
        SPECIAL_SOFTWARE_CHEMICALS_AND_RELATED_INDUSTRIES = "SPECIAL_SOFTWARE_CHEMICALS_AND_RELATED_INDUSTRIES",
        SPECIAL_SOFTWARE_STONE_CLAY_AND_GLASS_PRODUCTS = "SPECIAL_SOFTWARE_STONE_CLAY_AND_GLASS_PRODUCTS",
        SPECIAL_SOFTWARE_METAL_PRODUCTS = "SPECIAL_SOFTWARE_METAL_PRODUCTS",
        SPECIAL_SOFTWARE_MACHINERY_AND_EQUIPMENT = "SPECIAL_SOFTWARE_MACHINERY_AND_EQUIPMENT",
        SPECIAL_SOFTWARE_ELECTRICAL_MACHINES = "SPECIAL_SOFTWARE_ELECTRICAL_MACHINES",
        SPECIAL_SOFTWARE_TRANSPORT_EQUIPMENT = "SPECIAL_SOFTWARE_TRANSPORT_EQUIPMENT",
        SPECIAL_SOFTWARE_ELECTRICITY_GAS_HEAT_WATER_SUPPLY = "SPECIAL_SOFTWARE_ELECTRICITY_GAS_HEAT_WATER_SUPPLY",
        SPECIAL_SOFTWARE_ELECTRICITY = "SPECIAL_SOFTWARE_ELECTRICITY",
        SPECIAL_SOFTWARE_GAS = "SPECIAL_SOFTWARE_GAS",
        SPECIAL_SOFTWARE_WATER_SUPPLY = "SPECIAL_SOFTWARE_WATER_SUPPLY",
        SPECIAL_SOFTWARE_TRANSPORT_COMMUNICATION = "SPECIAL_SOFTWARE_TRANSPORT_COMMUNICATION",
        SPECIAL_SOFTWARE_TRANSPORT = "SPECIAL_SOFTWARE_TRANSPORT",
        SPECIAL_SOFTWARE_COMMUNICATION = "SPECIAL_SOFTWARE_COMMUNICATION",
        SPECIAL_SOFTWARE_SALES_RESTAURANTS_AND_DRINKING_PLACES = "SPECIAL_SOFTWARE_SALES_RESTAURANTS_AND_DRINKING_PLACES",
        SPECIAL_SOFTWARE_WHOLESALE = "SPECIAL_SOFTWARE_WHOLESALE",
        SPECIAL_SOFTWARE_RETAIL_SALES = "SPECIAL_SOFTWARE_RETAIL_SALES",
        SPECIAL_SOFTWARE_RESTAURANTS_AND_DRINKING_PLACES = "SPECIAL_SOFTWARE_RESTAURANTS_AND_DRINKING_PLACES",
        SPECIAL_SOFTWARE_FINANCIAL_AND_INSURANCE_ACTIVITIES = "SPECIAL_SOFTWARE_FINANCIAL_AND_INSURANCE_ACTIVITIES",
        SPECIAL_SOFTWARE_BANKING_AND_TRUST_ACTIVITIES = "SPECIAL_SOFTWARE_BANKING_AND_TRUST_ACTIVITIES",
        SPECIAL_SOFTWARE_FINANCIAL_BROKERAGE = "SPECIAL_SOFTWARE_FINANCIAL_BROKERAGE",
        SPECIAL_SOFTWARE_INSURANCE = "SPECIAL_SOFTWARE_INSURANCE",
        SPECIAL_SOFTWARE_REAL_ESTATE = "SPECIAL_SOFTWARE_REAL_ESTATE",
        SPECIAL_SOFTWARE_SERVICES = "SPECIAL_SOFTWARE_SERVICES",
        SPECIAL_SOFTWARE_RENTAL = "SPECIAL_SOFTWARE_RENTAL",
        SPECIAL_SOFTWARE_HOTELS_AND_HOSTELS = "SPECIAL_SOFTWARE_HOTELS_AND_HOSTELS",
        SPECIAL_SOFTWARE_BROADCASTING_AND_ADVERTISING = "SPECIAL_SOFTWARE_BROADCASTING_AND_ADVERTISING",
        SPECIAL_SOFTWARE_INFORMATION_SERVICES = "SPECIAL_SOFTWARE_INFORMATION_SERVICES",
        SPECIAL_SOFTWARE_MISCELLANEOUS_SERVICES = "SPECIAL_SOFTWARE_MISCELLANEOUS_SERVICES",
        SPECIAL_SOFTWARE_MEDICAL_HEALTH_AND_SANITARY_SERVICES = "SPECIAL_SOFTWARE_MEDICAL_HEALTH_AND_SANITARY_SERVICES",
        SPECIAL_SOFTWARE_EDUCATION_AND_SCIENTIFIC_RESEARCH = "SPECIAL_SOFTWARE_EDUCATION_AND_SCIENTIFIC_RESEARCH",
        SPECIAL_SOFTWARE_PUBLIC_SERVICES = "SPECIAL_SOFTWARE_PUBLIC_SERVICES",
        SPECIAL_SOFTWARE_LEISURE_AND_FAMILY_LIFE = "SPECIAL_SOFTWARE_LEISURE_AND_FAMILY_LIFE",
        SPECIAL_SOFTWARE_OTHER = "SPECIAL_SOFTWARE_OTHER",
        MULTIMEDIA_DATABASE = "MULTIMEDIA_DATABASE",
        MULTIMEDIA_DATABASE_REPLICATION_2D = "MULTIMEDIA_DATABASE_REPLICATION_2D",
        MULTIMEDIA_DATABASE_REPLICATION_3D = "MULTIMEDIA_DATABASE_REPLICATION_3D",
        MULTIMEDIA_DATABASE_REPLICATION_ANIMATION = "MULTIMEDIA_DATABASE_REPLICATION_ANIMATION",
        MULTIMEDIA_DATABASE_REPLICATION_SOUND = "MULTIMEDIA_DATABASE_REPLICATION_SOUND",
        DIGITAL_CREATION = "DIGITAL_CREATION",
        DIGITAL_CREATION_2D = "DIGITAL_CREATION_2D",
        DIGITAL_CREATION_3D = "DIGITAL_CREATION_3D",
        DIGITAL_CREATION_ANIMATION = "DIGITAL_CREATION_ANIMATION",
        DIGITAL_CREATION_SOUND = "DIGITAL_CREATION_SOUND",
        DIGITAL_CREATION_PICTURE = "DIGITAL_CREATION_PICTURE",
        WEBSITE = "WEBSITE",
        UNDEFINED = "UNDEFINED"
    }

    export enum SubscriptionTypeCode {
        S = "S",
        C = "C",
        D = "D",
        R = "R",
        X = "X"
    }
}

export interface Deposit {
    id: string;
    depositStoreId: string;
    iddn: string;
    subtitle: string;
    depositType: Deposit.Type;
    subscriptionTypeCode?: Deposit.SubscriptionTypeCode;
    productClass: Deposit.ProductClass;
    workType: Deposit.WorkType;
    workNature: Deposit.WorkNature;
    workVersion: string;
    workVersionDate?: string;
    countryCode: Country;
    remarks: string;
    logibox: {
        logiboxAdh?: string;
        logiboxApp?: string;
        logibox1?: string;
        logibox2?: string;
        mediaType?: string;
        note?: string;
    };
    member: {
        name: string;
        _links: {
            self: Link;
        };
    };
    creator: {
        member: {
            id: string;
            shortNumber: string;
            longNumber: string;
            name: string;
            role: Deposit.CreatorMemberRole;
            _links: {
                self: Link;
            };
        };
        user?: {
            id: string;
            firstName: string;
            lastName: string;
            role: Deposit.CreatorUserRole;
            phone?: string;
            mobile?: string;
            email?: string;
        };
    };
    shortIddnNumber: string;
    name: string;
    createdAt: string;
    sealedAt: string;
    filesCount?: number;
    filesSizeInBytes?: number;
    sealStatus: SealStatus;
    owner: Deposit.Owner;
    members?: Deposit.Member[];
    holders?: Deposit.Holder[];
    _links: {
        self: Link;
        depositStore: Link;
        update?: Link;
        upload?: Link;
        seal?: Link;
        certificate?: Link;
        resume?: Link;
        delete?: Link;
        initializeUpload: Link;
        deleteAllFiles: Link;
        addHolder?: Link;
        setSingleHolder: Link;
        setSharedHolders: Link;
        setRepresentative: Link;
        setTypeToDigital?: Link;
        setTypeToPhysical?: Link;
        shippingNote?: Link;
        finalizePhysicalDeposit?: Link;
    };
}
