import classNames from "classnames";
import { ReactNode, useEffect, useRef, useState } from "react";
import { getNodeText } from "../../helpers";
import { ChevronSmall, Tooltip } from "../index";

const SkeletonCard = ({ isSmallCard }: { isSmallCard?: boolean }) => {
    return (
        <div
            className={classNames("animate-pulse flex items-center w-full space-x-2.5 bg-white border border-gray-200 rounded shadow order", {
                "h-[34px] px-1": isSmallCard,
                "h-[50px] px-2.5": !isSmallCard
            })}
        >
            <div
                className={classNames("rounded bg-blue-50", {
                    "w-6 h-6": isSmallCard,
                    "w-8 h-8": !isSmallCard
                })}
            />
            <div className="w-full space-y-1">
                <div className="w-9/12 h-3.5 bg-gray-200" />
                {!isSmallCard && <div className="w-7/12 h-3.5 bg-gray-100" />}
            </div>
        </div>
    );
};

export default function Card({
    title,
    footer,
    subTitle,
    icon,
    children,
    topLeftContent,
    isClosable,
    open,
    tooltip,
    rightContent,
    isSmall,
    isLoading,
    headerBackground
}: {
    title: ReactNode;
    footer?: ReactNode;
    subTitle?: ReactNode;
    icon?: ReactNode;
    children?: ReactNode;
    topLeftContent?: string;
    isClosable?: boolean;
    open?: boolean;
    tooltip?: ReactNode;
    rightContent?: ReactNode;
    isSmall?: boolean;
    isLoading?: boolean;
    headerBackground?: boolean;
}) {
    const [openCard, setOpenCard] = useState<boolean>(!!open);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setOpenCard(!!open);
    }, [open]);

    useEffect(() => {
        const handleFocusIn = (e: FocusEvent) => {
            if (contentRef.current?.contains(e.target as Node)) {
                setOpenCard(true);
            }
        };

        document.addEventListener("focusin", handleFocusIn);
        return () => {
            document.removeEventListener("focusin", handleFocusIn);
        };
    }, []);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (isClosable) {
            if (contentRef.current?.contains(e.target as Node)) {
                return;
            }

            e.stopPropagation();
            setOpenCard(prev => !prev);
        }
    };

    const headerClasses = classNames(
        "flex justify-between w-full hover:bg-zinc-100 cursor-pointer",
        isSmall ? "px-1 py-1" : "px-2.5 py-1.5",
        headerBackground ? "bg-disabled" : "bg-white"
    );

    const iconContainerClasses = classNames("flex relative items-center justify-center bg-blue-100 rounded mr-2.5", isSmall ? "h-6 w-6 p-1" : "h-8 w-8 p-2");

    if (isLoading) {
        return <SkeletonCard isSmallCard={isSmall} />;
    }

    return (
        <div className="relative overflow-hidden flex flex-col p-0 w-full bg-white border border-gray-200 rounded shadow">
            {topLeftContent && <span className="absolute top-[-1px] left-0.5 text-xxs text-color-disabled mt-[-1px]">{topLeftContent}</span>}
            <header onClick={handleClick} className={headerClasses}>
                <div className="flex flex-row items-center min-w-0">
                    {icon && (
                        <span className={iconContainerClasses}>
                            <Tooltip message={tooltip} tooltipClassName="text-white bg-label">
                                {icon}
                            </Tooltip>
                        </span>
                    )}
                    <div className="flex flex-col text-start min-w-0 text-sm">
                        <p className="font-normal truncate text-label" title={getNodeText(title)}>
                            {title}
                        </p>
                        {subTitle && (
                            <p className="font-light truncate text-color-disabled" title={getNodeText(subTitle)}>
                                {subTitle}
                            </p>
                        )}
                    </div>
                </div>
                <div className="flex justify-end gap-2 text-xs text-right">
                    <div className="flex flex-col items-end justify-center space-y-1">{rightContent}</div>
                    {isClosable && (
                        <ChevronSmall
                            className={classNames("w-3 fill-primary ease-in-out duration-200 mr-1", {
                                "rotate-0": openCard,
                                "rotate-180": !openCard
                            })}
                        />
                    )}
                </div>
            </header>
            {children && (
                <div
                    ref={contentRef}
                    className={classNames("transition-all duration-300 overflow-hidden", {
                        "max-h-0": !openCard,
                        "max-h-screen": openCard
                    })}
                >
                    {children}
                    {footer}
                </div>
            )}
        </div>
    );
}
