import { BoxContainer, Deposit, FormFieldsRadio, InfoTooltip, TickIcon, useI18n } from "@vaultinum/app-sdk";
import { useFormContext, useWatch } from "react-hook-form";

import { Translation } from "../../i18n";
import DropZone from "../DropZone/DropZone";

export default function StepTransmission(): JSX.Element {
    const { control } = useFormContext();
    const { translation } = useI18n<Translation>();
    const [type]: [Deposit.Type] = useWatch({ name: ["type"] });

    const choices = [
        {
            value: Deposit.Type.DIGITAL,
            title: translation.meanStep.option1.title
        },
        {
            value: Deposit.Type.PHYSICAL,
            title: translation.meanStep.option2.title
        }
    ];

    return (
        <div className="w-5/6 space-y-5">
            <BoxContainer
                title={translation.depositMode}
                topRightContent={<InfoTooltip children={translation.transmissionTooltip} className="top-1 right-1" />}
                icon={type && <TickIcon className="fill-green-light" />}
            >
                <FormFieldsRadio radioGroupName="type" control={control} options={choices} />
            </BoxContainer>
            {type === Deposit.Type.DIGITAL && (
                <BoxContainer>
                    <DropZone />
                </BoxContainer>
            )}
        </div>
    );
}
