import { GridCell, InfoIcon, Layout, useI18n } from "@vaultinum/app-sdk";
import { Translation } from "../../i18n";
import { CONTACT_SUPPORT, HELP_PAGE } from "../../services";

export default function HelpPage() {
    const { translation } = useI18n<Translation>();

    return (
        <Layout icon={<InfoIcon className="w-6 h-8 fill-primary" />} title={translation.needHelpQuestion}>
            <div className="grid grid-cols-1 lg:grid-cols-3 p-2 w-full gap-2">
                <GridCell
                    customClass="border border-primary"
                    title={translation.needHelp}
                    primaryButtonProps={{
                        label: translation.contactHelpDesk,
                        to: CONTACT_SUPPORT,
                        shouldOpenInNewTab: true
                    }}
                    children={translation.widgetContact}
                />
                <GridCell
                    customClass="border border-primary"
                    title={translation.billingIssue}
                    children={translation.widgetBillingContact}
                />
                <GridCell
                    customClass="border border-primary"
                    title={translation.helpPage}
                    primaryButtonProps={{
                        label: translation.goTohelpPage,
                        to: HELP_PAGE,
                        shouldOpenInNewTab: true
                    }}
                    children={<p>{translation.widgetHelp}</p>}
                />
            </div>
        </Layout>
    );
}