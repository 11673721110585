import { InterdepositContext, Layout, LetterOpen, Loader, useI18n } from "@vaultinum/app-sdk";
import { useContext } from "react";
import { CreateDeposit } from "../../../../components";
import { WalletBreadCrumb } from "../../../../components/BreadCrumb";
import { Translation } from "../../../../i18n";

export default function DepositStoreUpdateDepositPage(): JSX.Element {
    const { depositStore, deposit } = useContext(InterdepositContext);
    const { translation } = useI18n<Translation>();

    if (!depositStore || !deposit) {
        return <Loader />;
    }

    return (
        <>
            <WalletBreadCrumb depositStore={depositStore} deposit={deposit} />
            <Layout title={`${translation.label(translation.depositOfUpdate)} ${depositStore.name}`} icon={<LetterOpen className="w-6 h-8 fill-primary" />}>
                <div className="max-w-3xl mx-auto w-full">
                    <CreateDeposit />
                </div>
            </Layout>
        </>
    );
}
