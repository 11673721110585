import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import { Button, Info, useToast } from "../../../design-system";
import { canSendMandateRequest, canSendRepresentativeRequest, canSendRequest } from "../../helpers/roles-helper";
import yup from "../../helpers/yup-extended";
import { useApi } from "../../hooks";
import { Translation, useI18n } from "../../i18n";
import { DepositMember, Member } from "../../model";
import { sendMandateRequest, sendRepresentativeRequest } from "../../services";
import { FormFieldsInput } from "../FormInputs";

export default function RequestForm({ member, cancelSubmit }: { member: Member; cancelSubmit: () => void }): JSX.Element {
    const { translation } = useI18n<Translation>();
    const toast = useToast();
    const { fetchApi } = useApi();

    const RepresentativeSchema: ObjectSchema<FieldValues> = yup.object({
        userEmail: yup.string().isEmailFormatValid(translation.emailFormatError).required(translation.requiredField),
        role: yup
            .string()
            .oneOf([
                DepositMember.Type.REPRESENTATIVE,
                DepositMember.Type.MANDATE,
                DepositMember.Type.COTITULARITY,
                DepositMember.Type.CONTRIBUTOR,
                DepositMember.Type.HOLDER
            ])
            .required(translation.requiredField)
    });

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        watch
    } = useForm<FieldValues>({
        mode: "onChange",
        resolver: yupResolver(RepresentativeSchema)
    });

    const submit = async (data: FieldValues) => {
        if (data.role === DepositMember.Type.REPRESENTATIVE) {
            const representativeRequestFn = sendRepresentativeRequest(member);
            if (representativeRequestFn) {
                await fetchApi(
                    () => representativeRequestFn(data.userEmail),
                    () => toast.success(translation.invitationSent)
                );
            }
        }

        if (data.role === DepositMember.Type.MANDATE) {
            const mandateRequestFn = sendMandateRequest(member);
            if (mandateRequestFn) {
                await fetchApi(
                    () => mandateRequestFn(data.userEmail),
                    () => toast.success(translation.invitationSent)
                );
            }
        }

        cancelSubmit();
    };

    return (
        <form onSubmit={handleSubmit(submit)} className="space-y-5">
            <div>
                <div className="flex flex-col">
                    <FormFieldsInput
                        control={control}
                        errors={errors}
                        fields={[
                            {
                                name: "role",
                                label: translation.invitedRole,
                                type: "select",
                                options: [
                                    {
                                        value: DepositMember.Type.REPRESENTATIVE,
                                        label: translation.representative,
                                        disabled: !canSendRepresentativeRequest(member)
                                    },
                                    {
                                        value: DepositMember.Type.MANDATE,
                                        label: translation.asMandate,
                                        disabled: !canSendMandateRequest(member)
                                    },
                                    {
                                        value: DepositMember.Type.COTITULARITY,
                                        label: translation.invitationType[DepositMember.Type.COTITULARITY],
                                        disabled: true
                                    },
                                    {
                                        value: DepositMember.Type.CONTRIBUTOR,
                                        label: translation.invitationType[DepositMember.Type.CONTRIBUTOR],
                                        disabled: true
                                    },
                                    {
                                        value: DepositMember.Type.HOLDER,
                                        label: translation.invitationType[DepositMember.Type.HOLDER],
                                        disabled: true
                                    }
                                ],
                                disabled: !canSendRequest(member),
                                helperText: translation.selectTargetUserRole
                            }
                        ]}
                    />
                </div>
            </div>
            <FormFieldsInput
                fields={[
                    {
                        name: "userEmail",
                        label: translation.memberEmail,
                        type: "email",
                        disabled: !watch("role")
                    }
                ]}
                control={control}
                errors={errors}
            />
            <Info type="primary" children={translation.representativeAddInfo} />
            <div className="flex flex-col justify-center items-center gap-7">
                <Button label={translation.sendInvitation} type="submit" disabled={!isValid} />
                <Button variant="link" label={translation.cancel} onClick={cancelSubmit} underline />
            </div>
        </form>
    );
}
